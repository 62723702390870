import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Order, OrderType } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import { OrderSetVariant } from "@models/OrderSetVariant";
import { setDraftOrderId } from "@redux/slices/globalStateSlice";
import client from "@services/api";
import asyncPool from "@utility/asyncPool";
import permissions from "@utils/permissions";
import { buildPaginatedQuery } from "@utils/reactQuery";
import { QueryOptions } from "@utils/reactQuery/types";
import useRoleIs from "@utils/useRoleIs";

import useDefaultBudgetOrCostCenter from "../../hooks/useDefaultBudgetOrCostCenter";
import { orderSetVariantSummaryKeyFactory } from "./orderSetVariantSummaryQueries";

export const orderSetsKeyFactory = createQueryKeys("order-sets", {
  detail: (orderSetId) => ({
    queryKey: [orderSetId],
    queryFn: () =>
      client.get<OrderSet>(`order-sets/${orderSetId}`).then((res) => res.data),
    contextQueries: {
      orders: {
        queryKey: null,
        queryFn: () =>
          client
            .get<Order[]>("orders", {
              params: { filter: { orderSetId }, skipPagination: true },
            })
            .then((res) => res.data),
      },
    },
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<OrderSet[]>("order-sets", { params }),
  }),
});

export const useOrderSetQuery = (
  orderSetId?: string | null,
  options?: QueryOptions<OrderSet>
) => {
  return useQuery({
    ...orderSetsKeyFactory.detail(orderSetId),
    enabled: !!orderSetId,
    ...options,
  });
};

export const usePaginatedOrderSets = buildPaginatedQuery(
  orderSetsKeyFactory.paginated
);

export const useDraftOrderSetsQuery = () => {
  const id = useSelector((state: any) => state.currentUser.id);
  const roleIs = useRoleIs();
  return usePaginatedOrderSets(
    {
      filter: {
        userIds: [id],
        status: ["draft", "inactive"],
        types: ["inventory", "on-demand"],
      },
      page: { size: 100 },
    },
    {
      enabled: roleIs(permissions.internalRoles),
    }
  );
};
export const useDraftPreOrdersQuery = () => {
  const id = useSelector((state: any) => state.currentUser.id);
  const roleIs = useRoleIs();
  return usePaginatedOrderSets(
    {
      filter: {
        userIds: [id],
        status: ["draft", "inactive"],
        types: ["pre-order"],
      },
      page: { size: 100 },
    },
    {
      enabled: roleIs(permissions.internalRoles),
    }
  );
};

export const useCurrentPreOrderOrderSetQuery = (
  programId: string,
  orderWindowId?: string
) => {
  const { currentChannelId, currentTerritoryId } = useSelector(
    (state: any) => state.currentUser
  );
  const { data, isLoading } = useDraftPreOrdersQuery();

  const orderSet = useMemo(
    () =>
      data.find(
        (os) =>
          os.program?.id === programId &&
          os.orderWindow?.id === orderWindowId &&
          (os.territory?.id ?? null) === currentTerritoryId &&
          (os.channel?.id ?? null) === currentChannelId
      ),
    [data, programId, orderWindowId, currentChannelId, currentTerritoryId]
  );

  return {
    orderSet,
    isLoading,
  };
};

type PreOrderOptions = {
  orderWindowId: string;
  programId: string;
};

type CreateOrderSetPayload = {
  orderSetType: OrderType;
  preOrderOptions?: PreOrderOptions;
};

export const useCreateOrderSetMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { currentTerritoryId, currentChannelId } = useSelector(
    (state: any) => state.currentUser
  );

  const { budgetId, costCenterId } = useDefaultBudgetOrCostCenter({
    warn: false,
  });

  return useMutation({
    mutationFn: ({ orderSetType, preOrderOptions }: CreateOrderSetPayload) =>
      client
        .post<OrderSet>("order-sets", {
          __type: "order-set",
          territoryId: currentTerritoryId,
          channelId: currentChannelId,
          type: orderSetType,
          budgetId: budgetId,
          costCenterId,
          ...preOrderOptions,
          relationshipNames: ["program", "orderCalendarMonth", "territory"],
        })
        .then((res) => res.data),
    onSuccess: (orderSet) => {
      dispatch(setDraftOrderId({ orderType: orderSet.type, id: orderSet.id }));
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSet.id).queryKey,
        orderSet
      );
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSet.id)._ctx.orders.queryKey,
        []
      );
    },
  });
};

type CreateOrderSetVariantsPayload = {
  orderSetId?: string | null;
  variantIds: string[];
  orderType: OrderType;
};

export const useCreateOrderSetVariantsMutation = () => {
  const queryClient = useQueryClient();
  const createOrderSetMutaton = useCreateOrderSetMutation();

  return useMutation({
    mutationFn: async ({
      orderSetId,
      variantIds,
      orderType,
    }: CreateOrderSetVariantsPayload) => {
      let osId = orderSetId;
      if (!osId) {
        const orderSet = await createOrderSetMutaton.mutateAsync({
          orderSetType: orderType,
        });
        osId = orderSet.id;
      }
      const res = await asyncPool(5, variantIds, (variantId) =>
        client
          .post<OrderSetVariant>(`order-set-variants`, {
            __type: "order-set-variant",
            orderSet: {
              id: osId,
            },
            variant: {
              id: variantId,
            },
            relationshipNames: ["variant", "orderSet"],
          })
          .then((res) => res.data)
      );
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
      return { id: osId, orderSetVariants: res.results };
    },
    onSuccess: async ({ id, orderSetVariants }) => {
      // cancel requests for the same order set
      await queryClient.cancelQueries({
        queryKey: orderSetsKeyFactory.detail(id).queryKey,
      });
      return queryClient.setQueryData<OrderSet>(
        orderSetsKeyFactory.detail(id).queryKey,
        (os) =>
          os && {
            ...os,
            orderSetVariants: [...os.orderSetVariants, ...orderSetVariants],
          }
      );
    },
    onError: (_, { orderSetId }) => {
      if (!orderSetId) return;
      return queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    },
  });
};

export const useApproveOrderSetsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (orderSetIds: string[]) => {
      const res = await asyncPool(5, orderSetIds, (orderSetId) =>
        client.post(
          `order-sets/${orderSetId}/approve`,
          {},
          { timeout: 10 * 60_000 }
        )
      );
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
    },
    onSettled: async (s, e, orderSetIds) => {
      if (orderSetIds.length === 1) {
        await queryClient.invalidateQueries({
          queryKey: orderSetsKeyFactory.detail(orderSetIds[0]).queryKey,
        });
      }
      queryClient.invalidateQueries({
        queryKey: orderSetVariantSummaryKeyFactory.paginated._def,
      });
      return queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
    },
  });
};

export const useDenyOrderSetsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orderSetIds,
      reason,
    }: {
      orderSetIds: string[];
      reason: string;
    }) => {
      const res = await asyncPool(5, orderSetIds, (id) =>
        client.post(
          `order-sets/${id}/cancel`,
          { "cancelation-type": "denial", "cancelation-note": reason },
          { serializeBody: false }
        )
      );

      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: orderSetVariantSummaryKeyFactory.paginated._def,
      });
      return queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory._def,
      });
    },
  });
};
