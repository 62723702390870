import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isEqual from "date-fns/isEqual";

import cloudinary from "@services/cloudinary";
import {
  formatDateString,
  stringToCents,
  upCase,
} from "@utility/utilityFunctions";

import { mapChannels } from "../channelSlice";
import { mapTerritories } from "../territories/maps";
import { mapUser } from "../user/maps";

const handleImages = (images) => {
  if (images.length === 0) {
    return {
      imgUrlThumb: {
        id: null,
        type: "thumbnail",
        cloudinaryId: null,
        originalUrl: null,
        url: "https://res.cloudinary.com/brandhub/image/upload/v1626199716/dev/Brandhub/NoImageFound_axxgh5.png",
      },
      imgUrlLg: [
        {
          id: null,
          type: "large",
          cloudinaryId: null,
          originalUrl: null,
          url: "https://res.cloudinary.com/brandhub/image/upload/v1626199716/dev/Brandhub/NoImageFound_axxgh5.png",
        },
      ],
    };
  } else {
    let thumb = images.find((img) => img.type === "thumbnail");
    if (!thumb) {
      thumb = images[1] ? images[1] : images[0];
    }
    let largeArray = images
      .filter((img) => img.type === "large")
      .sort((a, b) => {
        return a.position < b.position ? -1 : a.position > b.position ? 1 : 0;
      })
      .map((i) => ({
        id: i.id,
        type: i.type,
        cloudinaryId: i["cloudinary-id"],
        originalUrl: i["original-url"],
        url: `https://res.cloudinary.com/brandhub/image/upload/${i["cloudinary-id"]}`,
      }));
    if (largeArray.length === 0) {
      largeArray = [
        {
          id: null,
          type: "large",
          cloudinaryId: null,
          originalUrl: null,
          url: "https://res.cloudinary.com/brandhub/image/upload/v1626199716/dev/Brandhub/NoImageFound_axxgh5.png",
        },
      ];
    }
    return {
      imgUrlThumb: thumb
        ? {
            id: thumb.id,
            type: thumb.type,
            cloudinaryId: thumb["cloudinary-id"],
            originalUrl: thumb["original-url"],
            variantOptionId: null,
            url: `https://res.cloudinary.com/brandhub/image/upload/${thumb["cloudinary-id"]}`,
          }
        : {
            id: null,
            type: "thumbnail",
            cloudinaryId: null,
            originalUrl: null,
            variantOptionId: null,
            url: "https://res.cloudinary.com/brandhub/image/upload/v1626199716/dev/Brandhub/NoImageFound_axxgh5.png",
          },
      imgUrlLg: largeArray,
    };
  }
};

const handleVariablePricing = (pricing) => {
  if (!pricing || pricing.length === 0) return null;
  let mappedPricing = pricing
    .map((p) => ({
      id: p.id,
      maxQty: p["max-quantity"],
      cost: stringToCents(p.cost),
      price: stringToCents(p.price),
    }))
    .sort((a, b) => (a.maxQty > b.maxQty ? 1 : a.maxQty < b.maxQty ? -1 : 0));

  let max = mappedPricing.shift();
  return mappedPricing.concat(max);
};

export const mapAllocations = (allocations) => {
  if (!allocations || allocations.length === 0) return [];
  const mappedAllocations = allocations.map((a) => ({
    id: a.id,
    allocatedQty: a["allocated-qty"],
    availableQty:
      a["available-to-order-qty"] < 0 ? 0 : a["available-to-order-qty"],
    remainingQty: a["remaining-allocated-qty"],
    territory: a.territory ? mapTerritories([a.territory])[0] : null,
    user: a.user ? mapUser(a.user) : null,
  }));

  return mappedAllocations;
};

export const mapAllocationHistory = (history) => {
  if (!history || history.length === 0) return [];
  const mappedHistory = history.map((h) => ({
    updatedBy: h["updated-by"],
    updatedAt: h["updated-at"]
      ? format(new Date(h["updated-at"]), "MM/dd/yyyy")
      : "---",
    allocatedQty: h["allocated-qty"] ?? "---",
    adjustmentAmount: h["order-number"]
      ? -1 * h["adjustment-amount"]
      : h["adjustment-amount"],
    orderNumber: h["order-number"] ?? "---",
    orderStatus: h["order-status"] ? upCase(h["order-status"], "-") : "---",
  }));

  return mappedHistory;
};

export const handleVariants = (variants) => {
  if (!variants || variants.length === 0) return [];
  const mappedVariants = variants
    .filter((v) => v["is-active"])
    .map((variant) => {
      let variantNames =
        variant["selected-variant-options"].length > 0
          ? variant["selected-variant-options"]
              .filter((v) => v.name?.length)
              .sort((av, bv) =>
                av["variant-category"].name < bv["variant-category"].name
                  ? -1
                  : av["variant-category"].name > bv["variant-category"].name
                    ? 1
                    : 0
              )
              .map((v) => `${v["variant-category"].name}: ${v.name}`)
              .join(", ")
          : null;

      let variantOptionIds =
        variant["selected-variant-options"].length > 0
          ? variant["selected-variant-options"]
              .filter((v) => v.name?.length)
              .map((v) => v.id)
          : null;
      return {
        id: variant.id,
        name: variantNames,
        sku: variant["variant-sku"],
        qty: variant["global-available-to-order-qty"],
        warehouseId: variant["external-warehouse-id"],
        warehouseQty: variant["cached-warehouse-qty"],
        quantityOnHand: variant["quantity-on-hand"],
        allocations: mapAllocations(variant["variant-allocations"]),
        cost: stringToCents(variant.cost),
        price: stringToCents(variant.price),
        orderPosition: variant["order-position"],
        upcharge: stringToCents(variant.upcharge),
        variantOptionIds: variantOptionIds,
        imageUrl: variant.image
          ? cloudinary.url(variant.image["cloudinary-id"])
          : null,
      };
    });
  return mappedVariants
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    .sort((a, b) =>
      a.orderPosition < b.orderPosition
        ? -1
        : a.orderPosition > b.orderPosition
          ? 1
          : 0
    );
};

const handleGroups = (groups) => {
  const mappedGroups = groups.map((group) => ({
    id: group.id,
    groupCategoryId: group["group-category"].id,
    groupCategoryName: group["group-category"].name,
    name: group.name,
  }));
  return mappedGroups;
};

const handleTerritories = (territories) => {
  const mappedTerritories = territories.map((terr) => ({
    id: terr.id,
    name: terr.name,
  }));
  return mappedTerritories;
};

const handlePrograms = (programs) => {
  if (!programs || programs.length === 0) return { names: "---", detail: [] };
  let names = programs
    .filter((p) => p["is-active"])
    .map((p) => p.name)
    .join(", ");
  let detail = programs.map((p) => ({
    id: p.id,
    name: p.name,
    isActive: p["is-active"],
    startDate: formatDateString(p["start-date"]),
    endDate: formatDateString(p["end-date"]),
    onActiveOrderWindow: Boolean(
      p["order-windows"].find(
        (ow) =>
          (isBefore(new Date(ow["open-date"]), new Date()) ||
            isEqual(new Date(ow["open-date"]), new Date())) &&
          (isAfter(new Date(ow["review-close-date"]), new Date()) ||
            isEqual(new Date(ow["review-close-date"]), new Date()))
      )
    ),
  }));
  let activePreOrderPrograms = detail.filter((p) => p.onActiveOrderWindow);
  if (activePreOrderPrograms.length > 0) {
    activePreOrderPrograms = activePreOrderPrograms
      .map((p) => p.name)
      .join(", ");
  } else activePreOrderPrograms = null;

  return {
    names: names,
    detail: detail,
    activePreOrderPrograms: activePreOrderPrograms,
  };
};

export const mapItems = (items) => {
  const mappedItems = items.map((item) => {
    const variants = handleVariants(item.variants);
    const programs = handlePrograms(item.programs);
    return {
      id: item.id,
      name: item.name,
      images: handleImages(item.images),
      variants: variants,
      groups: handleGroups(item.groups),
      territories: handleTerritories(item.territories),
      channels: item.channels ? mapChannels(item.channels) : [],
      cost: stringToCents(item.cost),
      defaultExternalWarehouseId: item["default-external-warehouse-id"] ?? null,
      description: item.description ?? null,
      previewText: item["preview-text"]
        ? item["preview-text"]
        : item.description && item.description.length > 149
          ? item.description.slice(0, 149) + "..."
          : (item.description ?? null),
      adminPreviewText: item["preview-text"] ?? null,
      isActive: item["is-active"],
      leadTime: item["lead-time-in-days"],
      moq: item["minimum-order-quantity"],
      orderType: item["order-type"] ?? "default type",
      orderableStartDate: formatDateString(item["orderable-start-date"]),
      orderableEndDate: formatDateString(item["orderable-end-date"]),
      packSize: item["pack-size"],
      price: item.price ? stringToCents(item.price) : stringToCents(item.cost),
      programNames: programs.names,
      programDetail: programs.detail,
      activePreOrderPrograms: programs.activePreOrderPrograms,
      reorderThreshold: item["reorder-threshold"],
      sku: item.sku,
      specification:
        item.specification && Object.keys(item.specification).length > 0
          ? item.specification
          : null,
      supplierId: item["supplier-id"],
      unitOfMeasure: item["unit-of-measure"],
      variablePricing: handleVariablePricing(item["variable-pricing"]),
      visibleStartDate: formatDateString(item["visible-start-date"]),
      visibleEndDate: formatDateString(item["visible-end-date"]),
      warehouse: item.warehouse,
      isCustomizable: item["is-customizable"] ?? false,
      customizationDescription: item["customization-description"] ?? null,
      onHand: variants.reduce((a, b) => a + b.qty, 0),
      isBundle: item["is-bundle"],
    };
  });
  return mappedItems;
};
