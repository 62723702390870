/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { CircularProgress } from "@mui/material";

import { useGroupCategoriesListQuery } from "@features/groupCategories";
import { useGroupsListQuery } from "@features/groups/";
import { ControlledAutocompleteInput } from "@utils/forms";

import BlockCard from "../../../ui/BlockCard";
import { useIsPublished } from "../../helpers";

const Loading = tw.div`h-10 rounded-md bg-neutral-100 animate-pulse`;

const GroupSelector = ({ groupCategory }) => {
  const { control } = useFormContext();
  const isPublished = useIsPublished();
  const { data: groupOptions } = useGroupsListQuery({
    filter: { "group-category-id": groupCategory.id },
  });

  const inputName = `groups.${groupCategory.id}`;

  if (!groupOptions) return <Loading />;

  return (
    <ControlledAutocompleteInput
      label={groupCategory.name + (groupCategory.isRequiredOnItem ? " *" : "")}
      options={groupOptions}
      name={inputName}
      control={control}
      rules={{ required: groupCategory.isRequiredOnItem && isPublished }}
    />
  );
};

const CategoriesBlock = () => {
  const {
    reset,
    formState: { isDirty },
  } = useFormContext();
  const { data: groupCategories, isLoading } = useGroupCategoriesListQuery();

  useEffect(() => {
    // Initialize missing groupCategories
    if (isDirty || !groupCategories) return;
    const newGroups = Object.fromEntries(
      groupCategories.map(({ id }) => [id, ""])
    );

    reset((form) => ({
      ...form,
      groups: {
        ...newGroups,
        ...form.groups,
      },
    }));
  }, [groupCategories, reset, isDirty]);

  if (!groupCategories || isLoading) return <CircularProgress />;

  return (
    <BlockCard title="Categories" tw="space-y-4">
      {groupCategories.map((category) => (
        <GroupSelector groupCategory={category} key={category.id} />
      ))}
    </BlockCard>
  );
};

export default CategoriesBlock;
