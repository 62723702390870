import { usePaginatedBeaconPingsQuery } from "./data";
import useBeaconSearchFilters from "./useBeaconSearchFilters";

export default function useFilteredBeaconPings() {
  return usePaginatedBeaconPingsQuery(
    { filter: useBeaconSearchFilters() },
    {
      initialPagesToLoad: 100,
    }
  );
}
