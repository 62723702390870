import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";

import { OrderSetVariantSummary } from "@models/OrderSetVariantSummary";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const orderSetVariantSummaryKeyFactory = createQueryKeys(
  "order-set-variant-summary",
  {
    paginated: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client.get<OrderSetVariantSummary[]>(`order-set-variant-summaries`, {
          params,
        }),
    }),
  }
);

export const usePaginatedOrderSetVariantSummaries = buildPaginatedQuery(
  orderSetVariantSummaryKeyFactory.paginated,
  {
    staleTime: 60_000,
    placeholderData: keepPreviousData,
  }
);
