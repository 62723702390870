import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Order } from "@models/Order";
import client from "@services/api";

import { useCurrentOrderSet } from "../../CurrentOrderSetContext";
import { orderSetsKeyFactory } from "../orderSetQueries";

export default function useUpdateOrder() {
  const queryClient = useQueryClient();
  const { orderSetId } = useCurrentOrderSet();

  return useMutation({
    mutationFn: ({
      id,
      ...orderBody
    }: { id: string } & Record<string, any>) => {
      return client
        .update<Order>(`orders/${id}`, {
          __type: "order",
          ...orderBody,
        })
        .then((res) => ({ order: res.data }));
    },
    onSuccess: ({ order }) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => orders.map((o) => (o.id === order.id ? order : o))
      );
    },
  });
}
