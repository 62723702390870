import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Order, OrderType } from "@models/Order";
import client from "@services/api";

import { useCurrentOrderSet } from "../../CurrentOrderSetContext";
import { orderSetsKeyFactory } from "../orderSetQueries";

type CreateOrderPayload = {
  addressId: string;
  type: OrderType;
  attn?: string;
};

export default function useCreateOrder() {
  const queryClient = useQueryClient();
  const { orderSetId } = useCurrentOrderSet();

  return useMutation({
    mutationFn: (data: CreateOrderPayload) => {
      return client
        .post<Order>(`orders`, {
          __type: "order",
          orderSetId: orderSetId,
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: (order) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => [...orders, order]
      );
    },
  });
}
