import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import client from "@services/api";

import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";
import useDeleteOrderSetMutation from "./useDeleteOrderSetMutation";

export default function useDeleteOrderSetVariant() {
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const deleteOrderSet = useDeleteOrderSetMutation();

  return useMutation({
    mutationFn: (orderSetVariantId: string) => {
      const { orderSetVariants } = queryClient.getQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey
      ) as OrderSet;
      if (orderSetVariants.length === 1) {
        return deleteOrderSet.mutateAsync().then(() => null);
      }

      const variantId = orderSetVariants.find(
        (osv: any) => osv.id === orderSetVariantId
      )!.variant.id;

      return client
        .remove(`order-set-variants/${orderSetVariantId}`)
        .then(() => ({ orderSetVariantId, variantId }));
    },
    onSuccess: (args) => {
      if (!args) return;
      const { orderSetVariantId, variantId } = args;
      return Promise.all([
        queryClient.setQueryData(
          orderSetsKeyFactory.detail(orderSetId).queryKey,
          (orderSet: OrderSet) => {
            return {
              ...orderSet,
              orderSetVariants: orderSet.orderSetVariants.filter(
                (osv: any) => osv.id !== orderSetVariantId
              ),
            };
          }
        ),
        queryClient.setQueryData(
          orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
          (orders: Order[]) => {
            const newOrders = orders.map((order) => {
              let dov: any;
              const newOrderVariants = order.orderVariants.filter((ov: any) => {
                if (ov.variant.id === variantId) {
                  dov = ov;
                  return false;
                }
                return true;
              });

              const updateKey = (key: string) =>
                String(+order[key] - +(dov?.[key] || 0));

              return {
                ...order,
                totalQuantity: order.totalQuantity - (dov?.qty || 0),
                totalEstimatedCost: updateKey("totalEstimatedCost"),
                totalEstimatedTax: updateKey("totalEstimatedTax"),
                totalEstimatedShippingCost: updateKey(
                  "totalEstimatedShippingCost"
                ),
                orderVariants: newOrderVariants,
              };
            });

            return newOrders;
          }
        ),
      ]);
    },
  });
}
