/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useMemo } from "react";

import { Skeleton } from "@mui/material";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable from "@components/Table/ReactQueryTable";
import { useFilterParams } from "@features/filters";
import { useProgramListQuery } from "@features/programs";
import { Program } from "@models/Program";
import { CldImage } from "@services/cloudinary";
import { useSetLocation } from "@services/reactRouterDom";

import { useBeaconStatesReportQuery } from "../data";
import useBeaconSearchFilters from "../useBeaconSearchFilters";
import BeaconProgressBar from "./BeaconProgressBar";

const ProgramBeaconStatus = ({ program }: { program: Program }) => {
  const { data: states = [], isLoading } = useBeaconStatesReportQuery({
    filter: { ...useBeaconSearchFilters(), programId: program.id },
  });

  const { beaconsPinged, estBeaconsExpected } = useMemo(() => {
    let beaconsPinged = 0;
    let estBeaconsExpected = 0;

    states
      // .filter((state) => (isState ? state.stateCode === focusedState : true))
      .forEach((state) => {
        beaconsPinged += +state.beaconsPinged;
        estBeaconsExpected += +state.estBeaconsExpected;
      });

    return { beaconsPinged, estBeaconsExpected };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  if (isLoading) {
    return <Skeleton width="100%" />;
  }

  return (
    <BeaconProgressBar
      beaconsPinged={beaconsPinged}
      estBeaconsExpected={estBeaconsExpected}
    />
  );
};

const BeaconProgramsList = () => {
  const setLocation = useSetLocation();
  const [filters] = useFilterParams();

  const handleProgramClick = (program) => {
    setLocation({
      pathname: "/beacons/items",
      searchParams: { ...filters, programs: [program.id] },
    });
  };
  const { data = [], ...rest } = useProgramListQuery({
    filter: { hasBeacons: true },
  });
  return (
    <OpaqueCard tw="p-0">
      <ReactQueryTable
        {...rest}
        onRowClick={handleProgramClick}
        rows={data}
        columns={[
          {
            id: "_program",
            label: "Program",
            render: (_, program) => (
              <div tw="flex items-start gap-4">
                <CldImage
                  tw="object-cover object-center w-14 h-14 rounded"
                  publicId={program.cloudinaryId}
                  size="thumbnail"
                />
                <div tw="flex-1">
                  <h2 tw="text-lg text-neutral-700">{program.name}</h2>
                  <ProgramBeaconStatus program={program} />
                </div>
              </div>
            ),
          },
        ]}
        noResultsText="No Programs with Beaacons found for the selected filters."
      />
    </OpaqueCard>
  );
};

export default BeaconProgramsList;
