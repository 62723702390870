import { createSlice } from "@reduxjs/toolkit";
import { upCase } from "@utility/utilityFunctions";

let initialState = {
  budgetGroupIds: [],
  budget: null,
  budgetIsActive: null,
  budgetType: null,
  channelId: null,
  channelIds: [],
  currentTerritoryId: null,
  dateRangeStart: null,
  dateRangeEnd: null,
  description: null,
  favoriteItems: [],
  groupIds: [],
  groupedBy: null,
  groupOrderHistoryBy: null,
  invoiceNumber: null,
  isActive: null,
  isOrderable: null,
  isVisible: null,
  name: null,
  orderId: null,
  orderType: null,
  orderWindowId: null,
  orderWindowIds: [],
  poId: null,
  programId: null,
  programIds: [],
  promotionIds: [],
  promotionRedemptionType: null,
  promotionStatus: null,
  promotionType: null,
  purchaserIds: [],
  reportType: null,
  sku: null,
  inStock: null,
  stateIds: [],
  status: null,
  supplierIds: [],
  supplierReference: null,
  territoryIds: [],
  useOrderWindowTerritoryId: false,
  userIds: [],
  warehouse: null,
  sortBy: null,
  sortDirection: null,
  ignoreUserTerritory: false,
  ignoreUserChannel: false,
  reviewType: null,
  chipList: [],
  defaultFilters: null,
  clearFilters: false,
  clearInvoiceNumber: false,
  clearName: false,
  clearOrderId: false,
  clearPoId: false,
  clearSku: false,
  clearSupplierReference: false,
  sorted: false,
  filterType: null,
};

const arrayFilters = [
  "budgetGroupIds",
  "groupIds",
  "territoryIds",
  "channelIds",
  "orderWindowIds",
  "programIds",
  "promotionIds",
  "purchaserIds",
  "supplierIds",
  "userIds",
  "stateIds",
];

const freeTypeFilters = [
  "description",
  "invoiceNumber",
  "name",
  "orderId",
  "poId",
  "sku",
  "supplierReference",
];

const reset = (state, retainType) => {
  state.budgetGroupIds = [];
  state.budget = null;
  state.budgetIsActive = null;
  state.budgetType = null;
  state.channelId = null;
  state.channelIds = [];
  state.dateRangeStart = null;
  state.dateRangeEnd = null;
  state.description = null;
  state.favoriteItems = [];
  state.groupIds = [];
  state.groupedBy = null;
  state.groupOrderHistoryBy = null;
  state.invoiceNumber = null;
  state.isActive = null;
  state.isOrderable = null;
  state.isVisible = null;
  state.name = null;
  state.orderId = null;
  state.orderType = null;
  state.orderWindowId = null;
  state.orderWindowIds = [];
  state.poId = null;
  state.promotionRedemptionType = null;
  state.promotionStatus = null;
  state.promotionType = null;
  state.programId = null;
  state.programIds = [];
  state.promotionIds = [];
  state.purchaserIds = [];
  state.reportType = null;
  state.sku = null;
  state.stateIds = [];
  state.status = null;
  state.supplierIds = [];
  state.supplierReference = null;
  state.territoryIds = [];
  state.useOrderWindowTerritoryId = false;
  state.userIds = [];
  state.warehouse = null;
  state.currentTerritoryId = null;
  state.inStock = null;
  state.sortBy = null;
  state.sortDirection = null;
  state.ignoreUserTerritory = false;
  state.ignoreUserChannel = false;
  state.reviewType = null;
  state.chipList = [];
  state.defaultFilters = retainType ? state.defaultFilters : null;
  state.clearFilters = false;
  state.clearInvoiceNumber = false;
  state.clearName = false;
  state.clearOrderId = false;
  state.clearPoId = false;
  state.clearSku = false;
  state.clearSupplierReference = false;
  state.sorted = false;
  state.filterType = retainType ? state.filterType : null;
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilterType(state, action) {
      const { type } = action.payload;
      state.filterType = type;
    },
    setInitialFilters(state, action) {
      const { type, defaultFilters, paramFilters } = action.payload;
      reset(state, false);
      state.defaultFilters = defaultFilters;
      state.filterType = type;
      for (let filter in defaultFilters) {
        state[filter] = defaultFilters[filter];
      }
      if (paramFilters) {
        for (let filter in paramFilters) {
          state[filter] = paramFilters[filter];
        }
      }
      if (!type.includes("reports-")) {
        state.sorted = true;
      }
    },
    setDefaultFilters(state, action) {
      const { filterObject } = action.payload;
      reset(state, true);
      state.defaultFilters = { ...filterObject };
    },
    updateMultipleFilters(state, action) {
      const { filterObject } = action.payload;
      for (let filter in filterObject) {
        state[filter] =
          arrayFilters.includes(filter) && filter !== "groupIds"
            ? filterObject[filter].map((f) => ({
                name: f.code || f.name,
                id: f.id,
              }))
            : filterObject[filter];
      }
    },
    updateSingleFilter(state, action) {
      const { filter, value } = action.payload;
      state[filter] =
        arrayFilters.includes(filter) && filter !== "groupIds"
          ? value.map((f) => ({
              name: f.code || f.name || f.description,
              id: f.id,
            }))
          : value;
    },
    setClearFilter(state, action) {
      const { key, value } = action.payload;
      const clearMap = {
        invoiceNumber: "clearInvoiceNumber",
        name: "clearName",
        orderId: "clearOrderId",
        poId: "clearPoId",
        sku: "clearSku",
        supplierReference: "clearSupplierReference",
      };
      state[clearMap[key]] = value;
    },
    setClear(state) {
      state.clearFilters = true;
    },
    setSorted(state, action) {
      const { sorted } = action.payload;
      state.sorted = sorted;
    },
    setClearSku(state, action) {
      const { key, value } = action.payload;
      state[`${key}`] = value;
    },
    resetFilters(state) {
      reset(state, true);
    },
    setChips(state, action) {
      const { filterType } = action.payload;

      let chippable = [];
      if (filterType === "order-history") {
        chippable = [
          "groupIds",
          "orderType",
          "territoryIds",
          "programIds",
          "stateIds",
          "userIds",
          "orderId",
          "sku",
          "status",
        ];
      }
      if (filterType === "purchase-order-rollup") {
        chippable = ["groupIds", "programIds", "supplierIds", "sku"];
      }
      if (filterType === "purchase-order-history") {
        chippable = [
          "groupIds",
          "programIds",
          "supplierIds",
          "purchaserIds",
          "sku",
          "invoiceNumber",
          "poId",
          "supplierReference",
          "status",
          "orderType",
        ];
      }
      if (filterType && filterType.includes("reports-")) {
        chippable = [
          "groupIds",
          "orderWindowIds",
          "programIds",
          "promotionIds",
          "supplierIds",
          "purchaserIds",
          "sku",
          "invoiceNumber",
          "poId",
          "supplierReference",
          "status",
          "orderType",
          "userIds",
          "territoryIds",
          "budget",
          "budgetGroupIds",
          "channelIds",
        ];
      }
      if (filterType && filterType === "budgets") {
        chippable = ["name", "budgetGroupIds", "territoryIds", "userIds"];
      }
      if (filterType && filterType === "cost-centers") {
        chippable = ["name"];
      }
      if (filterType && filterType === "promotion") {
        chippable = [
          "userIds",
          "promotionType",
          "promotionStatus",
          "promotionRedemptionType",
        ];
      }
      if (filterType && filterType === "addresses") {
        chippable = ["name", "userIds", "territoryIds"];
      }

      let filters = [];
      let stateObject = { ...state };

      const pushFilter = (filter) => {
        let splitter = stateObject[filter].includes("-") ? "-" : " ";
        let value = freeTypeFilters.includes(filter)
          ? stateObject[filter]
          : upCase(stateObject[filter], splitter);
        filters.push({ type: filter, value: value });
      };

      for (let filter in stateObject) {
        if (chippable.includes(filter)) {
          if (arrayFilters.includes(filter)) {
            stateObject[filter].forEach((f) => {
              if (typeof f === "object") {
                filters.push({ type: filter, value: f.name, id: f.id });
              }
            });
          } else if (filter === "favoriteItems") {
            if (stateObject[filter].length > 0) {
              filters.push({ type: filter, value: "Favorite Items" });
            }
          } else if (filter === "status") {
            if (
              !filterType.includes("reports-") &&
              stateObject[filter] &&
              stateObject[filter].split(",").length === 1
            ) {
              pushFilter(filter);
            }
            if (filterType.includes("reports-") && stateObject[filter]) {
              let value = stateObject[filter]
                .split(",")
                .map((f) => upCase(f, "-"))
                .join(", ");
              filters.push({ type: filter, value: value });
            }
          } else if (filter === "budget") {
            if (stateObject[filter]) {
              let value = stateObject[filter].name;
              filters.push({ type: filter, value: value });
            }
          } else if (stateObject[filter]) {
            pushFilter(filter);
          }
        }
      }
      state.chipList = filters;
    },
  },
});

export const {
  setFilterType,
  setInitialFilters,
  setDefaultFilters,
  updateMultipleFilters,
  updateSingleFilter,
  setSorted,
  setClear,
  setClearFilter,
  resetFilters,
  setChips,
} = filterSlice.actions;

export default filterSlice.reducer;
