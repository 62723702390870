/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeForm from "./StripeForm";

const StripeCheckout = ({
  // amount,
  onSuccess,
}: {
  // amount: number;
  onSuccess: (paymentId: string) => Promise<void>;
}) => {
  const stripeAccount: string | null = useSelector(
    (state: any) => state.currentUser.organization.stripeAccount
  );

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!, {
    stripeAccount: stripeAccount ?? undefined,
  });

  return (
    <Elements stripe={stripePromise}>
      <StripeForm onSuccess={onSuccess} />
    </Elements>
  );
};

export default StripeCheckout;
