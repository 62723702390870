import { CostCenterSelector } from "@features/costCenters";
import { OrderSet } from "@models/OrderSet";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import { useUpdateOrderSetMutation } from "../data";

const OrderSetCostCenterSelector = ({
  onChangeSuccess,
  hideLabel = false,
}: {
  hideLabel?: boolean;
  onChangeSuccess?: (orderSet: OrderSet) => void;
}) => {
  const {
    orderSet: { costCenter },
  } = useCurrentOrderSet();

  const updateOrderSetMuation = useUpdateOrderSetMutation();

  const handleChangeCostCenter = (costCenterId) => {
    updateOrderSetMuation.mutate(
      { costCenterId },
      { onSuccess: onChangeSuccess }
    );
  };
  return (
    <CostCenterSelector
      hideLabel={hideLabel}
      value={costCenter?.id ?? ""}
      onChange={handleChangeCostCenter}
    />
  );
};

export default OrderSetCostCenterSelector;
