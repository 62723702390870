/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { LoadingButton } from "@features/ui";
import { DeliveryInstructions } from "@models/DeliveryInstructions";
import { Order } from "@models/Order";
import {
  ControlledCheckboxInput,
  ControlledTextInput,
  defaultFormValues,
} from "@utils/forms";

import { useUpdateDeliveryInstructionsMutation } from "./data";

const booleanInputs = {
  debrisRemoval: "Debris Removal",
  insideDelivery: "Inside Delivery",
  liftgateRequired: "Liftgate Required",
  limitedAccess: "Limited Access",
  oversizedOver96Inches: "Oversized (Over 96 Inches)",
  palletJackRequired: "Pallet Jack Required",
  rampRequired: "Ramp Required",
  residentialNonCommercial: "Residential/Non-Commercial",
};

const formValuesFromDeliveryInstructions = (
  deliveryInstructions: DeliveryInstructions
) => {
  return {
    ...(defaultFormValues(deliveryInstructions) as DeliveryInstructions),
    checkboxCallBeforeDelivery: !!deliveryInstructions.callBeforeDelivery,
    checkboxCallForDeliveryAppointment:
      !!deliveryInstructions.callForDeliveryAppointment,
  };
};

const DeliveryInstructionsModal = ({
  order,
  handleClose,
}: {
  order: Order;
  handleClose: () => void;
}) => {
  const { id: orderId, address } = order;

  const updateDeliveryInstructions = useUpdateDeliveryInstructionsMutation();
  const { control, watch, handleSubmit } = useForm({
    defaultValues: formValuesFromDeliveryInstructions(
      address.deliveryInstructions!
    ),
  });

  const handleSaveAndClose = (
    data: ReturnType<typeof formValuesFromDeliveryInstructions>
  ) => {
    const {
      checkboxCallBeforeDelivery,
      checkboxCallForDeliveryAppointment,
      callBeforeDelivery,
      callForDeliveryAppointment,
      additionalNotes,
      ...rest
    } = data;
    updateDeliveryInstructions.mutate(
      {
        addressId: address.id,
        orderId,
        ...rest,
        callBeforeDelivery: checkboxCallBeforeDelivery
          ? callBeforeDelivery
          : null,
        callForDeliveryAppointment: checkboxCallForDeliveryAppointment
          ? callForDeliveryAppointment
          : null,
        additionalNotes: additionalNotes || null,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const [checkboxCallBeforeDelivery, checkboxCallForDeliveryAppointment] =
    watch(["checkboxCallBeforeDelivery", "checkboxCallForDeliveryAppointment"]);
  const reg = (name: string, rules?: any) => ({ control, name, rules });

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex justify-between items-start">
        <span>
          {address.name}{" "}
          <span tw="text-primary-800 font-normal text-base ml-2">
            Delivery Instructions
          </span>
        </span>
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {order.status === "approved" && (
          <div tw="mb-3 -mx-3">
            <Alert severity="warning">
              Please note that Delivery Instructions are only passed over to the
              carrier at the time the order is submitted. Any updates to the
              instructions or key information can be stored in brandhub for
              future reference,{" "}
              <b>
                but if this information is critical for your order, it must be
                communicated to{" "}
                <a href="mailto:help@selectbrandhub.com">
                  help@selectbrandhub.com
                </a>
              </b>{" "}
              We will assist and make sure that the appropriate parties are
              contacted.
            </Alert>
          </div>
        )}
        <form onSubmit={(e) => e.preventDefault()}>
          <div tw="md:columns-2 gap-4">
            {Object.entries(booleanInputs).map(([key, label]) => (
              <ControlledCheckboxInput
                tw="block"
                key={key}
                {...reg(key)}
                label={label}
              />
            ))}

            <ControlledCheckboxInput
              tw="block"
              {...reg("checkboxCallBeforeDelivery")}
              label={"Call Before Delivery"}
            />
            {checkboxCallBeforeDelivery && (
              <ControlledTextInput
                tw="mb-2"
                {...reg("callBeforeDelivery", { required: true })}
                helperText="Please include phone #"
              />
            )}
            <ControlledCheckboxInput
              tw="block"
              {...reg("checkboxCallForDeliveryAppointment")}
              label={"Call For Delivery Appointment"}
            />
            {checkboxCallForDeliveryAppointment && (
              <ControlledTextInput
                tw="mb-2"
                {...reg("callForDeliveryAppointment", { required: true })}
                helperText="Please include timeframes and contact"
              />
            )}
            <ControlledTextInput
              {...reg("additionalNotes")}
              multiline
              minRows={2}
              label="Additional Notes"
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={updateDeliveryInstructions.isPending}
          variant="contained"
          onClick={handleSubmit(handleSaveAndClose)}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryInstructionsModal;
