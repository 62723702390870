import { memo, useState } from "react";
import { useSelector } from "react-redux";

import PictureAsPdfRounded from "@mui/icons-material/PictureAsPdfRounded";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import { StyledButton } from "../StyledComponents";
import ProgramItemPDF from "./ProgramItemPDF";
import PurchaseOrderPDF from "./PurchaseOrderPDF";

const handleClick = async (doc, fileName, setPDFLoading) => {
  setPDFLoading(true);
  const newPDF = pdf([]);
  newPDF.updateContainer(doc);
  const blob = await newPDF.toBlob();
  saveAs(blob, fileName);
  setPDFLoading(false);
};

export const ProgramItemPdfLoader = ({
  items,
  programName,
  fileName,
  ...props
}) => {
  const [isPDFLoading, setPDFLoading] = useState(false);

  const { logoUrl } = useSelector((state) => state.currentUser.organization);
  const { categories } = useSelector((state) => state.groupCategories);

  return (
    <StyledButton
      outlined
      startIcon={<PictureAsPdfRounded />}
      disabled={isPDFLoading}
      onClick={() =>
        handleClick(
          <ProgramItemPDF
            items={items}
            programName={programName}
            categories={categories}
            orgLogo={logoUrl}
          />,
          fileName,
          setPDFLoading
        )
      }
      {...props}
    >
      {isPDFLoading ? <CircularProgress size={28} /> : "PDF"}
    </StyledButton>
  );
};

export const PurchaseOrderPdfLoader = memo(({ purchaseOrder }) => {
  const [isPDFLoading, setPDFLoading] = useState(false);

  return (
    <MenuItem
      disabled={isPDFLoading}
      onClick={() =>
        handleClick(
          <PurchaseOrderPDF purchaseOrder={purchaseOrder} />,
          `brandhub-purchase-order-${purchaseOrder.id}`,
          setPDFLoading
        )
      }
    >
      <ListItemIcon>
        <PictureAsPdfRounded fontSize="small" />
      </ListItemIcon>
      <ListItemText>Download PDF</ListItemText>
      {isPDFLoading && <CircularProgress size={16} />}
    </MenuItem>
  );
});
