import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { format, isBefore } from "date-fns";

import useConfirm from "@features/confirm";
import { flattenObject } from "@utility/utilityFunctions";

import { dirtyValues } from "./helpers";
import { storage } from "@utility/storage";

export function usePersistFormChanges(
  uniqueId: string | false | null,
  form: UseFormReturn<any>,
  compareWithDate?: Date | null
) {
  const confirm = useConfirm();

  useEffect(() => {
    if (!uniqueId) return;
    (async () => {
      const fromStorage = storage.getItem(uniqueId);
      if (!fromStorage) return;

      const { timestamp, formValues } = JSON.parse(fromStorage);
      const dateString = format(new Date(timestamp), "PP p");
      const needsUserConfirmation =
        !compareWithDate || isBefore(new Date(timestamp), compareWithDate);
      if (
        !needsUserConfirmation ||
        (await confirm(
          `You have unsaved changes from ${dateString}${
            compareWithDate
              ? `, but the form was last saved ${format(
                  compareWithDate,
                  "PP p"
                )}`
              : ""
          }. Would you like to restore your changes?`,
          {
            title: "Unsaved changes found.",
            confirmButtonText: "Restore",
            cancelButtonText: "Discard",
          }
        ))
      ) {
        // we're dealing with the saved, flat, object
        Object.entries(formValues).forEach(([key, value]) => {
          form.setValue(key, value, { shouldDirty: true });
        });
      }
      storage.removeItem(uniqueId);
    })();
  }, [uniqueId, form, confirm, compareWithDate]);

  useEffect(() => {
    if (!uniqueId) return;
    const storeDirtyValues = () => {
      try {
        if (
          !form.formState.isDirty ||
          Object.keys(form.formState.dirtyFields).length === 0
        ) {
          storage.removeItem(uniqueId);
          return;
        }
        const dirty = dirtyValues(form.formState.dirtyFields, form.getValues());
        const flatDirty = flattenObject(dirty);
        storage.setItem(
          uniqueId,
          JSON.stringify({
            timestamp: new Date().toUTCString(),
            formValues: flatDirty,
          })
        );
      } catch (e) {
        // Suppress the error, but log it
        console.error(e);
      }
    };

    window.addEventListener("beforeunload", storeDirtyValues);

    return () => {
      storeDirtyValues();
      window.removeEventListener("beforeunload", storeDirtyValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId]);
}
