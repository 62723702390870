import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import ErrorBoundary from "@components/Utility/ErrorBoundary";
import Loading from "@components/Utility/Loading";
import ErrorModal from "@components/Utility/Modals/ErrorModal";
import StepperLoader from "@components/Utility/StepperLoader";
import NavWrapper from "@components/navigation/NavWrapper";
import { GlobalConfirmProvider } from "@features/confirm";
import { ItemModal } from "@features/items";
import { FetchUserDraftOrderSets } from "@features/ordering";
// import { FetchUserDraftOrderSets } from "@features/ordering";
import { GlobalReportManager } from "@features/reports";
import { fetchChannels } from "@redux/slices/channelSlice";
import { fetchGroupCategories } from "@redux/slices/groupCategories/groupCategorySlice";
import {
  fetchOrderWindows,
  setAvailableWindows,
} from "@redux/slices/orderWindows/orderWindowSlice";
import {
  clearTerritories,
  fetchTerritories,
} from "@redux/slices/territories/territorySlice";
import {
  fetchSupplierCounts,
  fetchUser,
  loginWithToken,
  removeUser,
  setExpires,
  setIsLoading,
  setRefetchData,
} from "@redux/slices/user/currentUserSlice";
import { fetchVariantCategories } from "@redux/slices/variantCategories/variantCategorySlice";
import client, { useClearSwrCache } from "@services/api";
import { LastLocationProvider } from "@services/reactRouterDom";
import permissions from "@utils/permissions";

import { logoutUser } from "./api/userApi";
import { useRedirect } from "./hooks/useRedirect";
import Landing from "./pages/Landing";
import { storage } from "@utility/storage";

axios.defaults.headers.get["Cache-Control"] = "no-cache";
axios.defaults.timeout = 20000;

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const clearCache = useClearSwrCache();
  const queryClient = useQueryClient();

  const [currentUser, setCurrentUser] = useState(
    storage.getItem("brandhub-silver-user")
  );
  const isStepperLoad = useSelector((state) => state.globalState.isStepperLoad);
  const user = useSelector((state) => state.currentUser);
  const {
    role,
    sessionExpire,
    isLoading,
    loggedIn,
    error,
    refetchData,
    currentTerritoryId,
    currentChannelId,
    loginError,
    loginType,
    id: userId,
    hasFetchedOrgData,
  } = user;

  const {
    isLoading: isWindowsLoading,
    windows,
    hasSetWindows,
  } = useSelector((state) => state.orderWindows);

  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
    isLoading: isAuthLoading,
  } = useAuth0();

  const fetchOrgSpecificData = useCallback(
    async (role) => {
      if (permissions.internalRoles.includes(role)) {
        dispatch(fetchGroupCategories());
        dispatch(fetchVariantCategories());
        dispatch(fetchTerritories());
        dispatch(fetchChannels());
        dispatch(fetchOrderWindows());
      }
      if (role === "supplier" && user.organization.id) {
        dispatch(fetchSupplierCounts());
      }
    },
    [dispatch, user.organization.id]
  );

  const handleLogout = useCallback(
    (expired = false) => {
      let returnToUrl = window.location.origin;
      if (expired) {
        returnToUrl += `?expired=true&r=${window.location.pathname}`;
      }
      dispatch(setIsLoading());
      setCurrentUser(null);
      dispatch(removeUser());
      dispatch(clearTerritories());
      logoutUser();
      // Clear SWR cache
      clearCache();
      // Clear react-query cache
      queryClient.clear();
      logout({ returnTo: returnToUrl });
    },
    [dispatch, logout, clearCache, queryClient]
  );

  useEffect(() => {
    const fetchCurrentUser = () => {
      dispatch(fetchUser());
    };

    if (currentUser && JSON.parse(currentUser).access_token) {
      if (new Date(JSON.parse(currentUser).expires_at) < new Date()) {
        handleLogout();
      } else if (!userId) {
        const bearer = `Bearer ${JSON.parse(currentUser).access_token}`;
        axios.defaults.headers.common["Authorization"] = bearer;
        client.headers["Authorization"] = bearer;
        fetchCurrentUser();
      } else if (loggedIn && userId && !hasFetchedOrgData) {
        fetchOrgSpecificData(role);
      }
    } else {
      setCurrentUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentUser, loggedIn, userId, hasFetchedOrgData]);

  useEffect(() => {
    if (loggedIn && !currentUser) {
      setCurrentUser(storage.getItem("brandhub-silver-user"));
    }
  }, [loggedIn, currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    const authUser = JSON.parse(currentUser);
    if (
      currentUser &&
      authUser.expires_at &&
      sessionExpire !== authUser.expires_at
    ) {
      dispatch(
        setExpires({
          expires: authUser.expires_at,
        })
      );
    }
  }, [sessionExpire, currentUser, dispatch, handleLogout]);

  useEffect(() => {
    let timeout = null;
    if (sessionExpire) {
      let msToLogout = new Date(sessionExpire) - new Date();
      timeout = setTimeout(() => handleLogout(true), Math.max(0, msToLogout));
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [sessionExpire, handleLogout]);

  useEffect(() => {
    if (
      windows.length > 0 &&
      currentTerritoryId &&
      !hasSetWindows &&
      !isWindowsLoading
    ) {
      dispatch(
        setAvailableWindows({
          channelId: currentChannelId,
          territoryId: currentTerritoryId,
        })
      );
    }
  }, [
    windows,
    currentTerritoryId,
    hasSetWindows,
    isWindowsLoading,
    currentChannelId,
    dispatch,
  ]);

  useEffect(() => {
    if (refetchData) {
      dispatch(setRefetchData({ value: false }));
      window.location.reload();
    }
  }, [refetchData, dispatch, fetchOrgSpecificData]);

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      const claims = await getIdTokenClaims();
      dispatch(loginWithToken(token, new Date(claims.exp * 1000)));
    };
    if (
      isAuthenticated &&
      !loginError &&
      !isLoading &&
      !loggedIn &&
      !isAuthLoading
    ) {
      dispatch(setIsLoading());
      getToken();
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isAuthLoading,
    loginError,
    isLoading,
    loggedIn,
  ]);

  useEffect(() => {
    if (isAuthenticated && loginError) {
      logoutUser();
      setCurrentUser(null);
      logout({ returnTo: `${window.location.origin}?user_error=true` });
    }
  }, [isAuthenticated, loginError, logout, dispatch]);

  useEffect(() => {
    if (
      !isAuthLoading &&
      !isAuthenticated &&
      loggedIn &&
      loginType === "auth0"
    ) {
      handleLogout();
    }
  }, [isAuthLoading, isAuthenticated, loggedIn, loginType, handleLogout]);

  useRedirect(location);

  if (error) {
    navigate("/whoops");
  }

  return (
    <ErrorBoundary location={location}>
      <ErrorModal />

      {/* Stepper loader */}
      {isStepperLoad && <StepperLoader />}

      {loggedIn && role && (
        <LastLocationProvider>
          <GlobalReportManager>
            <GlobalConfirmProvider>
              <NavWrapper>
                {/* Outlet is where reract-router will render nested routes */}
                <Outlet />
                <ItemModal />
                <FetchUserDraftOrderSets />
              </NavWrapper>
            </GlobalConfirmProvider>
          </GlobalReportManager>
        </LastLocationProvider>
      )}

      {((loggedIn && !role) || isLoading) && <Loading partial={false} />}
      {!loggedIn && <Landing />}
    </ErrorBoundary>
  );
};

export default App;
