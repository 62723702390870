import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import PropTypes from "prop-types";

import {
  createOrUpdateVariantAllocation,
  getAllocationHistory,
} from "@redux/slices/items/itemSlice";
import { roundUp } from "@utility/utilityFunctions";
import { upCase } from "@utility/utilityFunctions";

import { useNumberOnlyInput } from "../../../hooks/inputs/useNumberOnlyInput";
import SingleTerritoryAutoComplete from "../../Utility/AutoCompleteFields/SingleTerritoryAutoComplete";
import SingleUserAutoComplete from "../../Utility/AutoCompleteFields/SingleUserAutoComplete";
import AllocationHistoryTable from "./AllocationHistoryTable";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  twoWideContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  twoWide: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const AllocationModal = ({
  open,
  allocation,
  variantId,
  variantIdentifier,
  itemId,
  type,
  available,
  packSize,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [reset, setReset] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentTerritory, setCurrentTerritory] = useState(null);

  const { value, setValue, bind } = useNumberOnlyInput(
    allocation ? `${allocation.allocatedQty}` : ""
  );

  const {
    organization: { allocationLocation },
  } = useSelector((state) => state.currentUser);
  const { allocationHistory } = useSelector((state) => state.items);

  const handleAddRelationship = (value) => {
    if (allocationLocation === "user") {
      setCurrentUser(value);
    } else setCurrentTerritory(value);
  };

  const handleSubmit = () => {
    dispatch(
      createOrUpdateVariantAllocation(
        allocation ? allocation.id : null,
        variantId,
        value,
        currentUser?.id ?? null,
        currentTerritory?.id ?? null,
        itemId
      )
    );
    handleClose();
  };

  useEffect(() => {
    if (type === "edit") {
      dispatch(getAllocationHistory(allocation.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => handleClose()}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.centeredModal}>
            <Typography
              className={clsx(classes.settingsMargin, classes.headerText)}
            >
              {type === "new"
                ? `Creating Inventory Allocation for Sku ${variantIdentifier}`
                : `Editing Inventory Allocation for Sku ${variantIdentifier}`}
            </Typography>
            <Typography className={classes.bodyText}>
              <b>Shipped in Packs Of:</b>
              {` ${packSize}`}
            </Typography>
            <br />
            {type === "edit" && allocationHistory && (
              <>
                <AllocationHistoryTable history={allocationHistory} />
                <br />
              </>
            )}
            {type === "edit" && !allocationHistory && (
              <>
                <br />
                <CircularProgress color="secondary" />
                <br />
                <Divider className={classes.fullWidth} />
                <br />
              </>
            )}
            <div className={classes.twoWideContainer}>
              {type === "new" && allocationLocation === "user" && (
                <div className={classes.twoWide}>
                  <SingleUserAutoComplete
                    classes={classes}
                    handleChange={handleAddRelationship}
                    reset={reset}
                    setReset={setReset}
                    disabled={false}
                    noMargin
                  />
                </div>
              )}
              {type === "new" && allocationLocation === "territory" && (
                <div className={classes.twoWide}>
                  <SingleTerritoryAutoComplete
                    classes={classes}
                    handleChange={handleAddRelationship}
                    reset={reset}
                    setReset={setReset}
                    disabled={false}
                    noMargin
                  />
                </div>
              )}
              {type === "edit" && (
                <div className={classes.twoWide}>
                  <Typography className={classes.bodyText}>
                    <b>{`${upCase(allocationLocation)}: `}</b>
                    {`${
                      allocationLocation === "user"
                        ? allocation.user.name
                        : allocation.territory.name
                    }`}
                  </Typography>
                </div>
              )}
              <TextField
                size="small"
                className={classes.twoWide}
                variant="outlined"
                color="secondary"
                name="qty"
                type="text"
                label="Allocated Qty"
                onBlur={() => {
                  if (+value % packSize !== 0) {
                    setValue(`${roundUp(+value, packSize)}`);
                  }
                }}
                {...bind}
              />
            </div>
            <br />
            <Typography className={classes.bodyText} color="textSecondary">
              <em>
                * Allocations must be made in multiples of the items pack size,
                your allocation will automatically be rounded up to meet this
                requirement
              </em>
            </Typography>
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={
                (type === "new" &&
                  allocationLocation === "user" &&
                  !currentUser) ||
                (type === "new" &&
                  allocationLocation === "territory" &&
                  !currentTerritory) ||
                value.length === 0 ||
                +value - (allocation?.allocatedQty || 0) > available
              }
            >
              SUBMIT
            </Button>
            {value.length > 0 &&
              +value - (allocation?.allocatedQty || 0) > available && (
                <>
                  <br />
                  <Typography className={classes.bodyText} color="error">
                    <em>Allocation exceeds available quantity</em>
                  </Typography>
                </>
              )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AllocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  allocation: PropTypes.object,
  variantId: PropTypes.string.isRequired,
  variantIdentifier: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  available: PropTypes.number,
  packSize: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AllocationModal;
