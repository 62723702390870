import { usePaginatedItemsQuery } from "@features/items";

export default function useProgramItems(
  programId: string | undefined,
  { isPublic }: { isPublic: boolean }
) {
  return usePaginatedItemsQuery(
    {
      filter: {
        programIds: [programId as string],
        ...(isPublic && { isDraft: false, isActive: true, isVisible: true }),
      },
    },
    {
      enabled: !!programId,
      initialPagesToLoad: 10,
    }
  );
}
