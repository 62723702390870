import { useState } from "react";
import { useSelector } from "react-redux";

import {
  CancelTwoTone,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { formatMoney } from "@utility/utilityFunctions";

import StandardTableHead from "../Utility/StandardTableHead";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  rowRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  okChip: {
    backgroundColor: "#004d00",
    color: "white",
  },
  holdChip: {
    backgroundColor: "#920000",
    color: "white",
  },
}));

const ShippingParameterTableRow = ({
  rowData,
  handleEditAddressOpen,
  handleEditSPVOpen,
}) => {
  const classes = useStyles();
  const { status } = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const {
    role,
    organization: { usesItemCustomization, includesTaxes },
  } = useSelector((state) => state.currentUser);
  const readOnly = ["supplier", "select-account-manager"].includes(role);

  const [rowOpen, setRowOpen] = useState(false);

  return (
    <>
      <TableRow className={classes.rowRoot}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setRowOpen(!rowOpen)}
          >
            {rowOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{rowData.addressName}</TableCell>
        <TableCell align="left">{rowData.fullAddress}</TableCell>
        <TableCell align="left">{rowData.attn ?? "---"}</TableCell>
        {!readOnly && status !== "complete" && (
          <TableCell>
            <Tooltip title="Edit Shipping Parmeter Address">
              <IconButton
                size="small"
                onClick={() => handleEditAddressOpen(rowData)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={!readOnly && status !== "complete" ? 5 : 4}
        >
          <Collapse in={rowOpen} timeout="auto" unmountOnExit>
            <Box margin={1} style={{ overFlowX: "scroll" }}>
              <TableContainer>
                <Table size="small" aria-label="shipping-parameter-details">
                  <StandardTableHead
                    classes={classes}
                    headCells={[
                      { id: "warehouseId", label: "External ID" },
                      { id: "variant", label: "Variant" },
                      usesItemCustomization && {
                        id: "customization",
                        label: "Customization",
                      },
                      { id: "qty", label: "Qty" },
                      { id: "method", label: "Method" },
                      { id: "carrier", label: "Carrier" },
                      { id: "tracking", label: "Tracking" },
                      includesTaxes && { id: "tax", label: "Tax" },
                      { id: "actualShipDate", label: "Ship Date" },
                      { id: "status", label: "Status" },
                      !readOnly &&
                        status !== "complete" && {
                          id: "actions",
                          label: "Cancel",
                        },
                    ].filter(Boolean)}
                  />
                  <TableBody>
                    {rowData.shippingParameterVariants.map((spv) => (
                      <TableRow key={spv.id}>
                        <TableCell align="left">
                          {spv.externalWarehouseId ?? spv.sku}
                        </TableCell>
                        <TableCell align="left">
                          {spv.variantNames ?? "---"}
                        </TableCell>
                        {usesItemCustomization && (
                          <TableCell align="left">
                            {spv.customization ?? "---"}
                          </TableCell>
                        )}
                        <TableCell align="left">{spv.qty}</TableCell>
                        <TableCell align="left">
                          {spv.method ?? "---"}
                        </TableCell>
                        <TableCell align="left">
                          {spv.trackingData.carrier ?? "---"}
                        </TableCell>
                        <TableCell align="left">
                          {spv.trackingData.number ?? "---"}
                        </TableCell>
                        {includesTaxes && (
                          <TableCell align="left">
                            {formatMoney(spv.tax, true)}
                          </TableCell>
                        )}
                        <TableCell align="left">
                          {spv.actualShipDate ?? "---"}
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            classes={{
                              root:
                                spv.status === "ok"
                                  ? classes.okChip
                                  : classes.holdChip,
                            }}
                            label={
                              spv.status === "ok" && !spv.actualShipDate
                                ? "OK TO SHIP"
                                : spv.status === "ok" && spv.actualShipDate
                                  ? "SHIPPED"
                                  : spv.status.toUpperCase()
                            }
                          />
                        </TableCell>
                        {!readOnly && status !== "complete" && (
                          <TableCell align="left">
                            <Tooltip title="Cancel or Destroy Shipping Parmeter Item">
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditSPVOpen(spv.id)}
                                  disabled={spv.status !== "ok"}
                                >
                                  <CancelTwoTone />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ShippingParameterTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleEditAddressOpen: PropTypes.func.isRequired,
  handleEditSPVOpen: PropTypes.func.isRequired,
};

export default ShippingParameterTableRow;
