import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { promotionsKeyFactory } from "@features/promotions";
import client, { useMutateError } from "@services/api";
import { kebabCaseKeys } from "@utility/utilityFunctions";

import { useCurrentOrderSet } from "../../CurrentOrderSetContext";
import { orderSetsKeyFactory } from "../orderSetQueries";

/**
 * Toggles a promotion id or code for the current order set
 */
export default function useUpdateOrderSetPromotionsMutation() {
  const setError = useMutateError();
  const {
    orderSet: { id, promotionIds },
  } = useCurrentOrderSet();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (promotionId?: string | null) => {
      return client
        .post(
          `order-sets/${id}/update-promotions`,
          kebabCaseKeys(
            promotionId
              ? {
                  promotionIds: _.xor(promotionIds, [+promotionId]),
                }
              : { promotionIds }
          ),
          { serializeBody: false }
        )
        .then((data) => data.data);
    },
    onSuccess: (newOrderSet) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(id).queryKey,
        newOrderSet
      );
      queryClient.invalidateQueries({
        queryKey: promotionsKeyFactory.list._def,
      });
    },
    onError: (error, promotionId) => {
      console.error(error);
      if (!promotionId) {
        setError(
          new Error(
            "One or more promotions applied to this order set no longer apply and have been removed."
          )
        );
      } else {
        setError(error, "Order Set Update Promotions");
      }
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(id).queryKey,
      });
    },
  });
}
