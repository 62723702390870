import { BudgetWithCalcs } from "@models/Budget";

export const calculateBudgetTotal = (
  budget: BudgetWithCalcs,
  ogBudgetId: string | number,
  includeShippingInBudgets: boolean,
  includeTaxesInBudgets: boolean,
  totalEstFreight: number,
  totalEstTax: number,
  totalVariantPrice: number
) => {
  if (+budget.id === +ogBudgetId) {
    return (
      +budget.pendingBalance +
      totalVariantPrice +
      (includeShippingInBudgets ? totalEstFreight : 0) +
      (includeTaxesInBudgets ? totalEstTax : 0)
    );
  } else {
    return budget.pendingBalance;
  }
};
