/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import { OpaqueCard } from "@components/StyledComponents";
import { isOrderWindowActive } from "@features/orderWindows";
import {
  useCreateOrderSetMutation,
  useCurrentPreOrderOrderSetQuery,
} from "@features/ordering";
import { Program } from "@models";
import { useMutateError } from "@services/api";
import { CldImage } from "@services/cloudinary";
import { formatUtcDate } from "@utility/utilityFunctions";
import useRoleIs from "@utils/useRoleIs";

type ProgramCardProps = {
  program: Program;
  orderWindowId?: string;
};

const ProgramOrderButton = ({ program, orderWindowId }: ProgramCardProps) => {
  const setError = useMutateError();
  const navigate = useNavigate();
  const { orderSet, isLoading } = useCurrentPreOrderOrderSetQuery(
    program.id,
    orderWindowId
  );

  const createOrderSetMutation = useCreateOrderSetMutation();

  const handleCreateOrderSet = () => {
    createOrderSetMutation.mutate(
      {
        orderSetType: "pre-order",
        preOrderOptions: {
          orderWindowId: orderWindowId!,
          programId: program.id,
        },
      },
      {
        onSuccess: (os) => {
          navigate(`/order-sets/${os.id}`);
        },
        onError: (err) => {
          setError(err, "Create Order Set");
        },
      }
    );
  };

  return (
    <Button
      tw="text-primary-600 normal-case"
      disabled={isLoading}
      onClick={(evt) => {
        evt.stopPropagation();
        if (orderSet) {
          navigate(`/order-sets/${orderSet.id}`);
        } else {
          handleCreateOrderSet();
        }
      }}
    >
      {orderSet ? "View Order" : "Order"}
    </Button>
  );
};

const ProgramCard = ({ program, orderWindowId }: ProgramCardProps) => {
  const roleIs = useRoleIs();

  const orderWindow = program.orderWindows.find(
    (ow) => ow.id === orderWindowId
  );
  const isProgramOrderable = orderWindow && isOrderWindowActive(orderWindow);

  const link = orderWindowId
    ? `/programs/${program.id}/order/${orderWindowId}`
    : `/programs/${program.id}`;

  const description =
    program.description?.length > 200
      ? program.description.substr(0, 200) + "..."
      : program.description;

  return (
    <OpaqueCard tw="flex flex-col justify-between p-0 relative">
      <CldImage
        tw="absolute inset-0 object-cover object-center w-full h-full"
        publicId={program.cloudinaryId}
        size="medium"
        alt=""
      />
      <div tw="flex gap-6 p-4 bg-white/90 backdrop-blur-md h-full">
        <Link tw="flex-none" to={link}>
          <CldImage
            tw="rounded-lg w-40 h-40 object-contain object-center"
            publicId={program.cloudinaryId}
            alt={program.name}
            size="medium"
          />
        </Link>
        <div>
          <Link to={link}>
            <h3 tw="mt-2 text-xl font-bold text-neutral-700">{program.name}</h3>
          </Link>
          <div tw="flex flex-wrap mt-1 gap-x-6 text-neutral-600 tracking-wide whitespace-nowrap text-sm">
            <div>
              {orderWindow && (
                <>
                  <div>
                    Closes:
                    <b> {formatUtcDate(orderWindow.closeDate, "PP")}</b>
                  </div>
                  <div>
                    In Market:
                    <b> {formatUtcDate(orderWindow.inMarketDate, "PP")}</b>
                  </div>
                </>
              )}
            </div>
          </div>
          <p tw="text-neutral-500 mt-2">{description}</p>
          {!roleIs("read-only") && isProgramOrderable && (
            <ProgramOrderButton
              program={program}
              orderWindowId={orderWindowId}
            />
          )}
        </div>
      </div>
    </OpaqueCard>
  );
};

export default ProgramCard;
