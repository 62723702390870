import { Order, OrderStatus } from "./Order";
import { Variant } from "./Variant";

type DateString = string;
type DateTimeString = string;

export type OrderVariant = {
  actualShipDate?: DateTimeString;
  addressExternalId?: string;
  addressName: string;
  budgetId?: string;
  cancelationNote: string;
  cancelationType?: string;
  canceledQty: number;
  city: string;
  costCenterName?: string;
  id: string;
  inMarketDate: DateString;
  isRush?: boolean;
  order: Order;
  orderApprovedAt?: string | null;
  orderExpeditedDate?: string;
  orderIsExpedited: boolean;
  orderStatus: OrderStatus;
  orderSubmittedAt?: string;
  orderType: string;
  orderUserExternalId?: string;
  orderUserName: string;
  orderWindowInMarketDate: DateString | null;
  poId?: string;
  poInMarketDate?: DateString;
  price: string;
  program?: string;
  programName?: string;
  promotionDiscount: string;
  qty: number;
  qtyPerPack: number;
  region?: string;
  sku: string;
  state?: string;
  status: string;
  streetAddress1: string;
  streetAddress2?: string;
  territoryName: string;
  totalActualShippingCost: string;
  totalEstimatedShippingCost: string;
  totalEstimatedTax: string;
  totalPrice: string;
  trackingData?: {
    carrier: string;
    number: string;
  };
  variant: Variant;
  zip: string;
};

export const orderVariantDatePropertyMap = {
  "on-demand": "poInMarketDate",
  "pre-order": "orderWindowInMarketDate",
};
