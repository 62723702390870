/** @jsxImportSource @emotion/react */
import "twin.macro";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { capitalize } from "lodash";
import colors from "tailwindcss/colors";

import { formatMoney } from "@utility/utilityFunctions";

Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf" },
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    fontFamily: "Roboto",
    margin: "24px 4vw",
    width: "92vw",
  },
  bold: { fontWeight: "bold" },
  text: {
    lineHeight: 1.6,
  },
  label: {
    fontSize: 9,
    lineHeight: 1.6,
    color: colors.neutral[600],
    textTransform: "uppercase",
    letterSpacing: 0.4,
  },
});

const PDFTable = ({ data, columns, style, options }) => (
  <View style={style}>
    <View
      style={{
        ...(options.borders && {
          borderBottom: `1px solid ${colors.neutral[200]}`,
        }),
        flexDirection: "row",
        fontSize: 10,
        fontWeight: "bold",
        padding: "4px 0",
      }}
    >
      {columns.map((col) => (
        <View style={{ width: col.width }} key={col.key}>
          <Text style={{ textAlign: col.align ?? "left" }}>{col.title}</Text>
        </View>
      ))}
    </View>
    {data.map((row, i) => (
      <View
        key={i}
        style={{
          ...(options.borders && {
            borderBottom: `1px solid ${colors.neutral[200]}`,
          }),
          flexDirection: "row",
          fontSize: 10,
          padding: "4px 0",
        }}
      >
        {columns.map((col) => (
          <View style={{ width: col.width }} key={col.key}>
            <Text style={{ textAlign: col.align || "left", width: "100%" }}>
              {col.tf ? col.tf(row[col.key], row) : row[col.key]}
            </Text>
          </View>
        ))}
      </View>
    ))}
  </View>
);

const OrderInvoicePDF = ({ order, totals }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed>
          <Image
            src="https://res.cloudinary.com/brandhub/image/upload/v1671484179/prod/Brandhub/Logos/b7nusieoegbwqbnllbfb.png"
            style={{ width: 120, marginBottom: 24 }}
          />
        </View>
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            width: "92vw",
          }}
        >
          <View style={{ width: "50%" }}>
            <Text style={[styles.text, styles.bold]}>Details:</Text>
            <Text style={styles.text}>Order ID: {order.id}</Text>
            <Text style={styles.text}>Ordered by: {order.user}</Text>
            <Text style={styles.text}>Status: {capitalize(order.status)}</Text>
            <Text style={styles.text}>Program: {order.program}</Text>
            <Text style={styles.text}>Territory: {order.territory}</Text>
            <Text style={styles.text}>Order Date: {order.submittedAt}</Text>
            <Text style={styles.text}>Approval Date: {order.approvedAt}</Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={[styles.text, styles.bold]}>Ship to:</Text>
            {order.attn && (
              <Text style={styles.text}>
                <Text style={{ fontWeight: "bold" }}>Attn:</Text> {order.attn}
              </Text>
            )}
            <Text style={styles.text}>{order.address.name}</Text>
            <Text style={styles.text}>{order.address.streetOne}</Text>
            {order.address.streetTwo && (
              <Text style={styles.text}>{order.address.streetTwo}</Text>
            )}
            <Text style={styles.text}>
              {order.address.city}, {order.address.stateOrRegion}{" "}
              {order.address.zip}
            </Text>
            <Text style={styles.text}>{order.address.country}</Text>
          </View>
        </View>
        <PDFTable
          options={{ borders: true }}
          style={{ margin: "24px 0 8px", width: "92vw" }}
          data={order.variants}
          columns={[
            {
              key: "sku",
              title: "Item",
              width: "20vw",
              tf: (_, v) => v.warehouseId ?? v.sku,
            },
            {
              key: "name",
              title: "Description",
              width: "36vw",
              tf: (name, row) =>
                name + (row.variantNames ? ` - ${row.variantNames}` : ""),
            },
            { key: "count", title: "Quantity", width: "8vw", align: "right" },
            {
              tf: (p) => formatMoney(p),
              key: "price",
              title: "Price",
              width: "14vw",
              align: "right",
            },
            {
              tf: (_, row) => formatMoney(row.count * row.price),
              key: "total",
              title: "Total",
              width: "14vw",
              align: "right",
            },
          ]}
        />
        <View
          style={{
            marginTop: 8,
            width: "92vw",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <View style={{ alignItems: "flex-end" }}>
            <Text style={styles.text}>Subtotal:</Text>
            {order.discount && <Text style={styles.text}>Discount:</Text>}
            <Text style={styles.text}>Tax:</Text>
            <Text style={styles.text}>Freight:</Text>
            <Text style={styles.text}>Total:</Text>
          </View>
          <View
            style={{
              marginLeft: 16,
              alignItems: "flex-end",
              fontWeight: "bold",
            }}
          >
            <Text style={styles.text}>{formatMoney(order.totalPrice)}</Text>
            {order.discount && (
              <Text style={styles.text}>{formatMoney(order.discount)}</Text>
            )}
            <Text style={styles.text}>{totals.estTax}</Text>
            <Text style={styles.text}>{totals.estFreight}</Text>
            <Text style={styles.text}>{totals.grandTotal}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OrderInvoicePDF;
