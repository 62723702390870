import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { addMonths, format, startOfMonth, subDays, subMonths } from "date-fns";
import PropTypes from "prop-types";

import ChannelAutoComplete from "@components/Utility/AutoCompleteFields/ChannelAutoComplete";
import { ResourceAutocomplete, SelectInput } from "@utils/forms";

import { FilterRow } from "../Filtering/FilterComponents";
import { StyledButton } from "../StyledComponents";
import GroupAutoComplete from "../Utility/AutoCompleteFields/GroupAutoComplete";
import TerritoryAutoComplete from "../Utility/AutoCompleteFields/TerritoryAutoComplete";
import UserAutoComplete from "../Utility/AutoCompleteFields/UserAutoComplete";

const FiltersInventoryReports = ({
  reset,
  setReset,
  handleFilters,
  bindSearch,
  handleSearch,
  filterType,
}) => {
  const [startMonth, setStartMonth] = useState(
    startOfMonth(addMonths(new Date(), -11))
  );
  const [endMonth, setEndMonth] = useState(startOfMonth(new Date()));

  const isLoading = useSelector((state) => state.reports.isLoading);
  const { categories } = useSelector((state) => state.groupCategories);
  const { groupedBy } = useSelector((state) => state.filters);
  const {
    organization: { allocationLocation },
    role,
    id,
    currentTerritoryId,
    name,
  } = useSelector((state) => state.currentUser);

  const [organizerTerritory, setOrganizerTerritory] =
    useState(currentTerritoryId);

  const territoryList = useSelector((state) => state.territories.territoryList);

  useEffect(() => {
    handleFilters(
      `${format(startMonth, "MM/dd/yyyy")} - ${format(
        subDays(addMonths(endMonth, 1), 1),
        "MM/dd/yyyy"
      )}`,
      "dateRange",
      filterType
    );

    if (
      allocationLocation === "territory" &&
      filterType === "reports-variant-allocation-report" &&
      role === "orderer"
    ) {
      handleFilters(
        [
          {
            id: organizerTerritory,
            name:
              territoryList.find((t) => t.id === organizerTerritory)?.name ??
              "",
          },
        ],
        "territoryIds",
        filterType
      );
    }

    if (
      allocationLocation === "user" &&
      filterType === "reports-variant-allocation-report" &&
      role === "orderer"
    ) {
      handleFilters([{ id: id, name: name }], "userIds", filterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMonth, endMonth, filterType, organizerTerritory, territoryList]);

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <div>
      <FilterRow>
        {filterType === "reports-inventory-velocity" && (
          <>
            <SelectInput
              label="Group by"
              value={groupedBy}
              onChange={(e) => {
                handleFilters(
                  e.target.value,
                  "groupedBy",
                  "reports-inventory-velocity"
                );
              }}
              options={[
                { id: "approval-date", name: "Approval Date" },
                { id: "ship-date", name: "Ship Date" },
              ]}
            />

            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label={"Start Month"}
              views={["month", "year"]}
              value={startMonth}
              maxDate={subMonths(endMonth, 1)}
              onChange={(date) => {
                setStartMonth(date);
              }}
            />
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label={"End Month"}
              views={["month", "year"]}
              value={endMonth}
              minDate={addMonths(startMonth, 1)}
              onChange={(date) => {
                setEndMonth(date);
              }}
            />

            <ChannelAutoComplete
              handleChange={handleFilters}
              filterType={filterType}
              reset={reset}
              setReset={setReset}
            />

            {categories.map((cat) => (
              <GroupAutoComplete
                key={cat.id}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={filterType}
                categoryLabel={cat.name}
                options={cat.groups}
              />
            ))}
          </>
        )}
        {filterType === "reports-variant-allocation-report" && (
          <>
            <TextField
              color="secondary"
              fullWidth
              name="itemNumber"
              type="text"
              label="Search Sku, External ID"
              variant="outlined"
              size="small"
              {...bindSearch}
              onKeyUp={handleKeyUp}
              disabled={isLoading}
            />
            {allocationLocation === "user" && role !== "orderer" && (
              <UserAutoComplete
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={filterType}
              />
            )}
            {allocationLocation === "territory" && role !== "orderer" && (
              <TerritoryAutoComplete
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={filterType}
              />
            )}
            {allocationLocation === "territory" && role === "orderer" && (
              <ResourceAutocomplete
                label="Territory"
                resource="territories"
                value={organizerTerritory}
                disableClearable
                onChange={(e, val) => {
                  setOrganizerTerritory(val.id);
                  handleFilters([{ id: val.id }], "territoryIds", filterType);
                }}
              />
            )}
          </>
        )}

        <StyledButton cta onClick={handleSearch} disabled={isLoading}>
          GENERATE REPORT
        </StyledButton>
      </FilterRow>
    </div>
  );
};

FiltersInventoryReports.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  sku: PropTypes.string,
  bindSku: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default FiltersInventoryReports;
