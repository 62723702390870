/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import {
  setChips,
  setClear,
  setClearFilter,
  setSorted,
  updateSingleFilter,
} from "@redux/slices/filterSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const freeTypeFilters = ["sku", "name"];

const FilterChipList = ({ className }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const filterState = useSelector((state) => state.filters);
  const filterType = useSelector((state) => state.filters.filterType);

  const handleChipClick = ({ type, id }) => {
    let dispatchObject = { filter: type, value: null };
    // if (id) {
    //   let currentFilterArray = filterState[type].filter((f) => f.id !== id);
    //   dispatchObject.value = currentFilterArray;
    // }
    if (
      [
        "budgetGroupIds",
        "groupIds",
        "territoryIds",
        "programIds",
        "userIds",
        "stateIds",
        "supplierIds",
        "purchaserIds",
        "orderWindowIds",
        "promotionIds",
        "channelIds",
      ].includes(type)
    ) {
      let currentFilterArray = filterState[type].filter((f) => f.id !== id);
      dispatchObject.value = currentFilterArray;
    }
    if (type === "favoriteItems") {
      dispatchObject.value = [];
    }
    if (freeTypeFilters.includes(type)) {
      dispatch(setClearFilter({ key: type, value: true }));
    }
    dispatch(updateSingleFilter(dispatchObject));
    dispatch(setChips({ filterType: filterType }));
    dispatch(setSorted({ sorted: true }));
  };
  return (
    <div
      tw="flex flex-wrap items-center space-x-2 w-[calc(100% - 100px)]"
      className={className}
    >
      {filterState.chipList.map((filter) => (
        <Chip
          color="primary"
          size="small"
          key={`${filter.type}-${filter.value}${filter.id}`}
          label={filter.value}
          onDelete={() => handleChipClick(filter)}
        />
      ))}
      {filterState.chipList.length > 0 && (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.hoverText}
          style={{ marginLeft: "20px" }}
          onClick={() => {
            dispatch(setClear());
          }}
        >
          Clear Filters
        </Typography>
      )}
    </div>
  );
};

export default FilterChipList;
