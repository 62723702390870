/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { Button } from "@mui/material";

import {
  DeliveryInstructionsModal,
  useCreateDeliveryInstructionsMutation,
} from "@features/deliveryInstructions";
import { FaIcon } from "@features/ui";
import { Order } from "@models/Order";

const OrderDeliveryInstructions = ({ order }: { order: Order }) => {
  const {
    address: { id: addressId, deliveryInstructions },
  } = order;
  const createDeliveryInstructions = useCreateDeliveryInstructionsMutation();

  const [deliveryInsturctionsModalOpen, setDeliveryInstructionsModalOpen] =
    React.useState(false);
  const handleCreate = () => {
    createDeliveryInstructions.mutate(
      {
        addressId,
        orderId: order.id,
      },
      {
        onSuccess: () => setDeliveryInstructionsModalOpen(true),
      }
    );
  };
  return (
    <div tw="-ml-1">
      {deliveryInstructions ? (
        <Button
          variant="text"
          size="small"
          color="secondary"
          onClick={() => setDeliveryInstructionsModalOpen(true)}
          tw="text-primary-800 text-left whitespace-nowrap"
        >
          Edit Delivery Instructions
        </Button>
      ) : (
        <Button
          variant="text"
          size="small"
          color="secondary"
          onClick={handleCreate}
          startIcon={<FaIcon icon="warning" tw="text-yellow-800" />}
          tw="bg-yellow-50 text-yellow-800 text-left whitespace-nowrap"
        >
          Add Delivery Instructions
        </Button>
      )}
      {deliveryInstructions && deliveryInsturctionsModalOpen && (
        <DeliveryInstructionsModal
          order={order}
          handleClose={() => setDeliveryInstructionsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default OrderDeliveryInstructions;
