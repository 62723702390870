export const buildOrderVariantPatch = (id, field, value) => {
  return {
    data: {
      type: "order-variant",
      id: id,
      attributes: {
        [field]: value,
      },
    },
  };
};

export const buildOrder = (osId, addressId, type) => {
  return {
    data: {
      type: "order",
      attributes: {
        "order-set-id": osId,
        "address-id": addressId,
        type: type,
      },
    },
  };
};

export const buildOrderPatch = (id, note, attn) => {
  return {
    data: {
      type: "order",
      id: id,
      attributes: {
        notes: note,
        attn: attn,
      },
    },
  };
};
export const buildOrderCancelRequest = (
  id,
  note,
  cancelationType = "order"
) => {
  return {
    id: id,
    "cancelation-type": cancelationType,
    "cancelation-note": note,
  };
};

export const buildAddressChangeRequest = (id, addressId) => {
  return {
    id: id,
    "address-id": addressId,
  };
};
