import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { CostCenter } from "@models/CostCenter";
import client, { RequestParams } from "@services/api";
import { QueryOptions } from "@utils/reactQuery";

const costCenterQueryKeyFactory = createQueryKeys("cost-centers", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<CostCenter[]>("cost-centers", { params })
        .then((res) => res.data),
  }),
});

export const useCostCentersQuery = (
  params: RequestParams,
  options?: QueryOptions<CostCenter[]>
) => {
  return useQuery({
    ...costCenterQueryKeyFactory.list(params),
    placeholderData: keepPreviousData,
    ...options,
  });
};

export const useUserCostCentersQuery = () => {
  const userId = useSelector((state: any) => state.currentUser.id);
  const usesCostCenters = useSelector(
    (state: any) => state.currentUser.organization.usesCostCenters
  );
  return useCostCentersQuery(
    {
      filter: {
        userIds: [userId],
      },
    },
    {
      enabled: usesCostCenters,
    }
  );
};
