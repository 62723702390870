/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import { orderSetsKeyFactory } from "../data";
import OrderDeliveryReview from "../orderSetCheckout/OrderDeliveryReview";
import useOrderSetId from "../useOrderSetId";
import OrderSetOrdersOverview from "./OrderSetOrdersOverview";
import OrderSetSubmittedInfo from "./OrderSetSubmittedInfo";
import OrderSetSummaryActionPanel from "./OrderSetSummaryActionPanel";
import OrderSetVariantsOverview from "./OrderSetVariantsOverview";

const SectionHeader = tw.h4`mb-2 font-medium text-neutral-600`;

const OrderSetOverview = () => {
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const { orderSet, orders } = useCurrentOrderSet();

  useEffect(() => {
    queryClient.invalidateQueries(orderSetsKeyFactory.detail(orderSetId));
  }, [orderSetId, queryClient]);

  return (
    <div>
      {orderSet.status !== "draft" && <OrderSetSubmittedInfo />}
      {/* minmax trick: https://css-tricks.com/preventing-a-grid-blowout/ */}
      <div tw="grid grid-flow-dense grid-cols-1 lg:grid-cols-[minmax(0, 1fr) 320px] gap-4 lg:gap-6 relative">
        <div tw="lg:col-start-2 relative">
          <div tw="lg:sticky lg:top-[var(--header-height)]">
            <SectionHeader>Summary</SectionHeader>
            <OrderSetSummaryActionPanel />
          </div>
        </div>
        <div tw="space-y-8">
          <div>
            <SectionHeader>Item Overview</SectionHeader>
            <OrderSetVariantsOverview />
          </div>

          <div>
            <SectionHeader>Order Overview</SectionHeader>
            {orderSet.status === "draft" && <OrderSetOrdersOverview />}
            {orderSet.status !== "draft" && (
              <div tw="space-y-2">
                {orders.map((order) => (
                  <OrderDeliveryReview order={order} key={order.id} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSetOverview;
