/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";

import { OpaqueCard } from "@components/StyledComponents";
import { useOrderWindowQuery } from "@features/orderWindows";
import { useCanEditOrder } from "@features/orders";
import { Order } from "@models/Order";
import { formatMoneyString, formatUtcDate } from "@utility/utilityFunctions";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import { useUpdateOrder } from "../data";
import DeliverySelectionDropdown from "./DeliverySelectionDropdown";
import OrderDeliveryInstructions from "./OrderDeliveryInstructions";

const PreOrderDelivery = () => {
  const { orderSet } = useCurrentOrderSet();
  const { data } = useOrderWindowQuery(orderSet.orderWindow?.id);
  return (
    <ListItemText
      primary="In Market Date"
      secondary={data ? formatUtcDate(data.inMarketDate, "PP") : <Skeleton />}
    />
  );
};

const OrderDeliveryReview = ({ order }: { order: Order }) => {
  const updateOrder = useUpdateOrder();
  const { usesOrderEventDesignation } = useSelector(
    (state: any) => state.currentUser.organization
  );
  const canEdit = useCanEditOrder(order);
  const includeCosts = true;
  const handleUpdateOrder = (params: Record<string, any>) => {
    updateOrder.mutate({ id: order.id, ...params });
  };
  const canSetDelivery =
    order.type === "inventory" &&
    order.address.country.name === "United States";
  return (
    <OpaqueCard>
      <div tw="flex flex-wrap items-start gap-x-4 gap-y-2 -mt-2">
        <div tw="flex-1 flex-shrink-0">
          <Typography
            {...(order.status !== "draft" && {
              component: Link,
              to: `/orders/history/${order.id}`,
            })}
            tw="text-primary-800 text-sm tracking-wide font-medium"
          >
            #{order.id}
          </Typography>
          <h4 tw="font-medium">{order.address.name}</h4>
          <p tw="text-sm">
            {order.address.streetAddress1} {order.address.streetAddress2}
            <br />
            {order.address.city},{" "}
            {order.address.state?.code ?? order.address.region}{" "}
            {order.address.zip}
            {order.address.country.name !== "United States" &&
              ", " + order.address.country.name}
          </p>
        </div>
        {order.status !== "canceled" && (
          <>
            <div tw="space-y-2 flex-1 flex-shrink-0">
              {canSetDelivery ? (
                <DeliverySelectionDropdown order={order} />
              ) : order.type === "pre-order" ? (
                <PreOrderDelivery />
              ) : (
                <ListItemText primary="Standard Delivery" />
              )}
              <OrderDeliveryInstructions order={order} />
            </div>
            {(includeCosts || usesOrderEventDesignation) && (
              <div>
                {includeCosts && (
                  <div tw="space-y-2 mb-4 mt-1">
                    Total:{" "}
                    {formatMoneyString(
                      order.totalPrice +
                        order.totalEstimatedTax +
                        order.totalEstimatedShippingCost
                    )}
                  </div>
                )}
                {usesOrderEventDesignation && (
                  <FormControlLabel
                    disabled={!canEdit || updateOrder.isPending}
                    control={
                      <Checkbox
                        size="small"
                        tw="-my-1"
                        checked={order.isForEvent}
                        onChange={(e) =>
                          handleUpdateOrder({
                            isForEvent: e.target.checked,
                          })
                        }
                      />
                    }
                    label="For Event"
                    slotProps={{
                      typography: {
                        style: tw`text-sm text-neutral-600`,
                      },
                    }}
                  />
                )}
              </div>
            )}
          </>
        )}
        {order.status === "canceled" && (
          <div tw="flex-1 px-4 py-2 -my-2 rounded bg-red-50">
            <h3 tw="text-sm font-medium text-red-700 uppercase tracking-wide">
              {order.cancelationType === "order" ? "Canceled" : "Denied"}
            </h3>
            <p tw="text-red-900">{order.cancelationNote}</p>
          </div>
        )}
      </div>
    </OpaqueCard>
  );
};

export default OrderDeliveryReview;
