/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";
import { Divider, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";

import {
  Contained,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import { usePaginatedItemsQuery } from "@features/items";
import DocTitle from "@utility/DocTitle";
import { upCase } from "@utility/utilityFunctions";

import { useInput } from "../hooks/inputs/useInput";

/*
This report functions differently than other reports in Brandhub, so it has it's own view (all other reports
happen in the Reports page). Instead of generating a table, this gives a quick snapshot on the inventory levels
of items that match an entered sku.
*/

const useStyles = makeStyles((theme: any) => ({
  ...theme.global,
  searchField: {
    marginBottom: "15px",
    width: "30%",
    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

const CheckInventory = () => {
  const classes = useStyles() as any;

  const [sku, setSku] = useState("");
  const [productId, setProductId] = useState("");

  const {
    organization: { allocationLocation },
    currentChannelId,
  } = useSelector((state: any) => state.currentUser);

  const {
    data: items,
    isLoading,
    isFetched,
  } = usePaginatedItemsQuery(
    {
      filter: {
        ...(sku && { sku }),
        ...(productId && { external_warehouse_id: productId }),
        channelIds: currentChannelId && [currentChannelId],
      },
    },
    { enabled: !!sku || !!productId, placeholderData: undefined }
  );

  const { value: skuValue, bind: bindSku, reset: resetSku } = useInput("");
  const {
    value: productIdValue,
    bind: bindProductId,
    reset: resetProductId,
  } = useInput("");

  const handleSubmit = () => {
    setSku(skuValue.trim());
    setProductId(productIdValue.trim());
  };

  const handleClear = () => {
    setSku("");
    setProductId("");
    resetSku();
    resetProductId();
  };

  return (
    <>
      <DocTitle title={"Inventory Check Report"} />
      <Contained tw="space-y-6">
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Inventory Check Report
          </Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton
              outlined
              startIcon={<ExitToApp style={{ transform: "rotate(180deg)" }} />}
              component={Link}
              to="/reports-dashboard"
            >
              REPORTS
            </StyledButton>
          </div>
        </header>
        <OpaqueCard>
          <Typography className={classes.bodyText}>
            The Inventory Check report allows you to get a quick snapshot of
            inventory levels for a single sku. It will let you know if there are
            pending orders holding inventory, and give details on allocations as
            well.
          </Typography>
        </OpaqueCard>
        <div className={classes.fullWidthCenterColumn}>
          <TextField
            className={classes.searchField}
            size="small"
            label="Sku"
            {...bindSku}
          />
          <TextField
            className={classes.searchField}
            size="small"
            label="External ID"
            {...bindProductId}
          />
          <div className={clsx(classes.center, classes.innerConfigDiv)}>
            <StyledButton
              cta
              loading={isLoading}
              disabled={skuValue.length === 0 && productIdValue.length === 0}
              onClick={handleSubmit}
            >
              GENERATE REPORT
            </StyledButton>
            <StyledButton outlined disabled={!isFetched} onClick={handleClear}>
              RESET REPORT
            </StyledButton>
          </div>
        </div>
        <br />
        {isFetched && items.length === 0 && (
          <div className={clsx(classes.fullWidth, classes.center)}>
            <Typography className={classes.headerText}>
              There are no items that match your current search...
            </Typography>
          </div>
        )}
        {!isLoading &&
          items.length > 0 &&
          items.map((item, index) =>
            item.variants.map((v, i) => (
              <OpaqueCard key={`${index}-${i}`}>
                <Typography className={classes.headerText}>
                  {`Inventory Information for Sku ${v.variantSku}`}
                </Typography>
                <br />
                <Typography className={classes.bodyText}>
                  <b>Warehouse:</b>
                  {` ${upCase(item.warehouse)}`}
                </Typography>
                <br />
                <Typography className={classes.bodyText}>
                  <b>Current On Hand Inventory:</b>
                  {` ${v.quantityOnHand}`}
                </Typography>
                <br />
                {allocationLocation && (
                  <>
                    {v.variantAllocations.length > 0 && (
                      <>
                        <Typography className={classes.headerText}>
                          Allocations
                        </Typography>
                        <br />
                        {v.variantAllocations.map((va, vai) => (
                          <div
                            className={classes.fullLengthCenterColumn}
                            key={`${index}-${i}-${vai}`}
                          >
                            <Typography className={classes.bodyText}>
                              <b>{`${upCase(allocationLocation)}: ${
                                va[allocationLocation].name
                              }`}</b>
                            </Typography>
                            <br />
                            <Typography className={classes.bodyText}>
                              {`Total Allocated: ${va.allocatedQty}`}
                            </Typography>
                            <Typography className={classes.bodyText}>
                              {`Total Remaining: ${va.remainingAllocatedQty}`}
                            </Typography>
                            <Typography className={classes.bodyText}>
                              {`Total Pending: ${
                                va.remainingAllocatedQty -
                                va.availableToOrderQty
                              }`}
                            </Typography>
                            <Typography className={classes.bodyText}>
                              {`Total Available: ${va.availableToOrderQty}`}
                            </Typography>
                            <br />
                          </div>
                        ))}
                        <Divider className={classes.fullWidth} />
                        <br />
                      </>
                    )}
                    <Typography className={classes.bodyText}>
                      <b>Current Available Allocated Inventory:</b>
                      {` ${
                        v.variantAllocations.length > 0
                          ? v.variantAllocations.reduce(
                              (a, b) => a + b.availableToOrderQty,
                              0
                            )
                          : 0
                      }`}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>Current Pending Allocated Inventory:</b>
                      {` ${
                        v.variantAllocations.length > 0
                          ? v.variantAllocations.reduce(
                              (a, b) => a + b.remainingAllocatedQty,
                              0
                            ) -
                            v.variantAllocations.reduce(
                              (a, b) => a + b.availableToOrderQty,
                              0
                            )
                          : 0
                      }`}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>Current Available Unallocated Inventory:</b>
                      {` ${v.globalAvailableToOrderQty}`}
                    </Typography>
                  </>
                )}
                {!allocationLocation && (
                  <>
                    <Typography className={classes.bodyText}>
                      <b>Current Pending Inventory:</b>
                      {` ${
                        (v.cachedWarehouseQty ?? 0) -
                        v.globalAvailableToOrderQty
                      }`}
                    </Typography>
                    <br />
                    <Typography className={classes.bodyText}>
                      <b>Current Available Inventory:</b>
                      {` ${v.globalAvailableToOrderQty}`}
                    </Typography>
                  </>
                )}
              </OpaqueCard>
            ))
          )}
      </Contained>
    </>
  );
};

export default CheckInventory;
