import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setIsOrdering } from "@redux/slices/globalStateSlice";

// Locks channel/territory selection
// when component is mounted.
const useOrderLock = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsOrdering(true));
    return () => {
      dispatch(setIsOrdering(false));
    };
  }, [dispatch]);
};

export default useOrderLock;
