/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useMemo } from "react";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable from "@components/Table/ReactQueryTable";
import { useFilterParams } from "@features/filters";
import { ItemIdentifiers, ItemPreviewImageButton } from "@features/items";
import { Item } from "@models/Item";
import { useSetLocation } from "@services/reactRouterDom";

import { useBeaconVariantsReportQuery } from "../data";
import useBeaconItemLookup from "../useBeaconItemLookup";
import useBeaconSearchFilters from "../useBeaconSearchFilters";
import BeaconProgressBar from "./BeaconProgressBar";

const BeaconVariantsList = () => {
  const setLocation = useSetLocation();
  const [filters] = useFilterParams();

  const items = useBeaconItemLookup();
  const { data = [], ...rest } = useBeaconVariantsReportQuery({
    filter: useBeaconSearchFilters(),
  });

  const itemsWithBeaconInfo = useMemo(
    () =>
      data
        .map((data) => ({
          ...data,
          item: items.get(data.itemId.toString())!,
        }))
        .filter((v) => !!v.item),
    [data, items]
  );

  const handleSelectItem = (item: Item) => {
    setLocation({
      pathname: "/beacons/pings",
      searchParams: {
        ...filters,
        itemId: item.id,
        variantIds: item.variants.map((v) => v.id),
      },
    });
  };

  return (
    <OpaqueCard tw="p-0">
      <ReactQueryTable
        {...rest}
        onRowClick={({ item }) => handleSelectItem(item)}
        rows={itemsWithBeaconInfo}
        columns={[
          {
            id: "_item",
            label: "Item",
            render: (_, { item, estBeaconsExpected, beaconsPinged }) => (
              <div tw="flex items-start gap-4">
                <ItemPreviewImageButton item={item} />
                <div tw="flex-1">
                  <ItemIdentifiers item={item} />
                  <div tw="text-lg">{item.name}</div>
                  <BeaconProgressBar
                    beaconsPinged={beaconsPinged}
                    estBeaconsExpected={estBeaconsExpected}
                  />
                </div>
              </div>
            ),
          },
        ]}
        noResultsText="No Variants with Beaacons for the selected filters."
      />
    </OpaqueCard>
  );
};

export default BeaconVariantsList;
