import { formatMoney, handleOrderGrandTotal, upCase } from "./utilityFunctions";

/*
These are a collection of functions that build headers, and map data for the various
pages that generate their own reports. These reports are generated a little differently
than the normal reports, and instead of getting a large dump of data, the ui loops over
each page of data from the api based on the users current filters.
*/

export const addressData = (report, addressBookType) => {
  const headers = [
    { key: "user", label: "User Name" },
    { key: "territory", label: "Territory" },
    { key: "name", label: "Name" },
    { key: "addressOne", label: "Street Address One" },
    { key: "addressTwo", label: "Street Address Two" },
    { key: "defaultAttn", label: "Attention" },
    { key: "city", label: "City" },
    { key: "state", label: "State / Region" },
    { key: "zip", label: "Zip" },
    { key: "phoneNumber", label: "Phone Number" },
    { key: "countryName", label: "Country" },
  ].filter((h) => {
    if (addressBookType !== "user" && h.key === "user") return false;
    return true;
  });

  const mappedReport = report.map((row) => ({
    user: row.userName ?? "---",
    territory: row.territoryName ?? "---",
    name: row.name,
    addressOne: row.addressOne,
    addressTwo: row.addressTwo ?? "---",
    defaultAttn: row.attn ?? "---",
    city: row.city,
    state: row.stateOrRegion,
    region: row.region ?? "---",
    zip: row.zip,
    phoneNumber: row.phoneNumber ?? "---",
    countryName: row.countryName ?? "---",
  }));

  return {
    data: mappedReport,
    headers: headers,
  };
};

export const orderHistoryByVariant = (categories, organization) => {
  const categoryHeaders = categories.map((c) => ({
    label: c.name,
    id: c.id,
  }));
  const headers = [
    { label: "Order #", id: "orderId" },
    { label: "Order Type", id: "orderType" },
    { label: "Sku", id: "sku" },
    { label: "External ID", id: "externalId" },
    { label: "Item name", id: "name" },
    { label: "Ordered By", id: "user" },
    ...categoryHeaders,
    { label: "Variant", id: "variantNames" },
    { label: "Address Name", id: "addressName" },
    { label: "Address 1", id: "streetAddressOne" },
    { label: "Address 2", id: "streetAddressTwo" },
    { label: "City", id: "city" },
    { label: "State", id: "state" },
    { label: "Zip", id: "zip" },
    { label: "Store ID", id: "addressExternalId" },
    { label: "Program", id: "program" },
    { label: "Total Qty", id: "count" },
    { label: "Promotion", id: "promotionNames" },
    { label: "Promotion Discount", id: "discount" },
    { label: "Price", id: "price" },
    { label: "Extended Price", id: "totalPrice" },
    { label: "Payment Type", id: "orderCheckoutType" },
    organization.budgetLocation && { label: "Budget", id: "budget" },
    organization.usesCostCenters && { label: "Cost Center", id: "costCenter" },
    { label: "Order Date", id: "submittedAt" },
    { label: "Expedited Date", id: "orderExpeditedDate" },
    { label: "Est. Ship Date", id: "inMarketDate" },
    { label: "Act. Ship Date", id: "shipDate" },
    { label: "Carrier", id: "carrier" },
    { label: "Tracking", id: "tracking" },
    { label: "Status", id: "status" },
  ].filter(Boolean);

  const mapFunction = (report) => {
    return report.map((r) => {
      let data = {
        ...r,
        orderType: upCase(r.orderType, "-"),
        externalId: r.warehouseId,
        discount: formatMoney(r.discount, false),
        price: formatMoney(r.price, false),
        budget: r.budget,
        costCenter: r.costCenterName,
        carrier: r.trackingData.carrier,
        tracking: r.trackingData.number,
        status:
          r.status === "canceled"
            ? upCase(r.status, "-")
            : upCase(r.orderStatus, "-"),
      };

      categories.forEach(
        (c) =>
          (data[c.id] =
            r.item.groups.find((group) => group.groupCategoryId === c.id)
              ?.name ?? "---")
      );

      return data;
    });
  };
  return {
    mapFunction,
    headers,
  };
};

export const orderHistoryByOrder = (organization) => {
  const headers = [
    { label: "Order #", id: "orderId" },
    { label: "Order Type", id: "type" },
    { label: "Ordered By", id: "user" },
    { label: "Address Name", id: "addressName" },
    { label: "Address 1", id: "addressStreetOne" },
    { label: "Address 2", id: "addressStreetOne" },
    { label: "City", id: "addressCity" },
    { label: "State", id: "addressState" },
    { label: "Zip", id: "addressZipCode" },
    { label: "Store ID", id: "addressExternalId" },
    { label: "Program", id: "program" },
    { label: "Order Date", id: "submittedAt" },
    { label: "Expedited Date", id: "expeditedDate" },
    { label: "Total Qty", id: "count" },
    { label: "Promotion", id: "promotionNames" },
    { label: "Promotion Discount", id: "discount" },
    { label: "Extended Total", id: "extendedTotal" },
    { label: "Order Total", id: "orderTotal" },
    { label: "Payment Type", id: "checkoutType" },
    organization.budgetLocation && { label: "Budget", id: "budget" },
    organization.usesCostCenters && {
      label: "Cost Center",
      id: "costCenter",
    },
    { label: "Status", id: "status" },
  ].filter(Boolean);

  const mapFunction = (report) =>
    report.map((r) => ({
      ...r,
      orderId: r.id,
      addressName: r.address.name,
      addressStreetOne: r.address.streetOne,
      addressStreetTwo: r.address.streetTwo,
      addressCity: r.address.city,
      addressState: r.address.state,
      addressZipCode: r.address.zip,
      discount: formatMoney(r.discount),
      extendedTotal: formatMoney(r.totalPrice),
      orderTotal: handleOrderGrandTotal(r, organization),
      budget: r.budget ?? "---",
      costCenter: r.costCenterName,
      status: upCase(r.status),
    }));

  return {
    mapFunction,
    headers,
  };
};
