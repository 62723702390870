import { useFilterParams } from "@features/filters";

export default function useBeaconSearchFilters() {
  const [filters] = useFilterParams();
  return {
    programId: filters.programIds?.[0],
    states: filters.states?.join(","),
    variantIds: filters.variantIds,
    purchaseOrderId: filters.poId,
    location: filters.q,
  };
}
