/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";

import { axiosPost } from "src/api/axiosCalls";

import { StyledButton } from "@components/StyledComponents";
import { variantName } from "@features/items";
import { usePaginatedUsersQuery } from "@features/users";
import { usePaginatedVariantsQuery } from "@features/variants";
import { setError } from "@redux/slices/errorSlice";
import { roundUp } from "@utility/utilityFunctions";
import { StyledTextInput } from "@utils/forms/ControlledInputs";
import useDebouncedValue from "@utils/useDebouncedValue";

interface TransferAllocationModalProps {
  open: boolean;
  onClose: () => void;
}

export const TransferAllocationModal = ({
  open,
  onClose,
}: TransferAllocationModalProps) => {
  const dispatch = useDispatch();
  const { name, email } = useSelector((state: any) => state.currentUser);

  const initialFormData = {
    variantToTransfer: "",
    transferToUser: "",
    transferFromUser: name,
    qtyRequested: "",
    hasPermission: false,
    specialNotes: "",
    requesterPhone: "",
    packSize: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [searchTerm, setSearchTerm] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);
  const debouncedUserSearchTerm = useDebouncedValue(userSearchTerm, 300);

  const { data: variants, isLoading } = usePaginatedVariantsQuery({
    filter: {
      search_term: debouncedSearchTerm,
      isActive: true,
      limitToUserTerritories: true,
    },
    page: { size: 50 },
  });

  const { data: users, isLoading: isUsersLoading } = usePaginatedUsersQuery({
    filter: { nameOrEmail: debouncedUserSearchTerm },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await axiosPost("/api/variant-allocations/request-allocation-transfer", {
        originator_id: formData.transferFromUser,
        requestor_phone_number: formData.requesterPhone,
        variant_id: formData.variantToTransfer,
        quantity: parseInt(formData.qtyRequested),
        has_permission: formData.hasPermission,
        notes: formData.specialNotes,
      });

      setIsSuccess(true);
    } catch (error) {
      console.error("Failed to submit transfer request:", error);
      dispatch(setError({ error: error, source: "Draft order set" }));
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<{}>, value: string) => {
    setSearchTerm(value);
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setSearchTerm("");
    setUserSearchTerm("");
    setIsSuccess(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {isSuccess ? "Success!" : "Transfer Allocation Request"}
      </DialogTitle>
      <DialogContent>
        {isSuccess ? (
          <div tw="flex flex-col items-center gap-4 p-4">
            <p tw="text-center text-lg">
              Your transfer request has been submitted successfully!
            </p>
            <StyledButton onClick={handleClose} cta>
              Close
            </StyledButton>
          </div>
        ) : (
          <>
            <div tw="mb-4 text-sm text-gray-600">
              <p tw="font-bold mb-2">Instructions:</p>
              <p>
                To request an allocation transfer, please fill out the form
                below. Please be sure to enter quantities requested to transfer
                in UNITS. Once you are done, click 'Submit Request' to notify
                the brandhub Support Team who will review the request and
                respond via Email within 24 business hours of receipt.
              </p>
              <p tw="mt-2">
                If you have any questions, please contact{" "}
                <a
                  href="mailto:help@selectbrandhub.com"
                  tw="text-blue-600 hover:underline"
                >
                  help@selectbrandhub.com
                </a>
                !
              </p>
            </div>
            <form onSubmit={handleSubmit} tw="flex flex-col gap-4 p-4">
              <div tw="mb-2">
                <Autocomplete
                  options={variants.map((variant) => ({
                    id: variant.id,
                    identifier:
                      variant.externalWarehouseId ?? variant.variantSku,
                    name: variant.item?.name,
                    packSize: variant.item?.packSize,
                    variantName: variantName(variant),
                  }))}
                  getOptionLabel={(option) => {
                    const variantOptionName = option.variantName
                      ? `(${option.variantName})`
                      : "";

                    return `${option.identifier}: ${option.name} ${variantOptionName} - ${option.packSize} per packout`;
                  }}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      variantToTransfer: String(newValue?.id) || "",
                      packSize: String(newValue?.packSize) || "",
                    });
                  }}
                  onInputChange={handleSearchChange}
                  loading={isLoading}
                  renderInput={(params) => (
                    <StyledTextInput
                      {...params}
                      label="Variant to Transfer"
                      required
                      size="small"
                    />
                  )}
                />
              </div>
              <div tw="mb-2">
                <StyledTextInput
                  label="Pack Size"
                  value={formData.packSize}
                  disabled
                  fullWidth
                  size="small"
                />
              </div>
              <div tw="mb-2">
                <StyledTextInput
                  label="Transfer To User"
                  value={name}
                  disabled
                  fullWidth
                  size="small"
                />
              </div>
              <div tw="mb-2">
                <StyledTextInput
                  label="Transfer To User Email"
                  value={email}
                  disabled
                  fullWidth
                  size="small"
                />
              </div>
              <div tw="mb-2">
                <StyledTextInput
                  label="Transfer To User Phone"
                  value={formData.requesterPhone}
                  onChange={(e) =>
                    setFormData({ ...formData, requesterPhone: e.target.value })
                  }
                  fullWidth
                  size="small"
                />
              </div>
              <div tw="mb-2">
                <Autocomplete
                  options={
                    isUsersLoading
                      ? []
                      : users?.map((user) => ({
                          id: user.id,
                          name: user.name,
                          email: user.email,
                        })) || []
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      transferFromUser: String(newValue?.id) || "",
                    });
                  }}
                  onInputChange={(event, value) => {
                    setUserSearchTerm(value);
                  }}
                  loading={isUsersLoading}
                  renderInput={(params) => (
                    <StyledTextInput
                      {...params}
                      label="Transfer From User (Select User)"
                      required
                      size="small"
                    />
                  )}
                />
                <p tw="text-sm text-gray-600 mt-1 italic">
                  This user will be CC'd on the transfer request email
                </p>
              </div>
              <div tw="mb-2">
                <StyledTextInput
                  label="Qty (UNITS) Requested to Transfer"
                  type="number"
                  value={formData.qtyRequested}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setFormData({ ...formData, qtyRequested: inputValue });
                  }}
                  onBlur={(e) => {
                    const inputValue = e.target.value;
                    if (!inputValue) {
                      setFormData({ ...formData, qtyRequested: "" });
                      return;
                    }

                    const packSize = parseInt(formData.packSize) || 1;
                    const roundedValue = roundUp(
                      parseInt(inputValue),
                      packSize
                    );
                    setFormData({
                      ...formData,
                      qtyRequested: roundedValue.toString(),
                    });
                  }}
                  required
                  fullWidth
                  size="small"
                />
                {formData.packSize && (
                  <p tw="text-sm text-gray-600 mt-1 italic">
                    Quantities will be rounded up to the nearest pack size of{" "}
                    {formData.packSize}
                  </p>
                )}
              </div>
              <div tw="mb-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.hasPermission}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          hasPermission: e.target.checked,
                        })
                      }
                    />
                  }
                  label="I have received permission from the person I am requesting the transfer from"
                />
              </div>
              <div tw="mb-2">
                <StyledTextInput
                  label="Special Notes"
                  multiline
                  rows={4}
                  value={formData.specialNotes}
                  onChange={(e) =>
                    setFormData({ ...formData, specialNotes: e.target.value })
                  }
                  fullWidth
                  size="small"
                />
              </div>
              <div tw="flex gap-4 justify-end">
                <StyledButton onClick={handleClose} outlined>
                  Cancel
                </StyledButton>
                <StyledButton type="submit" cta>
                  Submit Request
                </StyledButton>
              </div>
            </form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const TransferAllocationModalButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <StyledButton
        outlined
        startIcon={<CompareArrowsIcon />}
        onClick={() => setIsModalOpen(true)}
      >
        Transfer Request
      </StyledButton>

      <TransferAllocationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default TransferAllocationModalButton;
