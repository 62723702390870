/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useMemo } from "react";

import { useBeaconStatesReportQuery } from "@features/beacons";

import Chip from "../components/Chip";
import ControlledFilterAutocomplete from "../components/ControlledFilterAutocomplete";
import { useFilterContext } from "../filterContext";

const Popper = () => {
  const { control } = useFilterContext();
  const { data = [], isLoading } = useBeaconStatesReportQuery({});
  const mapped = useMemo(
    () =>
      data.map((state) => ({
        id: state.stateCode ?? "Unspecified",
        name: state.stateCode ?? "Unspecified",
      })),
    [data]
  );

  return (
    <ControlledFilterAutocomplete
      name="states"
      options={mapped}
      control={control}
      inputPlaceholder="Filter States"
      isLoading={isLoading}
    />
  );
};

const FilterChip = (props) => {
  // const { data, isLoading } = useBeaconStatesReportQuery({});
  const { filterValues } = useFilterContext();
  const states = filterValues["states"] ?? [];

  return (
    <Chip {...props} showCloseButton={states.length > 0}>
      {states.length > 0 && (
        <>
          <span tw="text-neutral-600">State is&nbsp;</span>
          <span tw="text-neutral-800 font-medium">
            {[...states].splice(0, 2).join(", ")}
            {states.length > 2 && <span>, + {states.length - 2}</span>}
          </span>
        </>
      )}
      {states.length === 0 && <span tw="text-neutral-600">Select State</span>}
    </Chip>
  );
};

export default {
  name: "beaconStates",
  formControls: ["states"],
  alwaysShow: true,
  Popper,
  Chip: FilterChip,
};
