/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledResourceFilterAutocomplete from "./ControlledResourceFilterAutocomplete";
import useSelectedResources from "./useSelectedResources";

const ProgramPopper = ({ requestParams }) => {
  const { control } = useFilterContext();

  return (
    <ControlledResourceFilterAutocomplete
      name="programs"
      resource="programs"
      control={control}
      inputPlaceholder="Filter Programs"
      requestParams={requestParams}
    />
  );
};

const ProgramFilterTile = (props) => {
  const { filterValues } = useFilterContext();
  const programIds = filterValues["programs"] ?? [];
  const { data: selectedPrograms } = useSelectedResources(
    "programs",
    programIds
  );

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">
        {programIds.length === 0 ? "Select Program" : <>Program is&nbsp;</>}
      </span>
      <span tw="text-neutral-800 font-medium">
        {selectedPrograms
          .slice(0, 1)
          .map((program) => program.name)
          .join(", ")}
        {programIds.length > 1 && <span>, + {programIds.length - 1}</span>}
      </span>
    </Chip>
  );
};

const ProgramFilter = {
  Tile: ProgramFilterTile,
  Popper: ProgramPopper,
};

export default ProgramFilter;
