/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { Paper } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable from "@components/Table/ReactQueryTable";
import { variantName } from "@features/items";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";

const OrderSetOrdersOverview = () => {
  const { orders } = useCurrentOrderSet();

  return (
    <OpaqueCard tw="p-0">
      <ReactQueryTable
        rows={orders ?? []}
        columns={[
          { id: "id", label: "Order #" },
          {
            id: "address.name",
            label: "Address",
          },
          // { id: "address.city", label: "City" },
          {
            id: "_stateRegion",
            label: "State",
            render: (__, order) =>
              order.address.state?.code ?? order.address.region,
          },
          {
            id: "totalPrice",
            label: "Price",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "totalEstimatedShippingCost",
            label: "Est. Freight",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "totalEstimatedTax",
            label: "Est. Tax",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "_extendedTotal",
            label: "Total",
            align: "right",
            render: (v, o) => (
              <span tw="font-medium">
                {formatMoneyString(
                  o.totalPrice +
                    o.totalEstimatedTax +
                    o.totalEstimatedShippingCost
                )}
              </span>
            ),
          },
        ]}
        collapsibleContent={(order) => {
          return (
            <Paper tw="rounded-lg overflow-hidden">
              <ReactQueryTable
                rows={order.orderVariants.filter((ov) => ov.qty > 0)}
                columns={[
                  {
                    id: "_id",
                    label: "Sku",
                    render: (_, v) =>
                      v.variant.externalWarehouseId ?? v.variant.variantSku,
                  },
                  {
                    id: "variant.item.name",
                    label: "Item",
                    render: (name, ov) =>
                      name +
                      (ov.variant.selectedVariantOptions.length
                        ? ` - ${variantName(ov.variant)}`
                        : ""),
                  },
                  {
                    id: "price",
                    label: "Price",
                    align: "right",
                    render: formatMoneyString,
                  },
                  {
                    id: "qty",
                    label: "Qty",
                    align: "right",
                  },
                  {
                    id: "totalPrice",
                    label: "Total",
                    align: "right",
                    render: (v) => (
                      <span tw="font-medium">{formatMoneyString(v)}</span>
                    ),
                  },
                ]}
              />
            </Paper>
          );
        }}
      />
    </OpaqueCard>
  );
};

export default OrderSetOrdersOverview;
