import { useMutation, useQueryClient } from "@tanstack/react-query";

import { budgetsKeyFactory } from "@features/budgets";
import client, { useMutateError } from "@services/api";

import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";

export default function useSetOrderSetBudgetMutation() {
  const setError = useMutateError();
  const orderSetId = useOrderSetId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (budgetId: number | string | null) => {
      return client
        .post(
          `order-sets/${orderSetId}/update-budget`,
          { "budget-id": budgetId && +budgetId },
          { serializeBody: false }
        )
        .then((data) => data.data);
    },
    onSuccess: (newOrderSet) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey,
        newOrderSet
      );

      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: budgetsKeyFactory._def,
      });
    },
    onError: (error) => {
      setError(error, "Set Order Update Budget");
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    },
  });
}
