/** @jsxImportSource @emotion/react */
import tw, { css } from "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  Cancel,
  HelpOutlineRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import _ from "lodash";
import { setItemPreview } from "src/redux/slices/items/itemSlice";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { getItemPreviewImage, variantName } from "@features/items";
import { Variant } from "@models/Variant";
import { CldImage } from "@services/cloudinary";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import useDeleteOrderSetVariant from "../data/mutations/useDeleteOrderSetVariant";
import OrderSetAddressSection from "./addressSelection/OrderSetAddressSection";

const subTableStyles = css({
  td: tw`first-of-type:(bg-white! left-0 sticky font-medium) first-of-type:text-left
      bg-neutral-100! text-center`,
});

const OrderSetHead = ({
  measuredRef,
}: {
  measuredRef: (node: HTMLTableSectionElement) => void;
}) => {
  const dispatch = useDispatch();
  const { orderSet, orderSetVariants, orders } = useCurrentOrderSet();
  const deleteOrderSetVariant = useDeleteOrderSetVariant();
  const [orderDetailsOpen, setOrderDetailsOpen] = useState(true);
  const { hash } = useLocation();

  const {
    id: userId,
    currentTerritoryId,
    organization: { orderLimitType, allocationLocation },
  } = useSelector((state: any) => state.currentUser);

  const determineAvailableToOrder = (variant: Variant) => {
    const globalQty = variant.globalAvailableToOrderQty ?? 0;
    // check if allocations are used;
    if (!allocationLocation || !orderLimitType) return globalQty;

    const filteredAllocations = variant.variantAllocations.filter(
      (a) =>
        (a.user && a.user.id === userId) ||
        (a.territory && a.territory.id === currentTerritoryId)
    );

    const allocationTotal = _.sumBy(filteredAllocations, "availableToOrderQty");
    switch (orderLimitType) {
      case "allocation-only":
        return allocationTotal;
      case "allocation-plus-global":
        return allocationTotal + globalQty;

      case "allocation-only-or-global":
        if (filteredAllocations.length > 0) return allocationTotal;
        return globalQty;
      default:
        return globalQty;
    }
  };

  const countByItemIds = _(orderSetVariants)
    .groupBy("variant.item.id")
    .mapValues((v) => _.sumBy(v, "totalQty"))
    .value();

  return (
    <>
      <TableHead ref={measuredRef} tw="sticky top-0 z-30">
        <TableRow>
          <TableCell
            className="tutorial-highlight"
            id="tutorial-address-selection"
            css={[
              tw`sticky left-0 p-6 font-normal align-top bg-white`,
              orders.length === 0 &&
                tw`border-4! rounded-md! border-primary-300! `,
            ]}
          >
            <OrderSetAddressSection />
          </TableCell>
          {orderSetVariants.map((osv, i) => (
            <TableCell tw="align-top sticky left-0" key={`head-cell-${i}`}>
              <div tw="absolute z-10 top-0 right-0 flex gap-2">
                <Tooltip title="Remove from Order">
                  <IconButton
                    disabled={deleteOrderSetVariant.isPending}
                    onClick={() => {
                      if (
                        window.confirm(
                          `You are attempting to remove Sku #${osv.variant.variantSku} from the current order, are you sure you wish to continue?`
                        )
                      ) {
                        deleteOrderSetVariant.mutate(osv.id, {
                          onError: console.error,
                        });
                      }
                    }}
                  >
                    <Cancel fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>

              <button
                tw="block w-full relative"
                onClick={() =>
                  dispatch(setItemPreview({ id: osv.variant.item.id }))
                }
              >
                <CldImage
                  tw="w-full object-contain object-center max-h-32"
                  publicId={getItemPreviewImage(osv.variant.item)?.cloudinaryId}
                  size="medium"
                />
              </button>
              <Typography tw="mt-2 font-medium text-center">
                {osv.variant.item.name}
              </Typography>

              {osv.variant.selectedVariantOptions.length > 0 && (
                <Typography tw="text-center text-primary-600 font-medium tracking-wide text-sm">
                  {variantName(osv.variant)}
                </Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell tw="sticky left-0 bg-white border-t border-neutral-200 border-b-0">
            <div tw="flex w-full justify-between items-center">
              <Typography tw="text-neutral-600">Order Details</Typography>
              <IconButton
                aria-label="expand row"
                onClick={() => {
                  setOrderDetailsOpen(!orderDetailsOpen);
                }}
                size="small"
              >
                {orderDetailsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </TableCell>
          {orderSetVariants.map((osv) => (
            <TableCell
              tw="border-t border-neutral-200 border-b-0 text-center"
              key={`sku-${osv.id}`}
            >
              <Typography variant="body2" tw="text-neutral-700">
                {osv.variant.externalWarehouseId ?? osv.variant.variantSku}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell colSpan={orderSetVariants.length + 1} tw="p-0">
            <Collapse in={orderDetailsOpen} timeout="auto">
              <Table
                size="small"
                css={subTableStyles}
                tw="border-separate border-t border-neutral-200"
              >
                <TableBody>
                  {orderSet.type === "inventory" && (
                    <TableRow>
                      <TableCell>On Hand Units</TableCell>
                      {orderSetVariants.map((osv) => (
                        <TableCell key={`on-hand-${osv.id}`}>
                          {determineAvailableToOrder(osv.variant)}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell>
                      Shipped In Packs Of{" "}
                      <Tooltip title="Please order in individuals units. Brandhub will round up to the nearest pack size.">
                        <HelpOutlineRounded tw="text-primary-600 text-xl" />
                      </Tooltip>
                    </TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={`pack-size-${osv.id}`}>
                        {osv.variant.item.packSize}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Individual Units</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={`moq-${osv.id}`}>
                        {osv.totalQty}
                        {orderSet.type === "on-demand" &&
                          osv.variant.item.minimumOrderQuantity > 0 && (
                            <span
                              tw="bg-white px-1 py-0.5 rounded text-sm ml-2"
                              css={[
                                // If hash is set, we can assume the user is trying to resolve MOQ
                                hash &&
                                  countByItemIds[osv.variant.item.id] <
                                    osv.variant.item.minimumOrderQuantity &&
                                  tw`text-red-900 bg-red-50`,
                              ]}
                            >
                              MOQ:{" "}
                              <span
                                css={[
                                  tw`font-medium`,
                                  countByItemIds[osv.variant.item.id] >=
                                    osv.variant.item.minimumOrderQuantity &&
                                    tw`text-green-700`,
                                ]}
                              >
                                {countByItemIds[osv.variant.item.id]}
                              </span>{" "}
                              of {osv.variant.item.minimumOrderQuantity}
                            </span>
                          )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Unit Price</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={`price-${osv.id}`}>
                        {formatMoneyString(
                          osv.price ??
                            +osv.variant.item.price + +osv.variant.upcharge
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Price</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={osv.id}>
                        {formatMoneyString(osv.totalPrice)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export default OrderSetHead;
