import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OrderSet } from "@models/OrderSet";
import client, { useMutateError } from "@services/api";
import asyncPool from "@utility/asyncPool";

import useCustomizationRequirements from "../../orderSetCheckout/useCustomizationRequirements";
import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";

export const useUpdateOrderVariantCustomizationMutation = () => {
  const setError = useMutateError();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const { customizableOrderSetVariants } = useCustomizationRequirements();

  return useMutation({
    mutationFn: async ({
      itemId,
      customization,
    }: {
      itemId: string;
      customization: string;
    }) => {
      const osvs = customizableOrderSetVariants[itemId];
      const res = await asyncPool(5, osvs, async (osv) =>
        client.update(`order-set-variants/${osv.id}`, {
          customization,
        })
      );
      if (res.errors) throw res.errors[0];
    },
    onSuccess: (_r, { itemId, customization }) => {
      return queryClient.setQueryData<OrderSet>(
        orderSetsKeyFactory.detail(orderSetId).queryKey,
        (old) =>
          old && {
            ...old,
            orderSetVariants: old.orderSetVariants.map((osv) => {
              if (osv.variant.item.id === itemId) {
                return {
                  ...osv,
                  customization,
                };
              } else return osv;
            }),
          }
      );
    },
    onError: (error) => {
      setError(error, "Item Customization");
    },
  });
};
