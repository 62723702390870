import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  selectedOrderSetIds: [],
};

const orderReviewSlice = createSlice({
  name: "orderReview",
  initialState,
  reducers: {
    approveOrDenySuccess(state) {
      state.selectedOrderSetIds = [];
    },
    setOrderSetSelection(state, action) {
      state.selectedOrderSetIds = action.payload;
    },
  },
});

export const { approveOrDenySuccess, setOrderSetSelection } =
  orderReviewSlice.actions;

export default orderReviewSlice.reducer;
