import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useCurrentOrderSet } from "./CurrentOrderSetContext";

export default function useCanEditOrderSet() {
  const { orderSet } = useCurrentOrderSet();
  const roleIs = useRoleIs();
  return (
    orderSet.status === "draft" ||
    (orderSet.status === "submitted" && roleIs(permissions.orderReview)) ||
    (orderSet.status === "approval-review" &&
      roleIs(permissions.reviewApprovals))
  );
}
