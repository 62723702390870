import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Order } from "@models/Order";
import client from "@services/api";
import asyncPool from "@utility/asyncPool";

import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";

export default function useDeleteAllOrdersMutation() {
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();

  const ordersKey = orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey;

  return useMutation({
    mutationFn: async () => {
      const orders: Order[] = queryClient.getQueryData(ordersKey) ?? [];

      const orderIds = orders.map((order) => order.id);

      // Optimistic update
      queryClient.setQueryData(ordersKey, () => []);

      const res = await asyncPool(5, orderIds, (orderId) =>
        client.remove(`orders/${orderId}`).then(() => ({ orderId }))
      );
      if (res.errors) throw res.errors[0];
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ordersKey,
      });
    },
  });
}
