import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUserBudgetsPaginated } from "@features/budgets";
import { useUserCostCentersQuery } from "@features/costCenters";
import { setError } from "@redux/slices/errorSlice";

import useCheckoutOptions from "./useCheckoutOptions";

const useDefaultBudgetOrCostCenter = ({ warn }) => {
  const dispatch = useDispatch();
  const {
    allowBudgetCheckout,
    allowStandardCheckout,
    allowStripeCheckout,
    budgetLocation,
  } = useCheckoutOptions();

  const { usesCostCenters } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const requiresBudget =
    allowBudgetCheckout && !allowStandardCheckout && !allowStripeCheckout;

  const { data: costCenters = [], isLoading: areCostCentersLoading } =
    useUserCostCentersQuery();

  const {
    budgets,
    isLoading: isBudgetsLoading,
    isFetching,
  } = useUserBudgetsPaginated();

  const [budgetId, setBudgetId] = useState<string | null>(null);
  const [costCenterId, setCostCenterId] = useState<string | null>(null);

  useEffect(() => {
    if (
      !allowBudgetCheckout ||
      !budgetLocation ||
      isBudgetsLoading ||
      isFetching
    )
      return;

    if (budgets[0]) setBudgetId(budgets[0].id);
    else if (["user", "territory"].includes(budgetLocation) && requiresBudget) {
      warn &&
        dispatch(
          setError({
            error:
              "You currently do not have any active budgets assigned to you, please contact your administrator.",
            source: "Order Create",
          })
        );
    }
  }, [
    allowBudgetCheckout,
    budgetLocation,
    budgets,
    dispatch,
    isBudgetsLoading,
    isFetching,
    requiresBudget,
    warn,
  ]);

  useEffect(() => {
    if (areCostCentersLoading) return;
    if (usesCostCenters) {
      if (costCenters[0]?.id) setCostCenterId(costCenters[0].id as string);
      else {
        warn &&
          dispatch(
            setError({
              error:
                "You currently do not have any Cost Centers assigned to you, please contact your administrator.",
              source: "Order Create",
            })
          );
      }
    }
  }, [areCostCentersLoading, usesCostCenters, costCenters, dispatch, warn]);
  return { budgetId, costCenterId };
};

export default useDefaultBudgetOrCostCenter;
