/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useParams } from "react-router-dom";

import { Tab, Tabs } from "@mui/material";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import {
  BeaconMap,
  BeaconPingsList,
  BeaconProgramsList,
  BeaconVariantsList,
} from "@features/beacons";
import Filters from "@features/filters";
import { Title } from "@features/ui";
import { useSetLocation } from "@services/reactRouterDom";

import FourOhFour from "./FourOhFour";

const Beacons = () => {
  const { view } = useParams();
  const setLocation = useSetLocation();

  if (view && !["items", "pings"].includes(view)) {
    return <FourOhFour />;
  }

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex items-center gap-6 flex-wrap">
        <Title backUrl="/">Beacons</Title>
        <Tabs
          value={view ?? ""}
          onChange={(_e, v) => setLocation({ pathname: `/beacons/${v}` })}
          tw="min-h-0"
          indicatorColor="secondary"
        >
          <Tab
            label="Programs"
            value={""}
            tw="py-2 min-h-0 hover:text-neutral-600 tracking-wider"
          />
          <Tab
            label="Items"
            value={"items"}
            tw="py-2 min-h-0 hover:text-neutral-600 tracking-wider"
          />
          <Tab
            label="Pings"
            value={"pings"}
            tw="py-2 min-h-0 hover:text-neutral-600 tracking-wider"
          />
        </Tabs>
      </div>
      <Filters
        searchTitle="Search Locations"
        slots={["beaconItems", "programs", "beaconStates", "poId"]}
        slotProps={{
          programs: { requestParams: { filter: { hasBeacons: true } } },
        }}
      />
      <div tw="grid grid-cols-2 gap-4 flex-1! overflow-hidden">
        {!view && <BeaconProgramsList />}
        {view === "items" && <BeaconVariantsList />}
        {view === "pings" && <BeaconPingsList />}
        <BeaconMap />
      </div>
    </FixedHeightScrollLastChild>
  );
};

export default Beacons;
