import { useState } from "react";
import { useDispatch } from "react-redux";

import { ButtonProps } from "@mui/material";

import { LoadingButton } from "@features/ui";

import { requestReport } from "./globalReportSlice";

type RequestReportButtonProps = ButtonProps & {
  reportName: string;
  title?: string;
};

const RequestReportButton = ({
  reportName,
  title = "Export Report",
  ...props
}: RequestReportButtonProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    dispatch(requestReport(reportName));
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  };

  return (
    <LoadingButton
      variant="contained"
      onClick={handleClick}
      loading={loading}
      {...props}
    >
      {title}
    </LoadingButton>
  );
};

export default RequestReportButton;
