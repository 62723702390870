/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FavoriteBorderRounded, FavoriteRounded } from "@mui/icons-material";
import { Checkbox, Radio, Skeleton, Tooltip } from "@mui/material";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { useGroupCategoriesListQuery } from "@features/groupCategories";
import { Group, Item } from "@models";
import { toggleFavoriteItem } from "@redux/slices/user/currentUserSlice";
import { upCase } from "@utility/utilityFunctions";

import ItemIdentifiers from "./ItemIdentifiers";
import ItemOptionsCount from "./ItemOptionsCount";
import ItemPreviewImageButton from "./ItemPreviewImageButton";

const ItemSelectionTable = ({
  rows,
  query,
  selectedItemId,
  setSelectedItemId,
  ...tableProps
}: PaginatedResourceProps & {
  selectedItemId: string | null;
  setSelectedItemId: Dispatch<SetStateAction<string | null>>;
  query?: string;
  rows: Item[];
}) => {
  const dispatch = useDispatch();
  const { favoriteItemIds } = useSelector((state: any) => state.currentUser);
  const { data: groupCategories, isLoading: categoriesLoading } =
    useGroupCategoriesListQuery();

  const columns: Column<Item>[] = [
    {
      id: "radio",
      label: "",
      render: (_, row) => (
        <Radio
          checked={selectedItemId === row.id}
          onClick={(e) => e.stopPropagation()}
          onChange={() => setSelectedItemId(row.id)}
        />
      ),
    },
    {
      id: "preview",
      label: "Preview",
      render: (_, row) => <ItemPreviewImageButton item={row} />,
    },

    {
      id: "item",
      label: "Item",
      sort: "item-number",
      render: (_, row) => (
        <div>
          <div tw="flex gap-2 items-center text-primary-600">
            <Tooltip title="Favorite Item">
              <Checkbox
                className="group"
                tw="-m-2"
                edge="start"
                checked={favoriteItemIds.includes(+row.id)}
                size="small"
                icon={
                  <FavoriteBorderRounded tw="text-lg text-neutral-400 group-hover:text-neutral-700" />
                }
                checkedIcon={<FavoriteRounded tw="text-lg text-primary-600" />}
                onClick={(e) => e.stopPropagation()}
                onChange={() => dispatch(toggleFavoriteItem(row.id))}
              />
            </Tooltip>
            <ItemIdentifiers item={row} query={query} />
          </div>
          <div tw="text-base! text-neutral-800 leading-tight mt-2">
            <SearchableText text={row.name} query={query} />
          </div>
          {row.variants.length > 1 && <ItemOptionsCount item={row} />}
        </div>
      ),
    },
    {
      id: "description",
      label: "Description",
      render: (t) => (
        <SearchableText text={t ?? "---"} query={query} maxLength={200} />
      ),
    },
    {
      id: "groups",
      label: "Categories",
      render: (groups: Group[]) =>
        categoriesLoading ? (
          <Skeleton />
        ) : (
          <>
            {groupCategories?.map((_cat, i) => {
              const cat = groups.find(
                (group) => group.groupCategory.id === _cat.id
              );
              return (
                cat && (
                  <Tooltip
                    key={i}
                    title={cat?.groupCategory.name ?? "Category"}
                  >
                    <div tw="text-sm text-gray-500 hover:text-gray-900">
                      {cat?.name ?? "---"}
                    </div>
                  </Tooltip>
                )
              );
            })}
          </>
        ),
    },
    {
      id: "orderType",
      label: "Order Type",
      render: (orderType) => upCase(orderType),
    },
  ];

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable
        {...tableProps}
        rows={rows}
        columns={columns}
        onRowClick={(row) => setSelectedItemId(row.id)}
        rowCss={(row) => [
          tw`cursor-pointer hover:bg-neutral-50`,
          selectedItemId === row.id && tw`bg-primary-50!`,
        ]}
      />
    </OpaqueCard>
  );
};

export default ItemSelectionTable;
