import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import format from "date-fns/format";

import { fetchCostCenters } from "@redux/slices/costCenterSlice";
import {
  resetFilters,
  setChips,
  setClearFilter,
  setSorted,
  updateMultipleFilters,
  updateSingleFilter,
} from "@redux/slices/filterSlice";
import { fetchHistoryReport } from "@redux/slices/orders/orderHistorySlice";
import { fetchPromotions } from "@redux/slices/promotions/promotionSlice";
import { clearReports, fetchReport } from "@redux/slices/reports/reportSlice";

import { useDetailedInput } from "../../hooks/inputs/useDetailedInput";
import { useDetailedNumberOnlyInput } from "../../hooks/inputs/useDetailedNumberOnlyInput";
import FiltersBudgetReports from "../Reporting/FiltersBudgetReports";
import FiltersInventoryReports from "../Reporting/FiltersInventoryReports";
import FiltersItemReports from "../Reporting/FiltersItemReports";
import FiltersOrderReports from "../Reporting/FiltersOrderReports";
import FiltersOrderWindowReports from "../Reporting/FiltersOrderWindowReports";
import FiltersPromotionReports from "../Reporting/FiltersPromotionReports";
import FiltersBeaconReport from "./FiltersBeaconReport";
import FiltersCostCenters from "./FiltersCostCenters";
import FiltersOrderHistory from "./FiltersOrderHistory";
import FiltersPromotions from "./FiltersPromotions";

const FilterDrawer = () => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters);
  const currentTerritoryId = useSelector(
    (state) => state.currentUser.currentTerritory
  );
  const { categories } = useSelector((state) => state.groupCategories);

  const [reset, setReset] = useState(false);

  const validFilters = [
    "budgetGroupIds",
    "budget",
    "budgetIsActive",
    "channelId",
    "channelIds",
    "dateRangeStart",
    "dateRangeEnd",
    "favoriteItems",
    "groupIds",
    "groupedBy",
    "groupOrderHistoryBy",
    "ignoreUserTerritory",
    "invoiceNumber",
    "isActive",
    "isOrderable",
    "isVisible",
    "name",
    "orderId",
    "orderType",
    "orderWindowId",
    "orderWindowIds",
    "poId",
    "promotionIds",
    "promotionRedemptionType",
    "promotionStatus",
    "promotionType",
    "programId",
    "programIds",
    "purchaserIds",
    "sku",
    "search",
    "status",
    "stateIds",
    "status",
    "supplierIds",
    "supplierReference",
    "territoryIds",
    "userIds",
    "inStock",
    "itemVisibilityCase",
    "dateRange",
  ];

  const freeTypeFilters = [
    "invoiceNumber",
    "name",
    "orderId",
    "poId",
    "sku",
    "search",
    "supplierReference",
  ];

  const handleFilters = useCallback(
    (value, filter, type) => {
      let currentFilters = { ...filters };
      if (validFilters.includes(filter)) {
        let trimmedValue = freeTypeFilters.includes(filter)
          ? value.trim()
          : value;
        dispatch(updateSingleFilter({ filter: filter, value: trimmedValue }));
        currentFilters[filter] = trimmedValue;
        if (!freeTypeFilters.includes(filter)) {
          dispatch(setChips({ filterType: type }));
        }
      } else if (filter === "fromDate" || filter === "toDate") {
        dispatch(
          updateSingleFilter({
            filter: filter,
            value: format(value, "MM/dd/yyyy"),
          })
        );
        currentFilters[filter] = format(value, "MM/dd/yyyy");
      }
      if (!freeTypeFilters.includes(filter)) {
        if (filters.filterType === "cost-centers") {
          dispatch(fetchCostCenters(currentFilters));
          // dispatch(setBudgetsCSVFalse());
          // dispatch(resetBudgetReport());
        }
        if (filters.filterType === "promotion") {
          dispatch(fetchPromotions(currentFilters));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, filters]
  );

  const {
    value: sku,
    bind: bindSku,
    reset: resetSku,
  } = useDetailedInput("", handleFilters, "sku", filters.filterType);
  const { bind: bindSearch, reset: resetSearch } = useDetailedInput(
    "",
    handleFilters,
    "search",
    filters.filterType
  );
  const {
    value: name,
    bind: bindName,
    reset: resetName,
  } = useDetailedInput("", handleFilters, "name", filters.filterType);

  const {
    value: orderId,
    bind: bindOrderId,
    reset: resetOrderId,
  } = useDetailedNumberOnlyInput(
    "",
    handleFilters,
    "orderId",
    filters.filterType
  );

  const resetAllFilters = useCallback(() => {
    setReset(true);
    resetSku();
    resetSearch();
    resetName();
    resetOrderId();
    dispatch(resetFilters());
    if (filters.defaultFilters) {
      dispatch(updateMultipleFilters({ filterObject: filters.defaultFilters }));
      if (filters.filterType === "cost-centers") {
        dispatch(fetchCostCenters(filters.defaultFilters, filters.budgetType));
      }
      if (filters.filterType === "promotion") {
        dispatch(fetchPromotions(filters.defaultFilters));
      }
      if (filters.filterType.includes("reports-")) {
        dispatch(clearReports());
      }
      dispatch(setChips({ filterType: filters.filterType }));
    }
  }, [
    dispatch,
    resetSku,
    resetSearch,
    resetName,
    resetOrderId,
    setReset,
    filters,
  ]);

  const handleFilteredOrderHistoryFetch = () => {
    dispatch(setChips({ filterType: filters.filterType }));
    dispatch(fetchHistoryReport(filters));
  };
  const handleFilteredCostCenterFetch = () => {
    dispatch(setChips({ filterType: filters.filterType }));
    dispatch(fetchCostCenters(filters, filters.budgetType));
    // dispatch(setBudgetsCSVFalse());
    // dispatch(resetBudgetReport());
  };

  const handleFilteredPromotionsFetch = () => {
    dispatch(setChips({ filterType: filters.filterType }));
    dispatch(fetchPromotions(filters));
  };

  const handleReportFetch = () => {
    dispatch(setChips({ filterType: filters.filterType }));
    dispatch(fetchReport(filters, categories));
  };

  useEffect(() => {
    if (filters.clearFilters) {
      resetAllFilters();
    }
  }, [filters, resetAllFilters, dispatch]);

  useEffect(() => {
    if (filters.sorted) {
      if (filters.filterType === "cost-centers") {
        dispatch(fetchCostCenters(filters.defaultFilters, filters.budgetType));
      }
      if (filters.filterType === "promotion") {
        dispatch(fetchPromotions(filters));
      }
      if (filters.sorted) {
        dispatch(setSorted({ sorted: false }));
        dispatch(setChips({ filterType: filters.filterType }));
      }
    }
  }, [filters, dispatch, currentTerritoryId]);

  useEffect(() => {
    if (filters.clearSku) {
      resetSku();
      dispatch(setClearFilter({ key: "sku", value: false }));
    }
  }, [filters, resetSku, dispatch]);

  useEffect(() => {
    if (filters.clearSearch) {
      resetSearch();
      dispatch(setClearFilter({ key: "search", value: false }));
    }
  }, [filters, resetSearch, dispatch]);

  useEffect(() => {
    if (filters.clearName) {
      resetName();
      dispatch(setClearFilter({ key: "name", value: false }));
    }
  }, [filters, resetName, dispatch]);

  useEffect(() => {
    if (filters.clearOrderId) {
      resetOrderId();
      dispatch(setClearFilter({ key: "orderId", value: false }));
    }
  }, [filters, resetOrderId, dispatch]);

  return (
    <>
      {filters?.filterType === "cost-centers" && (
        <FiltersCostCenters
          name={name}
          bindName={bindName}
          handleSearch={handleFilteredCostCenterFetch}
        />
      )}
      {filters.filterType && filters.filterType === "promotion" && (
        <FiltersPromotions
          reset={reset}
          setReset={setReset}
          handleFilters={handleFilters}
          sku={sku}
          bindSku={bindSku}
          handleSearch={handleFilteredPromotionsFetch}
        />
      )}
      {filters.filterType &&
        (filters.filterType.includes("reports-order-window") ||
          filters.filterType === "reports-pre-order-user-report") && (
          <FiltersOrderWindowReports
            reset={reset}
            setReset={setReset}
            handleFilters={handleFilters}
            handleSearch={handleReportFetch}
            filterType={filters.filterType}
          />
        )}
      {filters.filterType === "reports-beacon-location-report" && (
        <FiltersBeaconReport
          reset={reset}
          setReset={setReset}
          handleFilters={handleFilters}
          handleSearch={handleReportFetch}
        />
      )}
      {filters.filterType && filters.filterType.includes("reports-budget") && (
        <FiltersBudgetReports
          reset={reset}
          setReset={setReset}
          handleFilters={handleFilters}
          name={name}
          bindName={bindName}
          handleSearch={handleReportFetch}
          filterType={filters.filterType}
        />
      )}
      {filters.filterType &&
        (filters.filterType.includes("reports-inventory") ||
          filters.filterType === "reports-variant-allocation-report" ||
          filters.filterType === "reports-allocation-snapshot-report") && (
          <FiltersInventoryReports
            reset={reset}
            setReset={setReset}
            handleFilters={handleFilters}
            bindSearch={bindSearch}
            handleSearch={handleReportFetch}
            filterType={filters.filterType}
          />
        )}
      {filters.filterType && filters.filterType.includes("reports-promo") && (
        <FiltersPromotionReports
          reset={reset}
          setReset={setReset}
          handleFilters={handleFilters}
          handleSearch={handleReportFetch}
          filterType={filters.filterType}
        />
      )}
      {filters.filterType &&
        filters.filterType === "reports-shipped-orders-report" && (
          <FiltersOrderReports
            reset={reset}
            setReset={setReset}
            handleFilters={handleFilters}
            handleSearch={handleReportFetch}
            filterType={filters.filterType}
          />
        )}
      {filters.filterType &&
        filters.filterType === "reports-product-list-report" && (
          <FiltersItemReports
            reset={reset}
            setReset={setReset}
            handleFilters={handleFilters}
            handleSearch={handleReportFetch}
            filterType={filters.filterType}
          />
        )}
      {filters.filterType === "reports-franchise-order-history-report" && (
        <FiltersOrderHistory
          reset={reset}
          setReset={setReset}
          handleFilters={handleFilters}
          sku={sku}
          bindSku={bindSku}
          orderId={orderId}
          bindOrderId={bindOrderId}
          handleSearch={handleFilteredOrderHistoryFetch}
        />
      )}
    </>
  );
};

export default FilterDrawer;
