/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { pdf } from "@react-pdf/renderer";
import clsx from "clsx";
import { saveAs } from "file-saver";

import CancelModal from "@components/OrderHistory/CancelModal";
import EditOrderAddressModal from "@components/OrderHistory/EditOrderAddressModal";
import EditOrderVariantModal from "@components/OrderHistory/EditOrderVariantModal";
import OrderInvoicePDF from "@components/OrderHistory/OrderInvoicePDF";
import OrderMaintenanceMenu from "@components/OrderHistory/OrderMaintenanceMenu";
import OrderVariantTable from "@components/OrderHistory/OrderVariantTable";
import {
  Contained,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import OrderPatchLoading from "@components/Utility/OrderPatchLoading";
import { usePaginatedOrderVariantsQuery } from "@features/orderVariants";
import { SingleOrderFreightAndTax } from "@features/orders";
import {
  cancelOrder,
  cancelOrderVariant,
  fetchOrder,
} from "@redux/slices/orders/orderHistorySlice";
import DocTitle from "@utility/DocTitle";
import { formatMoney, formatUtcDate, upCase } from "@utility/utilityFunctions";

import { useNoFetch } from "../hooks/useNoFetch";

/*
This page displays a single order, and allows for updating if the order hasn't shipped yet.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  detailSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  detailGrid: {
    width: "100%",
  },
  detailPaper: {
    boxSizing: "border-box",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: `2px solid ${theme.palette.secondary.light}`,
    height: "100%",
  },
}));

const OrderHistoryOrder = () => {
  const { orderId } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [cancelModal, setCancelModal] = useState({
    open: false,
    cancelObject: null,
    type: null,
  });
  const [editModal, setEditModal] = useState({
    open: false,
    editObject: null,
    type: null,
  });

  const orgUsesBudgets = useSelector(
    (state) => state.currentUser.organization.budgetLocation !== null
  );

  let { currentOrder, isLoading } = useSelector((state) => state.orderHistory);

  const { data, ...tableProps } = usePaginatedOrderVariantsQuery({
    filter: {
      orderId: orderId,
    },
  });
  const orderVariantData = data ?? [];

  const {
    role,
    organization: {
      includeShippingInBudgets,
      includeTaxesInBudgets,
      includeShippingInStripeCharge,
      includeTaxesInStripeCharge,
    },
  } = useSelector((state) => state.currentUser);

  const handleCancelOpen = (type, cancelObject) => {
    setCancelModal({
      open: true,
      cancelObject: type === "variant" ? cancelObject : currentOrder,
      type: type,
    });
  };

  const handleEditOpen = (type, editObject) => {
    setEditModal({
      open: true,
      editObject: type === "variant" ? editObject : currentOrder,
      type: type,
    });
  };

  const handleModalClose = (type, url = null) => {
    if (type === "cancel") {
      setCancelModal({ open: false, cancelObject: null, type: null });
    } else setEditModal({ open: false, editObject: null, type: null });
    if (url) navigate(url);
  };

  const handleCancel = (id, note, type) => {
    if (type === "order") {
      dispatch(cancelOrder(id, note));
    } else dispatch(cancelOrderVariant(id, note));
    handleModalClose("cancel");
  };

  const handleEstTax = () => {
    let tax = 0;
    if (
      (currentOrder.checkoutType === "budget" && includeTaxesInBudgets) ||
      (currentOrder.checkoutType === "stripe" && includeTaxesInStripeCharge)
    ) {
      tax = currentOrder.actTax ?? currentOrder.estTax;
    }

    return formatMoney(tax, false);
  };

  const handleEstFreight = () => {
    let freight = 0;
    if (currentOrder.checkoutType === "budget") {
      freight = includeShippingInBudgets ? currentOrder.estFreight : 0;
    } else if (currentOrder.checkoutType === "stripe") {
      freight = includeShippingInStripeCharge ? currentOrder.estFreight : 0;
    } else {
      freight = currentOrder.estFreight;
    }

    return formatMoney(freight, false);
  };

  const handleActFreight = () => {
    let freight = 0;
    if (currentOrder.checkoutType === "budget") {
      freight = includeShippingInBudgets ? currentOrder.actFreight : 0;
    } else if (currentOrder.checkoutType === "stripe") {
      freight = includeShippingInStripeCharge ? currentOrder.actFreight : 0;
    } else {
      freight = currentOrder.actFreight;
    }

    return formatMoney(freight, false);
  };

  const handleGrandTotal = () => {
    if (!currentOrder) return "---";
    let freight = 0;
    let tax = 0;
    let total;

    switch (currentOrder.checkoutType) {
      case "budget":
        if (includeShippingInBudgets) {
          freight = currentOrder.actFreight || currentOrder.estFreight;
        }
        tax = includeTaxesInBudgets
          ? (currentOrder.actTax ?? currentOrder.estTax)
          : 0;

        total = currentOrder.totalPrice + freight + tax;
        break;
      case "stripe":
        if (includeShippingInStripeCharge) {
          freight = currentOrder.actFreight || currentOrder.estFreight;
        }

        tax = includeTaxesInStripeCharge
          ? (currentOrder.actTax ?? currentOrder.estTax)
          : 0;

        total = currentOrder.totalPrice + freight + tax;
        break;
      default:
        freight =
          currentOrder.actFreight !== 0
            ? currentOrder.actFreight
            : currentOrder.estFreight;
        total = currentOrder.totalPrice + freight + currentOrder.estTax;
    }

    return formatMoney(total, false);
  };

  const handleExportPDF = async () => {
    const blob = await pdf(
      OrderInvoicePDF({
        order: currentOrder,
        totals: {
          estFreight: handleEstFreight(),
          estTax: handleEstTax(),
          actFreight: handleActFreight(),
          grandTotal: handleGrandTotal(),
        },
      })
    ).toBlob();

    saveAs(blob, `order-${currentOrder.id}.pdf`);
  };

  useEffect(() => {
    dispatch(fetchOrder(orderId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && currentOrder && currentOrder.id !== orderId) {
      dispatch(fetchOrder(orderId));
    }
  }, [isLoading, currentOrder, orderId, dispatch, orgUsesBudgets]);

  useNoFetch(dispatch);

  if (
    !currentOrder ||
    isLoading ||
    (currentOrder && currentOrder.id !== orderId)
  ) {
    return <Loading />;
  }

  return (
    <>
      {cancelModal.open && (
        <CancelModal
          {...cancelModal}
          handleClose={handleModalClose}
          handleCancel={handleCancel}
        />
      )}
      {editModal.open && editModal.type === "variant" && (
        <EditOrderVariantModal
          {...editModal}
          order={currentOrder}
          handleClose={handleModalClose}
        />
      )}
      {editModal.open && editModal.type === "order" && (
        <EditOrderAddressModal {...editModal} handleClose={handleModalClose} />
      )}
      <DocTitle title={`Order ${orderId}`} />
      <Contained>
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>
            {`Order ${orderId}`}
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <StyledButton
                cta
                startIcon={
                  <ExitToApp style={{ transform: "rotate(180deg)" }} />
                }
                component={Link}
                to={"/orders/history" + (location.state?.query ?? "")}
              >
                ORDER HISTORY
              </StyledButton>
              <StyledButton
                outlined
                startIcon={<PictureAsPdfRoundedIcon />}
                onClick={handleExportPDF}
              >
                Export Summary PDF
              </StyledButton>
              {["admin", "super"].includes(role) && (
                <OrderMaintenanceMenu
                  currentOrder={currentOrder}
                  handleCancelOpen={handleCancelOpen}
                  handleEditOpen={handleEditOpen}
                />
              )}
            </div>
          </div>
        </header>
        <br />
        <section>
          <Grid
            spacing={3}
            container
            direction="row"
            justifycontent="center"
            alignitems="stretch"
          >
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <OpaqueCard tw="space-y-2 text-neutral-600 px-4 py-6 h-full">
                <Typography
                  className={clsx(classes.settingsMargin, classes.headerText)}
                >
                  Details:
                </Typography>
                <Typography>
                  {`Order Type: ${upCase(currentOrder.type, "-")}`}
                </Typography>
                <Typography>{`Ordered By: ${currentOrder.user} (${currentOrder.userEmail})`}</Typography>
                {currentOrder.submittedBy &&
                  currentOrder.submittedBy !== currentOrder.user && (
                    <Typography>
                      {`Submitted By: ${currentOrder.submittedBy}`}
                    </Typography>
                  )}
                <Typography>
                  {`Status: ${upCase(currentOrder.status, " ")}`}
                </Typography>
                <Typography>{`Program: ${currentOrder.program}`}</Typography>
                {currentOrder.budget && (
                  <Typography>{`Budget: ${currentOrder.budget}`}</Typography>
                )}
                <Typography>
                  {`Territory: ${currentOrder.territory}`}
                </Typography>
                <Typography>
                  {`Order Date: ${currentOrder.submittedAt}`}
                </Typography>
                <Typography>
                  {`Approval Date: ${currentOrder.approvedAt}`}
                </Typography>
                {currentOrder.isExpedited && (
                  <Typography>
                    {`Requested Delivery Date: ${formatUtcDate(
                      currentOrder.expeditedDate
                    )}`}
                  </Typography>
                )}
              </OpaqueCard>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <OpaqueCard tw="space-y-2 text-neutral-600 px-4 py-6 h-full">
                <Typography
                  className={clsx(classes.settingsMargin, classes.headerText)}
                >
                  Address:
                </Typography>
                <Typography>{`Name: ${currentOrder.address.name}`}</Typography>
                <Typography>
                  {`Attention: ${currentOrder.attn ?? "---"}`}
                </Typography>
                <Typography>
                  {`Street One: ${currentOrder.address.streetOne}`}
                </Typography>
                <Typography>
                  {`Street Two: ${currentOrder.address.streetTwo ?? "---"}`}
                </Typography>
                <Typography>{`City: ${currentOrder.address.city}`}</Typography>
                <Typography>
                  {`State / Region: ${currentOrder.address.stateOrRegion}`}
                </Typography>
                <Typography>
                  {`Zip Code: ${currentOrder.address.zip}`}
                </Typography>
                <Typography>
                  {`Country: ${currentOrder.address.country}`}
                </Typography>
                <Typography>{`Phone: ${
                  currentOrder.address.phoneNumber ?? "---"
                }`}</Typography>
              </OpaqueCard>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <OpaqueCard tw="space-y-2 text-neutral-600 px-4 py-6 h-full">
                <Typography>{`Total Qty: ${currentOrder.count}`}</Typography>
                <Typography>
                  {`Total Price: ${formatMoney(
                    currentOrder.totalPrice + currentOrder.discount,
                    false
                  )}`}
                </Typography>
                {currentOrder.discount > 0 && (
                  <Typography>
                    {`Promotion Discount: ${formatMoney(
                      currentOrder.discount,
                      false
                    )}`}
                  </Typography>
                )}

                <SingleOrderFreightAndTax order={currentOrder} />
                <Divider className={classes.fullWidth} />
                <br />
                <Typography
                  className={clsx(classes.settingsMargin, classes.headerText)}
                >
                  {`Grand Total: ${handleGrandTotal()}`}
                </Typography>
              </OpaqueCard>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <OpaqueCard>
                <Typography
                  className={clsx(classes.settingsMargin, classes.headerText)}
                >
                  Notes:
                </Typography>
                {currentOrder.notes !== "---" && (
                  <Typography
                    className={clsx(classes.settingsMargin, classes.bodyText)}
                  >
                    {`${currentOrder.notes}`}
                  </Typography>
                )}
                {currentOrder.status === "canceled" &&
                  currentOrder.cancelationNote && (
                    <Typography
                      className={clsx(classes.settingsMargin, classes.bodyText)}
                    >
                      {`${currentOrder.cancelationNote}`}
                    </Typography>
                  )}
              </OpaqueCard>
            </Grid>
          </Grid>
          <br />
          <OpaqueCard tw="p-0 overflow-hidden mb-3">
            <OrderVariantTable
              orderVariantData={orderVariantData}
              tableProps={tableProps}
              handleEditOpen={handleEditOpen}
              handleCancelOpen={handleCancelOpen}
            />
          </OpaqueCard>
        </section>
        <OrderPatchLoading />
      </Contained>
    </>
  );
};

OrderHistoryOrder.propTypes = {};

export default OrderHistoryOrder;
