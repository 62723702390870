/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";

import { addDays, addMonths, getHours, isBefore, isSameDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { useCanEditOrder } from "@features/orders";
import { FaIcon } from "@features/ui";
import { Order } from "@models/Order";
import { formatUtcDate, utcDate } from "@utility/utilityFunctions";

import { useUpdateOrder } from "../data";

const DeliverySelectionDropdown = ({ order }: { order: Order }) => {
  const updateOrderMutation = useUpdateOrder();
  const isTitos = useSelector(
    (state: any) => state.currentUser.organization.name === "Tito's"
  );
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const now = new Date(); // Convert the current time to EST
  const est = utcToZonedTime(now, "America/New_York");
  const defaultExpeditedDeliveryDate = addDays(now, 2);

  const canEdit = useCanEditOrder(order);

  const standardDeliveryText = isTitos
    ? "Ground Delivery"
    : "5-7 business days";

  const shouldDisableDate = (date: Date) => {
    if (isBefore(date, now)) {
      return true;
    }
    // // Next day should be disabled if it's past 2pm EST
    const isTomorrow = isSameDay(addDays(now, 1), date);
    if (isTomorrow) {
      return getHours(est) >= 14;
    }
    // Weekends
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (
      (!deliveryDate && !order.expeditedDate) || // both are null
      (deliveryDate &&
        ((order.expeditedDate &&
          isSameDay(deliveryDate, utcDate(order.expeditedDate))) || //both are the same date
          shouldDisableDate(deliveryDate))) // selected date is disabled
    ) {
      // ignore change
      setDeliveryDate(
        order.expeditedDate ? utcDate(order.expeditedDate) : null
      );
      return;
    }
    updateOrderMutation.mutate({
      id: order.id,
      expeditedDate: deliveryDate,
      isExpedited: !!deliveryDate,
    });
  };

  useEffect(() => {
    setDeliveryDate(order.expeditedDate ? utcDate(order.expeditedDate) : null);
  }, [order]);

  return (
    <div>
      <Button
        variant="text"
        tw="text-left px-4! -mx-4 -my-1"
        endIcon={<FaIcon icon="chevron-down" tw="text-sm! ml-3" />}
        size="small"
        onClick={(e) => setAnchorEl(e.target as any)}
        disabled={!canEdit}
      >
        {deliveryDate ? (
          <ListItemText
            primary={`RAD (${formatUtcDate(deliveryDate, "PP")})`}
            secondary={
              order.status !== "approved" && shouldDisableDate(deliveryDate) ? (
                <span tw="text-red-700">Not available</span>
              ) : (
                "Requested Arrival Date"
              )
            }
          />
        ) : (
          <ListItemText
            primary="Standard Delivery"
            secondary={standardDeliveryText}
          />
        )}
      </Button>
      {anchorEl && (
        <Menu open anchorEl={anchorEl} onClose={handleClose}>
          <ListItemButton
            onClick={() => setDeliveryDate(null)}
            selected={!deliveryDate}
          >
            <ListItemIcon>
              {deliveryDate && <RadioButtonUnchecked />}
              {!deliveryDate && <CheckCircle tw="text-primary-600" />}
            </ListItemIcon>
            <ListItemText
              primary="Standard Delivery"
              secondary={standardDeliveryText}
            />
          </ListItemButton>
          <Box
            component={deliveryDate ? ListItem : ListItemButton}
            tw="flex-col items-stretch"
            onClick={() =>
              !deliveryDate &&
              setDeliveryDate(deliveryDate || defaultExpeditedDeliveryDate)
            }
          >
            <div tw="flex items-center">
              <ListItemIcon>
                {!deliveryDate && <RadioButtonUnchecked />}
                {deliveryDate && <CheckCircle tw="text-primary-600" />}
              </ListItemIcon>
              <ListItemText primary="RAD" secondary="Requested Arrival Date" />
            </div>
            {deliveryDate && (
              <>
                <div tw="bg-primary-50 text-primary-900 text-sm rounded-lg p-2 -mx-2">
                  Order before 2pm EST for next day delivery.
                </div>
                <div tw="text-neutral-600 text-sm mt-2">
                  Select a delivery date.
                </div>
                <DateCalendar
                  tw="bg-white rounded-lg"
                  value={deliveryDate}
                  onChange={(v) => setDeliveryDate(v)}
                  disablePast
                  minDate={now}
                  maxDate={addMonths(now, 3)}
                  shouldDisableDate={shouldDisableDate}
                />
              </>
            )}
          </Box>
        </Menu>
      )}
    </div>
  );
};

export default DeliverySelectionDropdown;
