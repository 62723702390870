/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "@components/StyledComponents";
import WithPermission, {
  permissions,
} from "@components/Utility/WithPermission";
import { Filters, useFilterParams } from "@features/filters";
import {
  ItemCatalogView,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";
import {
  AddToCartButton,
  VariantSelectionModal,
  useCreateOrderSetVariantsMutation,
  useDraftOrderSetQuery,
  useDraftOrderSetsQuery,
} from "@features/ordering";
import { CreateInventoryPurchaseOrderModal } from "@features/purchaseOrders";
import { Item } from "@models";
import { useMutateError } from "@services/api";
import DocTitle from "@utility/DocTitle";

import FourOhFour from "./FourOhFour";

const when = (condition, value) => (condition ? [value] : []);

const useFilteredItems = (orderType: string, filters: Record<string, any>) => {
  const { currentTerritoryId, currentChannelId } = useSelector(
    (state: any) => state.currentUser
  );
  return usePaginatedItemsQuery({
    filter: {
      isActive: true,
      isOrderable: true,
      orderType,
      territoryIds: [currentTerritoryId],
      channelIds: currentChannelId && [currentChannelId],
      query: filters.q,
      favorites: filters.favorites,
      programIds: filters.programs,
      groupIds: filters.groups && Object.values(filters.groups).flat(),
      hasInventory: filters.inStock,
    },
  });
};

const PlaceOrder = ({
  orderType,
}: {
  orderType: "inventory" | "on-demand";
}) => {
  const [filterParams] = useFilterParams();
  const productView: "table" | "grid" = useSelector(
    (state: any) => state.globalState.productView
  );

  const setError = useMutateError();

  const orderSetIdRef = useRef<string | null>(null);

  const [inventoryPOItemId, setInventoryPOItemId] = useState<null | string>(
    null
  );

  const [variantSelectionItem, setVariantSelectionItem] = useState<null | Item>(
    null
  );
  const createOrderSetVariantsMutation = useCreateOrderSetVariantsMutation();
  const { isLoading: isLoadingOrderSets } = useDraftOrderSetsQuery();
  const { data: orderSet } = useDraftOrderSetQuery(orderType);
  orderSetIdRef.current = orderSet?.id ?? null;

  const orderSetVariants = orderSet?.orderSetVariants ?? [];

  // Initialize a promise queue
  const mutationQueue = useRef<Promise<any>>(Promise.resolve());

  const addVariantsToOrderSet = async (variantIds: string[]) => {
    return new Promise<void>((resolve) => {
      // chain the promise so that the mutation is only called once at a time
      mutationQueue.current = mutationQueue.current.then(() => {
        return (
          createOrderSetVariantsMutation
            .mutateAsync({
              // always use the latest order-set id
              orderSetId: orderSetIdRef.current,
              variantIds,
              orderType,
            })
            .catch((err) => setError(err, "Add Order Set Variants"))
            // resolve the parent promise when the mutation is done
            .finally(() => resolve())
        );
      });
    });
  };
  const addItemToOrderSet = async (item: Item) => {
    const defaultVariant = item.variants!.find(
      (v) => v.selectedVariantOptions.length === 0
    );

    if (defaultVariant?.isActive) {
      await addVariantsToOrderSet([defaultVariant.id]);
    } else {
      setVariantSelectionItem(item);
    }
  };

  const { data: items, ...tableProps } = useFilteredItems(
    orderType,
    filterParams
  );

  // const addToOrderSet = async (item: Item) => {
  //   const defaultVariant = item.variants.find((v) => v.isDefault);

  //   if (defaultVariant?.isActive) {
  //     await createOrderSetVariants([defaultVariant.id]);
  //   } else {
  //     setVariantSelectionItem(item);
  //   }
  // };

  if (!["inventory", "on-demand"].includes(orderType)) {
    return <FourOhFour />;
  }

  return (
    <>
      {inventoryPOItemId && (
        <CreateInventoryPurchaseOrderModal
          itemId={inventoryPOItemId}
          handleClose={() => setInventoryPOItemId(null)}
        />
      )}
      {variantSelectionItem && (
        <VariantSelectionModal
          item={variantSelectionItem}
          onClose={() => setVariantSelectionItem(null)}
          {...{
            orderType,
            orderSetVariants,
            createOrUpdateOrderSet: addVariantsToOrderSet,
          }}
        />
      )}
      <DocTitle
        title={`${orderType === "inventory" ? "Inventory" : "On-Demand"} Order`}
      />
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">
            {orderType === "inventory"
              ? "Place an Inventory Order"
              : "Place an On-Demand Order"}
          </h2>
          <div tw="flex gap-2">
            <StyledButton
              cta
              disabled={!orderSet}
              loading={isLoadingOrderSets}
              to={`/order-sets/${orderSet?.id}`}
            >
              VIEW ORDER
            </StyledButton>
          </div>
        </div>
        <div tw="flex gap-2 items-start justify-between">
          <Filters
            searchTitle="Search Items"
            slots={[
              "favorites",
              ...when(orderType === "inventory", "inStock"),
              "groups",
              "programs",
            ]}
            {...(orderType === "inventory" && {
              defaultValues: { inStock: true },
            })}
          />
          <ItemViewButtons />
        </div>
        <ItemCatalogView
          rows={items}
          query={filterParams.q}
          rowActions={(item) => (
            <div
              css={[
                tw`flex items-center gap-2`,
                productView === "table" && tw`flex-row-reverse`,
              ]}
            >
              {orderType === "inventory" && (
                <WithPermission allow={permissions.createPurchaseOrder}>
                  <Tooltip title="Restock Inventory">
                    <IconButton
                      size="small"
                      edge="end"
                      css={[
                        tw`text-primary-600 bg-primary-50! hover:(bg-primary-100/50 text-primary-800)`,
                        productView === "grid" &&
                          tw`transition-opacity opacity-0 group-hover:opacity-100`,
                      ]}
                      onClick={() => setInventoryPOItemId(item.id)}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </WithPermission>
              )}

              <AddToCartButton
                item={item}
                orderSetVariants={orderSetVariants}
                addToOrderSet={addItemToOrderSet}
              />
            </div>
          )}
          {...tableProps}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PlaceOrder;
