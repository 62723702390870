/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ExitToAppTwoTone } from "@mui/icons-material";
import { Alert } from "@mui/material";

import { Contained } from "@components/StyledComponents";
import { ItemCatalogView } from "@features/items";
import { useIsOrderWindowOrderable } from "@features/orderWindows";
import {
  AddToCartButton,
  VariantSelectionModal,
  useCreateOrderSetMutation,
  useCreateOrderSetVariantsMutation,
  useCurrentPreOrderOrderSetQuery,
} from "@features/ordering";
import {
  ProgramDetails,
  useProgramItems,
  useProgramQuery,
} from "@features/programs";
import { LoadingButton } from "@features/ui";
import { Item } from "@models";
import { useMutateError } from "@services/api";
import DocTitle from "@utility/DocTitle";

/*
The program view displays all information for the selected program, and also gives access to view all items
in the program. From there the user can also create a pdf based on the items in the program.
*/

const ProgramOrder = () => {
  const setError = useMutateError();
  const navigate = useNavigate();
  const { programId, orderWindowId } = useParams() as unknown as Record<
    string,
    string
  >;
  const { currentChannelId, currentTerritoryId } = useSelector(
    (state: any) => state.currentUser
  );

  const { orderSet, isLoading: isOrderSetLoading } =
    useCurrentPreOrderOrderSetQuery(programId, orderWindowId);

  const createOrderSetMutation = useCreateOrderSetMutation();
  const createOrderSetVariants = useCreateOrderSetVariantsMutation();

  const handleStartOrderSet = () => {
    createOrderSetMutation.mutate(
      {
        orderSetType: "pre-order",
        preOrderOptions: {
          orderWindowId: orderWindowId,
          programId: programId,
        },
      },
      {
        onSuccess: (os) => {
          navigate(`/order-sets/${os.id}`);
        },
        onError: (err) => {
          setError(err, "Create Order Set");
        },
      }
    );
  };

  // Initialize a promise queue
  const mutationQueue = useRef<Promise<any>>(Promise.resolve());

  const addVariantsToOrderSet = async (variantIds: string[]) => {
    if (!orderSet) return;
    return new Promise<void>((resolve) => {
      // chain the promise so that the mutation is only called once at a time
      mutationQueue.current = mutationQueue.current.then(() => {
        return (
          createOrderSetVariants
            .mutateAsync({
              // always use the latest order-set id
              orderSetId: orderSet.id,
              variantIds,
              orderType: "pre-order",
            })
            .catch((err) => setError(err, "Add Order Set Variants"))
            // resolve the parent promise when the mutation is done
            .finally(() => resolve())
        );
      });
    });
  };

  const { data: program } = useProgramQuery(programId);
  const { data, ...tableProps } = useProgramItems(programId, {
    isPublic: true,
  });
  const items = data || [];

  const [variantSelectionItem, setVariantSelectionItem] = useState<null | Item>(
    null
  );

  const selectedOrderWindow =
    orderWindowId &&
    program?.orderWindows.find((ow) => ow.id === orderWindowId);

  const isOrderWindowOrderable = useIsOrderWindowOrderable();
  const isProgramOrderable =
    selectedOrderWindow && isOrderWindowOrderable(selectedOrderWindow);

  const orderableForChannelTerritory =
    selectedOrderWindow &&
    program &&
    (!program.isTerritoryExclusive ||
      program.territories.some((t) => t.id === currentTerritoryId)) &&
    (program.channel?.id ?? null) === currentChannelId &&
    selectedOrderWindow.territories.some((t) => t.id === currentTerritoryId);

  const { role } = useSelector((state: any) => state.currentUser);

  const addToOrderSet = async (item: Item) => {
    const defaultVariant = item.variants.find((v) => v.isDefault);

    if (defaultVariant?.isActive) {
      await addVariantsToOrderSet([defaultVariant.id]);
    } else {
      setVariantSelectionItem(item);
    }
  };

  return (
    <>
      <DocTitle title={"Program Order"} />
      {variantSelectionItem && (
        <VariantSelectionModal
          item={variantSelectionItem}
          onClose={() => setVariantSelectionItem(null)}
          orderType="pre-order"
          {...{
            orderSetVariants: orderSet?.orderSetVariants ?? [],
            createOrUpdateOrderSet: addVariantsToOrderSet,
          }}
        />
      )}
      <Contained tw="space-y-3 pb-3">
        <ProgramDetails
          ctaComponent={
            orderableForChannelTerritory &&
            role !== "read-only" &&
            (orderSet ? (
              <LoadingButton
                variant="contained"
                startIcon={<ExitToAppTwoTone />}
                component={Link}
                to={`/order-sets/${orderSet.id}`}
              >
                View Order
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                startIcon={<ExitToAppTwoTone />}
                onClick={handleStartOrderSet}
                loading={isOrderSetLoading}
                disabled={!isProgramOrderable}
              >
                Start Order
              </LoadingButton>
            ))
          }
          programId={programId}
          items={items}
        />

        <div tw="flex gap-4 items-center justify-between">
          {program && !orderableForChannelTerritory && (
            <Alert severity="warning">
              This program is not available for your current{" "}
              {currentChannelId && "channel and "}territory.
            </Alert>
          )}

          {!orderWindowId && (
            <h3 tw="text-lg text-neutral-600">Item Preview</h3>
          )}
          {/* <ItemViewButtons /> */}
        </div>

        <ItemCatalogView
          rows={items}
          rowActions={
            orderSet
              ? (item) => (
                  <AddToCartButton
                    item={item}
                    orderSetVariants={orderSet.orderSetVariants}
                    addToOrderSet={addToOrderSet}
                  />
                )
              : undefined
          }
          {...tableProps}
        />
      </Contained>
    </>
  );
};
export default ProgramOrder;
