/** @jsxImportSource @emotion/react */
import "twin.macro";

import SearchableText from "@components/Table/SearchableText";
import {
  ItemIdentifiers,
  ItemPreviewImageButton,
  variantName,
} from "@features/items";
import { OrderSetVariantSummary } from "@models/OrderSetVariantSummary";
import { OrderVariant } from "@models/OrderVariant";

type ItemColumnProps = {
  row: OrderVariant | OrderSetVariantSummary;
  query?: string;
  imgProps?: Partial<React.ComponentProps<typeof ItemPreviewImageButton>>;
};

const ItemColumn: React.FC<ItemColumnProps> = ({ row, query, imgProps }) => {
  return (
    <div tw="flex gap-3 w-max max-w-sm items-start">
      <ItemPreviewImageButton
        item={row.variant.item}
        imageId={row.variant.imageId}
        {...imgProps}
      />

      <div>
        <ItemIdentifiers variant={row.variant} query={query} />

        <div tw="inline-block text-base leading-tight mt-2">
          <SearchableText text={row.variant.item.name} query={query} />
          {row.variant.selectedVariantOptions.length > 0 && (
            <div tw="text-sm text-neutral-500">{variantName(row.variant)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemColumn;
