/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Button } from "@mui/material";

import { useCurrentOrderSet } from "../../CurrentOrderSetContext";
import BulkUploadOrdersModal from "./BulkUploadOrdersModal";

const BulkUploadOrdersButton = () => {
  const { orders } = useCurrentOrderSet();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {modalOpen && (
        <BulkUploadOrdersModal onClose={() => setModalOpen(false)} />
      )}
      {orders.length === 0 && (
        <Button variant="outlined" onClick={() => setModalOpen(true)}>
          Bulk Upload Orders
        </Button>
      )}
    </>
  );
};

export default BulkUploadOrdersButton;
