import { Order } from "@models/Order";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

export default function useCanEditOrder(order: Order) {
  const roleIs = useRoleIs();
  return (
    order.status === "draft" ||
    (order.status === "submitted" && roleIs(permissions.orderReview)) ||
    (order.status === "approval-review" && roleIs(permissions.reviewApprovals))
  );
}
