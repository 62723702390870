/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Alert, Button } from "@mui/material";

import { Contained } from "src/components/StyledComponents";
import { formatMoneyString } from "src/utility/utilityFunctions";

import useConfirm from "@features/confirm";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import useDeleteAllOrdersMutation from "../data/mutations/useDeleteAllOrdersMutation";
import useDeleteOrderSetMutation from "../data/mutations/useDeleteOrderSetMutation";
import OrderSetApprovalActions from "../orderSetApproval/OrderSetApprovalActions";
import { BulkUploadOrdersButton } from "./bulkUploadOrders";

const OrderSetActionBar = ({ canDelete = true }: { canDelete?: boolean }) => {
  const confirm = useConfirm();
  const orderVariantErrors: Record<string, any> = useSelector(
    (state: any) => state.orderSet.orderVariantErrors
  );
  const { orderSet, orders } = useCurrentOrderSet();
  const deleteOrderSet = useDeleteOrderSetMutation();
  const deleteAllOrders = useDeleteAllOrdersMutation();

  const [orderingDescriptionOpen, setOrderingDescriptionOpen] = useState(
    !!orderSet.programOrderDescription
  );

  const orderSetExtendedTotal = orders.reduce(
    (total, order) => total + order.totalPrice,
    0
  );

  const hasQuantities = orders.some((order) => order.totalQuantity > 0);

  const handleRemoveAllOrders = async () => {
    if (
      !(await confirm(
        "Are you sure you want to delete all orders from this Order-Set?"
      ))
    )
      return;
    deleteAllOrders.mutate();
  };

  const handleRemoveOrderSet = async () => {
    if (!(await confirm("Are you sure you want to delete this Order-Set?")))
      return;
    deleteOrderSet.mutate();
  };

  return (
    <div tw="fixed inset-0 top-auto bottom-4 z-20 left-[var(--sidebar-width)] lg:ml-3">
      <Contained>
        <div tw="rounded shadow-md bg-white flex flex-wrap justify-between items-center px-4 py-3">
          {orderingDescriptionOpen && (
            <Alert
              tw="w-full flex-none mb-2"
              severity="info"
              onClose={() => setOrderingDescriptionOpen(false)}
            >
              <p>{orderSet.programOrderDescription}</p>
            </Alert>
          )}
          <div tw="flex justify-end items-baseline gap-4">
            {canDelete && (
              <>
                <Button
                  variant="danger"
                  onClick={handleRemoveOrderSet}
                  id="tutorial-delete-order-set"
                  className="tutorial-highlight"
                >
                  Delete Order Set
                </Button>
                <Button variant="danger" onClick={handleRemoveAllOrders}>
                  Delete All Orders
                </Button>
              </>
            )}
          </div>
          <OrderSetApprovalActions />
          <div tw="flex justify-end items-center gap-4">
            <BulkUploadOrdersButton />
            {orders.length > 0 && (
              <h2 tw="text-neutral-600 text-lg">
                Total:{" "}
                <b tw="font-medium">
                  {formatMoneyString(orderSetExtendedTotal)}
                </b>
              </h2>
            )}
            <div
              id="tutorial-order-set-overview"
              className="tutorial-highlight"
            >
              <Button
                variant="contained"
                component={Link}
                disabled={
                  orderSet.status === "inactive" ||
                  !hasQuantities ||
                  Object.keys(orderVariantErrors).length !== 0
                }
                to={"overview"}
              >
                Order Overview
              </Button>
            </div>
          </div>
        </div>
      </Contained>
    </div>
  );
};

export default OrderSetActionBar;
