import * as Sentry from "@sentry/react";
import { DefaultError, Mutation, Query } from "@tanstack/react-query";

export const captureMutationException = (
  error: DefaultError,
  mutation: Mutation<unknown, unknown, unknown, unknown>
) => {
  Sentry.withScope((scope) => {
    scope.setContext("mutation", {
      mutationId: mutation.mutationId,
      variables: mutation.state.variables,
    });
    if (mutation.options.mutationKey) {
      scope.setFingerprint(
        // Duplicate to prevent modification
        Array.from(mutation.options.mutationKey) as string[]
      );
    }
    Sentry.captureException(error);
  });
};

export const captureQueryException = (
  error: DefaultError,
  query: Query<unknown, unknown, unknown, readonly unknown[]>
) => {
  Sentry.withScope((scope) => {
    scope.setContext("query", { queryHash: query.queryHash });
    const fingerprint = query.queryKey.map((key) => {
      if (typeof key === "number") return ":id";
      if (key === null) return ":null";
      if (typeof key === "object") return ":params";
      return String(key);
    });
    scope.setFingerprint(fingerprint);
    Sentry.captureException(error);
  });
};
