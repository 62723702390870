import { useMutation, useQueryClient } from "@tanstack/react-query";

import client from "@services/api";

import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";

export default function useUpdateOrderSetMutation() {
  const orderSetId = useOrderSetId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client
        .update(`order-sets/${orderSetId}`, { __type: "order-set", ...data })
        .then((data) => data.data);
    },
    onSuccess: (newOrderSet) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey,
        newOrderSet
      );

      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    },
  });
}
