import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { Promotion } from "@models/Promotion";
import client, { RequestParams } from "@services/api";
import { QueryOptions } from "@utils/reactQuery";

export const promotionsKeyFactory = createQueryKeys("promotions", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Promotion[]>("promotions", { params }).then((res) => res.data),
  }),
});

export const usePromotionsQuery = (
  params?: RequestParams,
  options?: QueryOptions<Promotion[]>
) => {
  return useQuery({ ...promotionsKeyFactory.list(params), ...options });
};
