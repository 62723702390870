/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Contained } from "@components/StyledComponents";
import {
  OrderSetCheckoutContent,
  OrderSetNavigation,
} from "@features/ordering";

const OrderSetCheckout = () => {
  return (
    <Contained tw="pb-24 max-w-screen-xl space-y-6">
      <OrderSetNavigation stage="checkout" />
      <OrderSetCheckoutContent />
    </Contained>
  );
};

export default OrderSetCheckout;
