/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { CircularProgress, Paper, Tooltip } from "@mui/material";

import _ from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable from "@components/Table/ReactQueryTable";
import SmartTable from "@components/Table/SmartTable";
import { getItemPreviewImage, variantName } from "@features/items";
import { FaIcon, HelperTextLabel } from "@features/ui";
import { OrderVariant } from "@models/OrderVariant";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import { CldImage } from "@services/cloudinary";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";

const OrderSetVariantsOverview = () => {
  const dispatch = useDispatch();

  const { orderSet, orders, orderSetVariants } = useCurrentOrderSet();

  const groupedOrderVariants: Record<string, OrderVariant[]> = _(orders ?? [])
    .flatMap("orderVariants")
    .filter((ov) => ov.qty > 0)
    .groupBy("variant.id")
    .value();

  const countByItemIds = _(orderSetVariants)
    .groupBy("variant.item.id")
    .mapValues((v) => _.sumBy(v, "totalQty"))
    .value();

  if (!orderSet) return <CircularProgress />;

  return (
    <OpaqueCard tw="p-0" css={{ td: tw`align-middle!` }}>
      <ReactQueryTable
        rows={orderSetVariants.filter((osv) => (osv.totalQty ?? 0) > 0)}
        columns={[
          {
            id: "_img",
            label: "Item",
            render: (_, osv) => (
              <div
                tw="flex-shrink-0 w-16 relative rounded-md cursor-pointer overflow-hidden
                              after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
                              hover:after:opacity-20"
                role="button"
                tabIndex={-1}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    setItemPreview({
                      id: osv.variant.item.id,
                      focusImageId: osv.variant.imageId,
                    })
                  );
                }}
              >
                <CldImage
                  tw="w-full aspect-square object-contain 
                                border-2 border-neutral-200
                              bg-white rounded-md"
                  image={getItemPreviewImage(
                    osv.variant.item,
                    osv.variant.imageId
                  )}
                  size="thumbnail"
                />
              </div>
            ),
          },
          {
            id: "_item",
            label: "",
            render: (_, osv) => (
              <div>
                <div tw="flex gap-2 items-center text-primary-600">
                  {osv.variant.externalWarehouseId ?? osv.variant.variantSku}
                </div>
                <div tw="text-base! text-neutral-800 leading-tight mt-1">
                  {osv.variant.item.name}
                </div>
                {osv.variant.selectedVariantOptions.length > 0 && (
                  <div tw="text-neutral-500">{variantName(osv.variant)}</div>
                )}
                {osv.customization && (
                  <p tw="leading-tight mt-1.5">
                    <span tw="text-neutral-800">
                      {osv.variant.item.customizationDescription}:{" "}
                    </span>
                    <span tw="text-primary-800 rounded bg-primary-50 px-1 decoration-clone">
                      {osv.customization}
                    </span>
                  </p>
                )}
              </div>
            ),
          },
          ...(orderSet.type === "on-demand"
            ? [
                {
                  id: "variant.item.minimumOrderQuantity",
                  label: (
                    <HelperTextLabel
                      title={
                        <p>
                          <b>Minimum Order Quantity for On-Demand Orders:</b>{" "}
                          The MOQ applies to the total number of units ordered
                          for an item, including all variants if applicable.
                        </p>
                      }
                    >
                      MOQ Reached
                    </HelperTextLabel>
                  ),
                  render: (moq, osv) => {
                    const meetsMoq = countByItemIds[osv.variant.item.id] >= moq;
                    if (meetsMoq) {
                      return (
                        <Tooltip title="Meets Minimum Order Quantity">
                          <div tw="w-6 h-6 rounded-full bg-green-50 text-green-800 flex items-center justify-center">
                            <FaIcon
                              icon="check"
                              variant="solid"
                              tw="text-green-600"
                            />
                          </div>
                        </Tooltip>
                      );
                    }
                    return (
                      <Tooltip title="Does not meet Minimum Order Quantity">
                        <Link
                          to={`..#${osv.variant.id}`}
                          tw="text-red-800 font-medium bg-red-50 px-2 py-0.5 rounded whitespace-nowrap"
                        >
                          <FaIcon icon="warning" tw="mr-3" />
                          <span tw="text-yellow-800">
                            {countByItemIds[osv.variant.item.id]}
                          </span>{" "}
                          of {osv.variant.item.minimumOrderQuantity}
                        </Link>
                      </Tooltip>
                    );
                  },
                },
              ]
            : []),
          {
            id: "price",
            label: "Price",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "_totalQty",
            label: "QTY",
            align: "right",
            render: (v, osv) =>
              _.sumBy(groupedOrderVariants[osv.variant.id], "qty"),
          },

          {
            id: "_extendedCost",
            label: "Total",
            align: "right",
            render: (v, osv) => {
              const qty = _.sumBy(groupedOrderVariants[osv.variant.id], "qty");
              const price = +(osv.price ?? 0);
              return (
                <span tw="font-medium">{formatMoneyString(price * qty)}</span>
              );
            },
          },
        ]}
        collapsibleContent={(osv) => (
          <div tw="max-w-full">
            <h4 tw="text-neutral-600 mb-2">Item Allocations</h4>
            <Paper>
              <SmartTable
                rows={groupedOrderVariants[osv.variant.id] ?? []}
                columns={[
                  { id: "order.id", label: "Order #" },
                  {
                    id: "order.address.name",
                    label: "Address Name",
                  },
                  { id: "order.address.city", label: "City" },
                  {
                    id: "_state",
                    label: "State",
                    render: (_, osv) =>
                      osv.order.address.state?.code ?? osv.order.address.region,
                  },
                  { id: "order.address.zip", label: "Zip Code" },
                  { id: "qty", label: "Qty" },
                ]}
              />
            </Paper>
          </div>
        )}
        noResultsText="All items on this order set have been canceled."
      />
    </OpaqueCard>
  );
};

export default OrderSetVariantsOverview;
