import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import {
  BeaconPing,
  BeaconStateReport,
  BeaconVariantSummaryReport,
} from "@models/Beacon";
import client, { RequestParams } from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const beaconsKeyFactory = createQueryKeys("beacons", {
  pings: (params) => ({
    queryKey: ["pings", params],
    queryFn: () => client.get<BeaconPing[]>("beacons", { params }),
  }),
  variantsReport: (params) => ({
    queryKey: ["variantsReport", params],
    queryFn: () =>
      client
        .get<BeaconVariantSummaryReport[]>("beacons/variants", { params })
        .then((res) => res.data),
  }),
  statesReport: (params) => ({
    queryKey: ["statesReport", params],
    queryFn: () =>
      client
        .get<BeaconStateReport[]>("beacons/states", { params })
        .then((res) => res.data),
  }),
});

export const usePaginatedBeaconPingsQuery = buildPaginatedQuery(
  beaconsKeyFactory.pings,
  {
    staleTime: 1000 * 60 * 5,
  }
);

export const useBeaconVariantsReportQuery = (params?: RequestParams) => {
  return useQuery({
    ...beaconsKeyFactory.variantsReport(params),
    staleTime: 5 * 60_000,
    placeholderData: keepPreviousData,
  });
};

export const useBeaconStatesReportQuery = (params?: RequestParams) => {
  return useQuery({
    ...beaconsKeyFactory.statesReport(params),
    staleTime: 5 * 60_000,
    placeholderData: keepPreviousData,
  });
};
