import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addressesKeyFactory } from "@features/addresses";
import { orderSetsKeyFactory, useOptionalOrderSetId } from "@features/ordering";
import { DeliveryInstructions } from "@models/DeliveryInstructions";
import { Order } from "@models/Order";
import client from "@services/api";

export const useCreateDeliveryInstructionsMutation = () => {
  const orderSetId = useOptionalOrderSetId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ addressId }: { addressId: string; orderId?: string }) =>
      client
        .post<DeliveryInstructions>(`delivery-instructions`, { addressId })
        .then((res) => res.data),
    onSuccess: (deliveryInstructions, { addressId, orderId }) => {
      queryClient.setQueryData(
        addressesKeyFactory.detail(addressId).queryKey,
        (address) => address && { ...address, deliveryInstructions }
      );

      if (orderId && orderSetId) {
        queryClient.setQueryData<Order[]>(
          orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
          (orders) =>
            orders &&
            orders.map((order) =>
              orderId === order.id
                ? {
                    ...order,
                    address: { ...order.address, deliveryInstructions },
                  }
                : order
            )
        );
      }
    },
  });
};

type DeliveryInstructionsPayload = {
  id: string;
  addressId?: string;
  orderId?: string;
} & Partial<Omit<DeliveryInstructions, "id">>;

export const useUpdateDeliveryInstructionsMutation = () => {
  const orderSetId = useOptionalOrderSetId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: DeliveryInstructionsPayload) =>
      client
        .update<DeliveryInstructions>(`delivery-instructions/${id}`, data)
        .then((res) => res.data),
    onSuccess: (deliveryInstructions, { addressId, orderId }) => {
      if (addressId) {
        queryClient.setQueryData(
          addressesKeyFactory.detail(addressId).queryKey,
          (address) => address && { ...address, deliveryInstructions }
        );
      }
      if (orderId && orderSetId) {
        queryClient.setQueryData<Order[]>(
          orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
          (orders) =>
            orders &&
            orders.map((order) =>
              orderId === order.id
                ? {
                    ...order,
                    address: { ...order.address, deliveryInstructions },
                  }
                : order
            )
        );
      }
    },
  });
};
