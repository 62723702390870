import React from "react";
import { Outlet, UIMatch, useMatches, useNavigate } from "react-router-dom";

import { OrderSet } from "@models/OrderSet";

import useOrderLock from "../hooks/useOrderLock";
import { CurrentOrderSetContextProvider } from "./CurrentOrderSetContext";

const OrderSetProvider = () => {
  const matches: UIMatch<any, any>[] = useMatches();
  const match = matches.find((match) => match.handle?.fallback);
  const navigate = useNavigate();
  const onOrderSetDelete = (os: OrderSet) => {
    const addItemsUrl = `/orders/${os.type}`;

    navigate(
      os.type === "pre-order"
        ? `/programs/${os.program?.id}/order/${os.orderWindow?.id}`
        : addItemsUrl
    );
  };

  useOrderLock();

  return (
    <CurrentOrderSetContextProvider
      onOrderSetDelete={onOrderSetDelete}
      fallback={match && match.handle.fallback}
    >
      <Outlet />
    </CurrentOrderSetContextProvider>
  );
};

export default OrderSetProvider;
