/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { getItemPreviewImage } from "@features/items";
import { setItemPreview } from "@redux/slices/items/itemSlice";

import { Item } from "../../../models";
import { CldImage } from "../../../services/cloudinary";
import ItemIdentifiers from "./ItemIdentifiers";

const MinimalItemCard = ({
  item,
  disabledInteractive,
  includeDescription,
  imageProps,
  ...props
}: {
  item: Item;
  imageProps?: React.HTMLAttributes<HTMLDivElement>;
  disabledInteractive?: boolean;
  includeDescription?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useDispatch();
  return (
    <div tw="flex gap-2" {...props}>
      <div
        css={[
          tw`relative w-full overflow-hidden rounded-md`,
          tw`after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)`,
          !disabledInteractive && tw`hover:after:opacity-20`,
        ]}
        {...(!disabledInteractive && {
          role: "button",
          tabIndex: -1,
          onClick: (e) => {
            e.stopPropagation();
            dispatch(setItemPreview(item));
          },
        })}
        {...imageProps}
      >
        <CldImage
          tw="w-full aspect-square object-contain 
                  border-2 border-neutral-200
                bg-white rounded-md"
          image={getItemPreviewImage(item)}
          size="medium"
        />
      </div>
      <div tw="space-y-1">
        <ItemIdentifiers item={item} />
        <h3 tw="leading-tight">
          {disabledInteractive ? (
            item.name
          ) : (
            <Link
              to={`/admin/items/${item.id}`}
              target="_blank"
              tw="text-neutral-600 hover:underline"
            >
              {item.name}
            </Link>
          )}
        </h3>
        {includeDescription && (
          <p tw="text-neutral-500 line-clamp-3">{item.description}</p>
        )}
      </div>
    </div>
  );
};

export default MinimalItemCard;
