import { useSearchParams } from "react-router-dom";

import { pickBy } from "lodash";

import { useSetLocation } from "@services/reactRouterDom";

function splitObjectKeys(
  obj: Record<string, any>,
  delimiter: string
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const parts = key.split(delimiter);
    let currentObj = result;

    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];

      if (!currentObj[part]) {
        currentObj[part] = {};
      }

      currentObj = currentObj[part];
    }

    currentObj[parts[parts.length - 1]] = value;
  }

  return result;
}

const flattenObject = (obj, keys: string[] = [], separator = ".") =>
  Object.keys(obj).reduce((memo, prop) => {
    const value = obj[prop];
    const nextKeys = keys.concat([prop]);
    const isObject =
      Object.prototype.toString.call(value) === "[object Object]";

    if (isObject) {
      return {
        ...memo,
        ...flattenObject(value, nextKeys, separator),
      };
    }

    return { ...memo, [nextKeys.join(separator)]: value };
  }, {});

const encodeArrays = (obj: Record<string, string | boolean | string[]>) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      Array.isArray(value)
        ? [key + "[]", value.join(",")]
        : [key, value?.toString()]
    )
  );

export const encodeSearchParams = (data: Record<string, any>) => {
  // Heads up: we might eventually have a filter that accepts "false" as a value.
  const filterData = pickBy(
    encodeArrays(flattenObject(data)),
    (val) => val && val !== "false"
  );

  return new URLSearchParams(filterData).toString();
};

const decodeArrays = (obj: Record<string, string>) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      key.endsWith("[]") ? [key.slice(0, -2), value.split(",")] : [key, value]
    )
  );

const decodeSearchParams = (searchParams: URLSearchParams) => {
  const obj = Object.fromEntries(searchParams.entries());
  return splitObjectKeys(decodeArrays(obj), ".");
};

const useFilterParams = (): [
  Record<string, any>,
  (filterObject: Record<string, any>) => void,
] => {
  const [searchParams] = useSearchParams();
  // setSearchParams overwrites the hash, so we need to use useSetLocation
  const setLocation = useSetLocation();

  const applyFilters = (formData) => {
    const search = encodeSearchParams(formData).toString();
    setLocation({ search: `?${search}` }, { replace: true });
  };

  const appliedFilters = decodeSearchParams(searchParams);

  return [appliedFilters, applyFilters];
};

export default useFilterParams;
