import { useMemo } from "react";

import { useItemListQuery } from "@features/items";
import { Item } from "@models/Item";

const useBeaconItemLookup = () => {
  const { data: items = [] } = useItemListQuery(
    {
      filter: {
        hasBeacons: true,
      },
    },
    { staleTime: 60 * 60_000 }
  );
  const lookup = useMemo(() => {
    const map = new Map<string, Item>();

    items.forEach((item) => {
      map.set(item.id, item);
    });

    return map;
  }, [items]);

  return lookup;
};

export default useBeaconItemLookup;
