/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Link, useNavigate, useParams } from "react-router-dom";

import { ChevronLeftRounded, GetAppTwoTone } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { sortBy } from "lodash";

import { ProgramItemPdfLoader } from "@components/PDF/PDFLoader";
import {
  OpaqueCard,
  SectionTitle,
  StyledButton,
} from "@components/StyledComponents";
import { isOrderWindowActive } from "@features/orderWindows";
import { Item } from "@models";
import { CldImage, getResourceUrl } from "@services/cloudinary";
import DocTitle from "@utility/DocTitle";
import { formatUtcDate, utcDate } from "@utility/utilityFunctions";

import { useProgramQuery } from "./data";

type ProgramDetailsProps = {
  programId: string;
  items?: Item[];
  ctaComponent?: React.ReactNode;
};
const ProgramDetails = ({
  programId,
  items,
  ctaComponent,
}: ProgramDetailsProps) => {
  const { data: program } = useProgramQuery(programId);
  const { orderWindowId } = useParams();
  const navigate = useNavigate();

  const selectedOrderWindow =
    orderWindowId &&
    program?.orderWindows?.find((ow) => ow.id === orderWindowId);
  const sortedOrderWindows = sortBy(program?.orderWindows ?? [], "closeDate");
  const nextOrderWindow = sortedOrderWindows.find(
    (ow) => utcDate(ow.closeDate) > new Date()
  );
  const lastOrderWindow = sortedOrderWindows
    .reverse()
    .find((ow) => utcDate(ow.closeDate) < new Date());
  const orderWindow = selectedOrderWindow || nextOrderWindow || lastOrderWindow;
  const orderWindowActive = orderWindow && isOrderWindowActive(orderWindow);

  const orderWindowSectionTitle = selectedOrderWindow
    ? "Order Window"
    : !nextOrderWindow
      ? "Last Order Window"
      : orderWindowActive
        ? "Active Order Window"
        : "Next Order Window";

  if (!program) return null;

  return (
    <>
      <DocTitle title={program.name} />
      <OpaqueCard>
        <div tw="flex justify-between grow flex-col md:flex-row">
          <h1 tw="text-3xl font-bold text-neutral-700 mb-4 flex items-center gap-2">
            <Tooltip
              title={
                orderWindowId ? "Back to Pre Orders" : "Back to all programs"
              }
            >
              <IconButton
                onClick={() =>
                  navigate(
                    orderWindowId ? `/pre-orders/${orderWindowId}` : "/programs"
                  )
                }
              >
                <ChevronLeftRounded fontSize="large" />
              </IconButton>
            </Tooltip>
            {program.name}
          </h1>
          <div tw="flex gap-2 items-start justify-end">
            {program.additionalFileCloudinaryId &&
              program.additionalFileResourceType && (
                <StyledButton
                  outlined
                  startIcon={<GetAppTwoTone color="inherit" />}
                  component={"a"}
                  target="_blank"
                  href={getResourceUrl(program.additionalFileCloudinaryId, {
                    resourceType: program.additionalFileResourceType,
                  })}
                >
                  PROGRAM INFO
                </StyledButton>
              )}

            {items && items.length > 0 && (
              <ProgramItemPdfLoader
                items={items}
                programName={program.name}
                fileName={`${program.name.split(" ").join("-").toLowerCase()}`}
              />
            )}

            {ctaComponent}
          </div>
        </div>
        <div tw="flex flex-col xl:flex-row gap-4 pb-4">
          <CldImage
            tw="w-56 h-56 rounded-xl mx-4 mb-4 object-cover object-center"
            publicId={program.cloudinaryId}
            alt={program.name}
            size="medium"
          />
          <div tw="grow text-neutral-600 mx-4 grid gap-6 lg:grid-cols-[auto 50%]">
            <div tw="space-y-4">
              <div>
                <SectionTitle>Description</SectionTitle>
                <p tw="max-w-prose whitespace-pre-wrap">
                  {program.description}
                </p>
              </div>
              {program.orderDescription && (
                <div>
                  <SectionTitle>Order Description</SectionTitle>
                  <p tw="max-w-prose whitespace-pre-wrap">
                    {program.orderDescription}
                  </p>
                </div>
              )}
              {!!program.goals?.length && (
                <div>
                  <SectionTitle>Program Goals</SectionTitle>
                  <ul tw="list-disc pl-4">
                    {program.goals.map((goal, i) => (
                      <li key={`goal-${i}`}>{goal}</li>
                    ))}
                  </ul>
                </div>
              )}
              {!!program.strategies?.length && (
                <div>
                  <SectionTitle>Marketing Strategies</SectionTitle>
                  <ul tw="list-disc pl-4">
                    {program.strategies.map((goal, i) => (
                      <li key={`goal-${i}`}>{goal}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div tw="space-y-4 max-w-xs">
              {orderWindow && (
                <div>
                  <SectionTitle>{orderWindowSectionTitle}</SectionTitle>
                  {
                    <div
                      css={[
                        tw`p-3 rounded-lg bg-neutral-100`,
                        orderWindowActive && tw`bg-blue-50`,
                      ]}
                    >
                      <h4 tw="font-medium">
                        <Link
                          to={`/pre-orders/${orderWindow.id}`}
                          tw="hover:underline"
                        >
                          {orderWindow.name}
                        </Link>
                      </h4>
                      <div tw="text-sm mt-1">
                        {formatUtcDate(orderWindow.openDate, "PP")} &mdash;{" "}
                        {formatUtcDate(orderWindow.closeDate, "PP")}
                      </div>
                    </div>
                  }
                </div>
              )}
              {!orderWindow && (
                <div tw="bg-neutral-100 p-3 rounded-lg">
                  <h4 tw="font-medium">Program unavailable to order</h4>
                  <div tw="text-sm mt-1">
                    This program does not have any order windows.
                  </div>
                </div>
              )}
              <div>
                <SectionTitle>Available Territories</SectionTitle>
                <div tw="flex flex-wrap gap-1 text-neutral-700">
                  {program.isTerritoryExclusive
                    ? program.territories.map((t) => (
                        <div
                          tw="bg-neutral-100 inline-block px-2 py-0.5 rounded text-sm"
                          key={t.id}
                        >
                          {t.name}
                        </div>
                      ))
                    : "All Territories"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </OpaqueCard>
    </>
  );
};

export default ProgramDetails;
