class MiniStorage {
    store: Record<string, string>
    constructor() {
        this.store = {}
        console.log('Using MiniStorage')
    }

    public getItem(key: string): string | null {
        return this.store[key]
    }
    public setItem(key: string, value: string): void {
        this.store[key] = value
    }
    public removeItem(key: string) {
        delete this.store[key]
    }
}

class Storage {
    chosenStorage: any
    constructor() {
        this.chosenStorage = (localStorage) ? localStorage : new MiniStorage()
    }

    public getItem(key: string): string | null {
        return this.chosenStorage.getItem(key)
    }
    public setItem(key: string, value: string): void {
        return this.chosenStorage.setItem(key, value)
    }
    public removeItem(key: string): void {
        return this.chosenStorage.removeItem(key)
    }
}

export const storage = new Storage()