import { useSelector } from "react-redux";

import { useOrderSetQuery } from "./data";

export const useDraftOrderSetQuery = (orderType: "inventory" | "on-demand") => {
  const currentOrderId = useSelector(
    (state: any) => state.globalState.draftOrderIds[orderType]
  );

  return useOrderSetQuery(currentOrderId);
};

export default useDraftOrderSetQuery;
