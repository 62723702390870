/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link, useNavigate } from "react-router-dom";

import { ArrowBackRounded } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";

import { useLastLocation } from "@services/reactRouterDom";

const BackButton = ({
  defaultUrl = "/",
  override,
  ...props
}: { defaultUrl?: string; override?: string } & IconButtonProps) => {
  const navigate = useNavigate();
  const lastLocation = useLastLocation();
  const lastUrl = lastLocation && lastLocation.pathname + lastLocation.search;

  const handleBackClick = (e) => {
    if (!override && lastUrl) {
      e.preventDefault();
      // Pop the history stack, this is different than
      // navigating to lastUrl as it removes the last location from the history stack
      navigate(-1);
    }
  };

  return (
    <IconButton
      tw="text-neutral-600"
      component={Link}
      to={override ?? lastUrl ?? defaultUrl}
      onClick={handleBackClick}
      title="Back"
      {...props}
      state={{ preventLastLocation: true }}
    >
      <ArrowBackRounded />
    </IconButton>
  );
};

export default BackButton;
