/** @jsxImportSource @emotion/react */
import "twin.macro";

import { styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BlueGreenLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 8,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: value === 100 ? "#00C489" : "#0099cc",
  },
}));

const BeaconProgressBar = ({ beaconsPinged, estBeaconsExpected }) => {
  const percent = estBeaconsExpected
    ? Math.min(100, (beaconsPinged / estBeaconsExpected) * 100)
    : 0;
  return (
    <div tw="flex gap-2 items-center justify-between mt-1">
      <BlueGreenLinearProgress
        variant="determinate"
        tw="w-10/12"
        value={percent}
      />
      <div tw="text-neutral-500 text-xs text-right whitespace-nowrap min-w-max">
        {`${beaconsPinged} / ${estBeaconsExpected}`}
      </div>
    </div>
  );
};

export default BeaconProgressBar;
