/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Typography } from "@mui/material";

import { ItemIdentifiers, getItemPreviewImage } from "@features/items";
import { Item } from "@models/Item";
import { CldImage } from "@services/cloudinary";
import { ControlledResourceAutocomplete } from "@utils/forms";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

import { useFilterContext } from "../filterContext";

const isMatch = (item: Item, filter: string) => {
  return (
    item.name.toLowerCase().includes(filter.toLowerCase()) ||
    item.variants.some((v) =>
      v.variantSku.toLowerCase().includes(filter.toLowerCase())
    ) ||
    item.variants.some((v) =>
      v.externalWarehouseId?.toLowerCase().includes(filter.toLowerCase())
    )
  );
};

const FilterBeaconItems = () => {
  const { control, setValue, getValues, setFilterValues } = useFilterContext();
  return (
    <ControlledResourceAutocomplete
      fullWidth
      tw="max-w-72"
      resource="items"
      resourceFilterName="query"
      control={control}
      requestParams={{
        filter: {
          hasBeacons: true,
        },
      }}
      filterOptions={(opts, { inputValue }) =>
        opts.filter((item) => isMatch(item, inputValue))
      }
      name="itemId"
      onChange={(_, value) => {
        setValue("variantIds", value?.variants.map((v) => v.id) ?? []);
        setFilterValues(getValues() as any);
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          key={option.id}
          className={props.className + " group"}
          tw="flex gap-3"
        >
          <div tw="flex-none w-14 h-14 rounded overflow-hidden border-2 border-neutral-200">
            <CldImage
              image={getItemPreviewImage(option)}
              tw="w-full h-full object-cover"
              size="thumbnail"
            />
          </div>
          <div>
            <ItemIdentifiers item={option} />
            <Typography noWrap>{option.name}</Typography>
            {/* <Typography tw="text-neutral-400 text-sm ml-auto group-hover:text-neutral-700"></Typography> */}
          </div>
        </li>
      )}
      renderInput={({ ...params }) => (
        <StyledTextInput
          {...params}
          placeholder="Search Items"
          InputProps={{
            style: tw`h-10 rounded-md text-filter`,
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default {
  name: "beaconItems",
  formControls: ["itemId", "variantIds"],
  Popper: null,
  Chip: FilterBeaconItems,
  alwaysShow: true,
};
