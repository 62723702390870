/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { useDispatch } from "react-redux";

import { GetApp, Publish } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { saveAs } from "file-saver";
import { axiosPost } from "src/api/axiosCalls";

import { StyledButton } from "@components/StyledComponents";
import SmartTable, { Column } from "@components/Table/SmartTable";
import { itemsKeyFactory } from "@features/items";
import { setError } from "@redux/slices/errorSlice";

const columns: Column<any>[] = [
  { id: "status", label: "Status" },
  { id: "item_name", label: "Item Name" },
  { id: "item_sku", label: "Item SKU" },
];

const ItemCSVUploadModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const csvRef = useRef<any>(null);

  const [uploadResponse, setUploadResponse] = useState<{
    errors: any[];
    successes: any[];
    existing: any[];
  }>({ errors: [], successes: [], existing: [] });

  const handleOpenDialog = (evt) => {
    if (csvRef.current) {
      csvRef.current.open(evt);
    }
  };

  const downloadItemUploadForm = async () => {
    const response = await axios.get("/api/item-upload-headers", {
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "item-import-template.csv");
  };

  const handleCSVFileSelect = async (data: any) => {
    try {
      let itemDataArr: any[] = [];

      data.forEach((row) => {
        if (row.errors.length > 0) {
          setLoading(false);
          throw new Error(
            `Invalid row data, check formatting of csv file\n${row.errors
              .map((e) => e.message)
              .join("\n")}`
          );
        }

        itemDataArr.push(row["data"]);
      });

      const resp: any = await axiosPost(
        "/api/item-upload",
        {
          data: { rows: itemDataArr },
        },
        false
      );

      setLoading(false);
      queryClient.invalidateQueries({ queryKey: itemsKeyFactory._def });
      if (resp.error as any) {
        throw new Error("\n" + resp.error.join("\n"));
      } else {
        setUploadResponse(resp.data);
      }
    } catch (error: any) {
      dispatch(
        setError({ error: error.toString(), source: "Item Upload CSV File" })
      );
    }
  };

  const handleCSVFileUploadError = (err) => {
    dispatch(
      setError({ error: err.toString(), source: "Item Upload CSV File" })
    );
  };

  // Join successes and existing arrays adding a status to each for new/already existing items
  const allItems = uploadResponse.successes
    .map((item) => ({ ...item, status: "New" }))
    // Add a "status" field to each object to indicate if it's an existing item
    .concat(
      uploadResponse.existing.map((item) => ({
        ...item,
        status: "Item already exists",
      }))
    )
    // Remove duplicate items
    .filter((v, i, a) => a.findIndex((t) => t.item_sku === v.item_sku) === i);

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>Item CSV Upload</DialogTitle>
      <DialogContent>
        <div tw="flex flex-col items-center mb-8 mx-8">
          <div tw="mb-4">
            <div>Please upload a csv of items:</div>
          </div>

          <div tw="flex">
            <StyledButton
              outlined
              onClick={downloadItemUploadForm}
              startIcon={<GetApp />}
            >
              DOWNLOAD TEMPLATE
            </StyledButton>

            <CSVReader
              ref={csvRef}
              onFileLoad={handleCSVFileSelect}
              onError={handleCSVFileUploadError}
              noClick
              config={{
                header: true,
                beforeFirstChunk: (chunk) => {
                  setLoading(true);
                  // Eliminate all instruction lines before parsing the csv
                  const lines = chunk.split(/\r\n|\n/);
                  while (
                    lines.length > 0 &&
                    !lines[0].startsWith("External ID,")
                  ) {
                    lines.splice(0, 1);
                  }
                  return lines.join("\n");
                },
              }}
              noProgressBar
            >
              {({ error }) => (
                <>
                  <StyledButton
                    cta
                    startIcon={<Publish />}
                    onClick={handleOpenDialog}
                    loading={loading}
                    tw="ml-2"
                  >
                    UPLOAD ITEM CSV
                  </StyledButton>
                  {!loading && error && (
                    <div>{`Error uploading file: ${error}`}</div>
                  )}
                </>
              )}
            </CSVReader>
          </div>
          {uploadResponse.errors.length > 0 && (
            <div tw="mt-8 text-red-600">
              <h2>Errors:</h2>
              <ul>
                {uploadResponse.errors.map((error, index) => (
                  <li key={index}>
                    {index}: {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {allItems.length > 0 && (
            <div tw="my-8 flex flex-col items-center">
              <div tw="mb-2">
                {`${uploadResponse.existing.length} item(s) already exist`}
              </div>
              <div tw="mb-2">
                The following items have successfully been added to brandhub:
              </div>
              <div tw="max-h-96">
                <SmartTable rows={allItems} columns={columns} error={null} />
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <StyledButton cta disabled={loading} onClick={onClose} tw="mt-4">
        Done
      </StyledButton>
    </Dialog>
  );
};

export default ItemCSVUploadModal;
