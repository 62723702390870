/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect } from "react";

import { LinearProgress } from "@mui/material";

import _ from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { OpaqueCard } from "@components/StyledComponents";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import { useUpdateOrderSetPromotionsMutation } from "../data";

const OrderSetCostOverview = ({
  topComponent,
  bottomComponent,
}: {
  topComponent?: React.ReactNode;
  bottomComponent?: React.ReactNode;
}) => {
  const { orderSet, orders, isFetching } = useCurrentOrderSet();

  const {
    totalPrice,
    totalPromotionDiscount,
    totalQuantity,
    extendedTotalPrice,
    promotionIds,
  } = orderSet;

  const totalTax = _.sumBy(orders, "totalEstimatedTax");
  const totalFreight = _.sumBy(orders, "totalEstimatedShippingCost");

  const rows = [
    ["Total Item Price", totalPrice],
    ["Est. Freight", totalFreight],
    ["Est. Taxes", totalTax],
  ];

  const updateOrderSetPromotions = useUpdateOrderSetPromotionsMutation();
  useEffect(() => {
    if (orderSet.status !== "draft") return;
    // validate promotions on mount
    if (promotionIds.length > 0 || totalPromotionDiscount) {
      updateOrderSetPromotions.mutate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionIds.length, totalPromotionDiscount]);

  return (
    <>
      <OpaqueCard tw="space-y-4 relative">
        {topComponent}
        <div
          css={[
            tw`grid grid-cols-[auto_1fr] gap-1 transition-opacity text-neutral-500`,
            isFetching && tw`opacity-50`,
          ]}
        >
          <div>Total Items</div>
          <div tw="text-right">{totalQuantity}</div>

          {rows.map(([label, value], i) => (
            <React.Fragment key={i}>
              <div tw="text-neutral-500">{label}</div>
              <div tw="text-right text-neutral-700 font-medium">
                {formatMoneyString(value)}
              </div>
            </React.Fragment>
          ))}
          {+totalPromotionDiscount > 0 && (
            <>
              <div tw="text-neutral-500">Promotion Discount</div>
              <div tw="text-right text-neutral-500 font-medium">
                ({formatMoneyString(totalPromotionDiscount)})
              </div>
            </>
          )}
        </div>
        <div
          css={[
            tw`flex items-baseline justify-between pt-3 mt-3! font-medium border-t text-neutral-700 border-neutral-200`,
            isFetching && tw`opacity-50`,
          ]}
        >
          <div>Order Total</div>
          <div tw="text-right">{formatMoneyString(extendedTotalPrice)}</div>
        </div>

        {bottomComponent}
        {isFetching && <LinearProgress tw="absolute inset-0 top-auto" />}
      </OpaqueCard>
    </>
  );
};

export default OrderSetCostOverview;
