/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Alert, Button, Divider, Skeleton } from "@mui/material";

import { IconName } from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";

import { OpaqueCard } from "@components/StyledComponents";
import { useBudgetQuery } from "@features/budgets";
import { FaIcon, LoadingButton } from "@features/ui";
import { User } from "@models/User";
import { formatMoneyString } from "@utility/utilityFunctions";
import { ResourceAutocomplete } from "@utils/forms";
import permissions from "@utils/permissions";
import { StripeCheckout } from "@utils/stripe";
import useRoleIs from "@utils/useRoleIs";

import useCheckoutOptions from "../../hooks/useCheckoutOptions";
import BudgetSelectModal from "../BudgetSelectModal";
import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import {
  useSetOrderSetBudgetMutation,
  useStripeCheckoutMutation,
  useSubmitOrderSetMutation,
} from "../data";
import OrderSetCostCenterSelector from "./OrderSetCostCenterSelector";
import useCustomizationRequirements from "./useCustomizationRequirements";

const RequiresCustomizationWarning = () => (
  <Alert severity="warning">
    Some items require customizations before you can submit this order-set.
  </Alert>
);

const UserSelection = ({
  user,
  setUser,
}: {
  user: User | null;
  setUser: (user: User | null) => void;
}) => {
  const { currentChannelId, currentTerritoryId } = useSelector(
    (state: any) => state.currentUser
  );

  return (
    <div>
      <ResourceAutocomplete
        resource="users"
        resourceFilterName="name-or-email"
        value={user}
        onChange={(e, value) => setUser(value)}
        getOptionSecondaryText={"email"}
        requestParams={{
          filter: {
            territoryIds: [currentTerritoryId],
            ...(currentChannelId && { channelIds: [currentChannelId] }),
          },
        }}
      />
      <p tw="text-sm text-neutral-500 mb-4 mt-1">
        * User selection is limited to the territory
        {currentChannelId ? " and channel" : ""} you are currently working in.
      </p>
    </div>
  );
};

const CheckoutButtons = ({
  isLoading,
  disabled,
}: {
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  const [isSubmittingOnBehalf, setIsSubmittingOnBehalf] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const roleIs = useRoleIs();
  const submitOrderSet = useSubmitOrderSetMutation();
  const { requiresCustomization } = useCustomizationRequirements();

  return (
    <div tw="space-y-4">
      {requiresCustomization && <RequiresCustomizationWarning />}
      {!isSubmittingOnBehalf && (
        <div tw="flex gap-2">
          <LoadingButton
            variant="contained"
            size="large"
            loading={isLoading || submitOrderSet.isPending}
            onClick={() => submitOrderSet.mutate()}
            disabled={disabled || requiresCustomization}
          >
            Submit Orders
          </LoadingButton>
          {roleIs(permissions.admin) && (
            <LoadingButton
              variant="outlined"
              size="large"
              loading={isLoading || submitOrderSet.isPending}
              disabled={disabled || requiresCustomization}
              onClick={() => setIsSubmittingOnBehalf(true)}
            >
              Submit on Behalf of Another User
            </LoadingButton>
          )}
        </div>
      )}
      {isSubmittingOnBehalf && (
        <div>
          <Button
            variant="text"
            onClick={() => setIsSubmittingOnBehalf(false)}
            startIcon={<FaIcon icon="chevron-left" />}
          >
            Submit for yourself
          </Button>
          <h4 tw="text-neutral-700 mt-2 mb-1">
            Select a user to submit the order-set on behalf of
          </h4>
          <UserSelection user={user} setUser={setUser} />
          <LoadingButton
            variant="contained"
            size="large"
            loading={isLoading || submitOrderSet.isPending}
            onClick={() => submitOrderSet.mutate(user?.id)}
            disabled={disabled || !user}
          >
            Submit Orders
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

const StandardCheckout = () => {
  const usesCostCenters = useSelector(
    (state: any) => state.currentUser.organization.usesCostCenters
  );
  const {
    orderSet: { budgetId },
  } = useCurrentOrderSet();

  const setBudget = useSetOrderSetBudgetMutation();

  useEffect(() => {
    if (budgetId) {
      setBudget.mutate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetId]);
  return (
    <>
      {usesCostCenters && <OrderSetCostCenterSelector />}
      <CheckoutButtons isLoading={setBudget.isPending} />
    </>
  );
};

const BudgetCheckout = () => {
  const { orderSet, orders } = useCurrentOrderSet();

  const totalTax = _.sumBy(orders, "totalEstimatedTax");
  const totalFreight = _.sumBy(orders, "totalEstimatedShippingCost");

  const [isBudgetSelectOpen, setBudgetSelectOpen] = useState(false);

  const { includeShippingInBudgets, includeTaxesInBudgets } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const { data: budget, isLoading: isBudgetLoading } = useBudgetQuery(
    orderSet.budgetId
  );

  const handleBudgetSelectClose = () => {
    setBudgetSelectOpen(false);
  };

  const pendingBalance = +(budget?.pendingBalance ?? 0);

  let total = +orderSet.totalPrice;
  if (includeShippingInBudgets) total += totalFreight;
  if (includeTaxesInBudgets) total += totalTax;

  let budgetBalance = pendingBalance + total;

  return (
    <div tw="space-y-4">
      {isBudgetLoading && <Skeleton height={48} width={120} />}
      {!budget && !isBudgetLoading && (
        <Button
          variant="outlined"
          size="large"
          onClick={() => setBudgetSelectOpen(true)}
        >
          Select Budget
        </Button>
      )}
      {budget && (
        <div tw="p-4 rounded-lg bg-primary-50 text-neutral-700 -mx-1">
          <div tw="flex justify-between items-start gap-2">
            <div>
              <h4 tw="text-primary-900 font-medium">{budget.name}</h4>
              <p tw="text-neutral-600">
                Available: {formatMoneyString(budgetBalance)}
              </p>
            </div>
            <Button
              variant="text"
              color="secondary"
              tw="font-normal -mt-2"
              endIcon={<FaIcon icon="edit" />}
              onClick={() => setBudgetSelectOpen(true)}
            >
              Change Budget
            </Button>
          </div>
        </div>
      )}
      {pendingBalance < 0 && (
        <p tw="text-neutral-700">
          Insufficient funds in budget ({formatMoneyString(total)} Required)
        </p>
      )}
      {budget && <CheckoutButtons disabled={pendingBalance < 0} />}
      {isBudgetSelectOpen && (
        <BudgetSelectModal
          open={isBudgetSelectOpen}
          handleClose={handleBudgetSelectClose}
        />
      )}
    </div>
  );
};

const CreditCardCheckout = () => {
  const { orderSet } = useCurrentOrderSet();
  const stripeCheckout = useStripeCheckoutMutation();
  const authorizedAmount = Math.round(+orderSet.extendedTotalPrice * 100);

  const { requiresCustomization } = useCustomizationRequirements();

  const minPaymentAmountInCents = 49;

  const handleSubmit = async (paymentMethodId?: string) => {
    stripeCheckout.mutate({
      amountInCents: authorizedAmount,
      paymentMethodId: paymentMethodId ?? null,
    });
  };

  if (requiresCustomization) {
    return <RequiresCustomizationWarning />;
  }

  if (authorizedAmount > minPaymentAmountInCents) {
    return <StripeCheckout onSuccess={handleSubmit} />;
  }

  return (
    <LoadingButton
      variant="contained"
      size="large"
      loading={stripeCheckout.isPending}
      onClick={() => handleSubmit()}
    >
      Submit Orders
    </LoadingButton>
  );
};

const CheckoutOption = ({
  title,
  icon,
  onClick,
  checked,
}: {
  title: string;
  icon: IconName;
  onClick: () => void;
  checked: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className="group"
      tw="rounded-lg"
      role="radio"
      aria-checked={checked}
      aria-label={title}
    >
      <div
        css={[
          tw`flex items-center gap-4 p-2 pr-10 transition-colors border-2 rounded-lg text-neutral-600 border-neutral-200 hover:border-primary-600 hover:text-neutral-700`,
          checked && tw` bg-primary-50 border-primary-600 text-primary-900`,
        ]}
      >
        <div
          css={[
            tw`flex items-center justify-center w-12 h-12 rounded-full`,
            checked
              ? tw`bg-primary-100 text-primary-600`
              : tw`bg-neutral-100 text-neutral-300`,
          ]}
        >
          <FaIcon icon={icon} tw="text-lg" />
        </div>
        <span>{title}</span>
      </div>
    </button>
  );
};

const Checkout = React.forwardRef<HTMLDivElement, any>((_props, ref) => {
  const { allowStandardCheckout, allowStripeCheckout, allowBudgetCheckout } =
    useCheckoutOptions();

  const checkoutOptions = _.compact([
    allowStandardCheckout && "default",
    allowStripeCheckout && "stripe",
    allowBudgetCheckout && "budget",
  ]);

  const [checkoutMethod, setCheckoutMethod] = useState(
    checkoutOptions.length > 1 ? "" : checkoutOptions[0]
  );

  return (
    <div id="checkout" ref={ref}>
      <h2 tw="text-xl text-neutral-600 mb-2">Complete Checkout</h2>
      <OpaqueCard tw="space-y-6">
        {checkoutOptions.length > 1 && (
          <>
            <h3 tw="text-base text-neutral-400">Select checkout method</h3>
            <div tw="flex gap-3 flex-wrap mt-2!">
              {allowStandardCheckout && (
                <CheckoutOption
                  title="Standard"
                  icon="globe"
                  checked={checkoutMethod === "default"}
                  onClick={() => setCheckoutMethod("default")}
                />
              )}
              {allowBudgetCheckout && (
                <CheckoutOption
                  title="Budget"
                  icon="dollar-sign"
                  checked={checkoutMethod === "budget"}
                  onClick={() => setCheckoutMethod("budget")}
                />
              )}
              {allowStripeCheckout && (
                <CheckoutOption
                  title="Credit Card"
                  icon="credit-card"
                  checked={checkoutMethod === "stripe"}
                  onClick={() => setCheckoutMethod("stripe")}
                />
              )}
            </div>
          </>
        )}
        {!checkoutMethod && (
          <h3 tw="text-lg text-neutral-400">
            Select a checkout method to continue.
          </h3>
        )}
        {checkoutMethod && checkoutOptions.length > 1 && <Divider />}
        {checkoutMethod === "default" && <StandardCheckout />}
        {checkoutMethod === "budget" && <BudgetCheckout />}
        {checkoutMethod === "stripe" && <CreditCardCheckout />}
      </OpaqueCard>
    </div>
  );
});

export default Checkout;
