import { createContext, useContext, useEffect, useState } from "react";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const LastLocationContext = createContext<Location | null>(null);

export const useLastLocation = () => useContext(LastLocationContext);

export const LastLocationProvider = ({ children }) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const [historyStack, setHistoryStack] = useState<Location[]>([
    location,
    location,
  ]);

  useEffect(() => {
    switch (navigationType) {
      case NavigationType.Push:
        if (location.state?.preventLastLocation) return;
        return setHistoryStack((stack) => stack.concat([location]));

      case NavigationType.Replace:
        return setHistoryStack((stack) =>
          stack.slice(0, -1).concat([location])
        );

      case NavigationType.Pop:
        return setHistoryStack((stack) => stack.slice(0, -1));

      default:
      // ignore
    }
  }, [location, navigationType]);

  return (
    <LastLocationContext.Provider value={historyStack[historyStack.length - 2] ?? null}>
      {children}
    </LastLocationContext.Provider>
  );
};
